import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

@Injectable()
export class PusherService {
    public pusher: Pusher;

    public constructor() {
        Pusher.logToConsole = !!environment.production;

        this.pusher = new Pusher(environment.pusher.key, {
            cluster: environment.pusher.cluster,
        });
    }
}
