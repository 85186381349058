import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { buildCacheKey } from "../../../utils/cache.util";
import { CacheService } from "../../cache.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../../user.service";

export interface ScorecardAdvertisingFilter {
    accounts: string[];
    reportlevel: string;
    lookbackPeriod: string;
    campaignTypes?: string;
    strategicPillars?: string;
    brands: string[];
    categories?: string;
    subcategories?: string;
    currency: string;
    marketplaces: string[];
}

@Injectable({providedIn: 'root'})
export class VendorCentralScorecardAdvertisingService {
    private baseUrl = environment.apiV2Url;

    public constructor(
        private cacheService: CacheService,
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async getAdvertisingOverview(filter: ScorecardAdvertisingFilter) {
        try {
            const key = buildCacheKey([
                'vendor_central_scorecard_adv_overview',
                filter.accounts.join(','),
                filter.reportlevel,
                filter.lookbackPeriod,
                filter.brands.join(','),
                filter.marketplaces.join(','),
                filter.currency
            ]);

            const cache = this.cacheService.getCache('Memory', key);

            let result = of(cache);

            if(!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/vendor-central/advertising/overview');

                result = this.http.get<any>(url.toString() ,{
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': filter.accounts,
                        reportLevel: filter.reportlevel,
                        lookbackPeriod: filter.lookbackPeriod,
                        'brands[]': filter.brands,
                        'marketplaces[]': filter.marketplaces,
                        currency: filter.currency
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingByStrategicPillar(filter: ScorecardAdvertisingFilter) {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'scorecard/vendor-central/advertising/by-strategic-pillar');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filter.accounts,
                    reportLevel: filter.reportlevel,
                    lookbackPeriod: filter.lookbackPeriod,
                    'campaignTypes[]': filter.campaignTypes || [],
                    'strategicPillars[]': filter.strategicPillars || [],
                    'brands[]': filter.brands || [],
                    'categories[]': filter.categories || [],
                    'subcategories[]': filter.subcategories || [],
                    'marketplaces[]': filter.marketplaces,
                    currency: filter.currency
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingByCampaignType(filter: ScorecardAdvertisingFilter) {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'scorecard/vendor-central/advertising/by-campaign-type');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filter.accounts,
                    reportLevel: filter.reportlevel,
                    lookbackPeriod: filter.lookbackPeriod,
                    'campaignTypes[]': filter.campaignTypes || [],
                    'strategicPillars[]': filter.strategicPillars || [],
                    'brands[]': filter.brands || [],
                    'categories[]': filter.categories || [],
                    'subcategories[]': filter.subcategories || [],
                    'marketplaces[]': filter.marketplaces,
                    currency: filter.currency
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingBySubcategory(filter: ScorecardAdvertisingFilter) {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'scorecard/vendor-central/advertising/by-subcategory');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filter.accounts,
                    reportLevel: filter.reportlevel,
                    lookbackPeriod: filter.lookbackPeriod,
                    'campaignTypes[]': filter.campaignTypes || [],
                    'strategicPillars[]': filter.strategicPillars || [],
                    'brands[]': filter.brands || [],
                    'categories[]': filter.categories || [],
                    'subcategories[]': filter.subcategories || [],
                    'marketplaces[]': filter.marketplaces,
                    currency: filter.currency
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingByCategory(filter: ScorecardAdvertisingFilter) {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'scorecard/vendor-central/advertising/by-category');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filter.accounts,
                    reportLevel: filter.reportlevel,
                    lookbackPeriod: filter.lookbackPeriod,
                    'campaignTypes[]': filter.campaignTypes || [],
                    'strategicPillars[]': filter.strategicPillars || [],
                    'brands[]': filter.brands || [],
                    'categories[]': filter.categories || [],
                    'subcategories[]': filter.subcategories || [],
                    currency: filter.currency,
                    'marketplaces[]': filter.marketplaces
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingByBrand(filter: ScorecardAdvertisingFilter) {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'scorecard/vendor-central/advertising/by-brand');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filter.accounts,
                    reportLevel: filter.reportlevel,
                    lookbackPeriod: filter.lookbackPeriod,
                    'campaignTypes[]': filter.campaignTypes || [],
                    'strategicPillars[]': filter.strategicPillars || [],
                    'brands[]': filter.brands || [],
                    'categories[]': filter.categories || [],
                    'subcategories[]': filter.subcategories || [],
                    'marketplaces[]': filter.marketplaces,
                    currency: filter.currency
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingFormatting(accounts: string[]) {
        try {
            const key = buildCacheKey(['scorecard_adv_formatting', accounts]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if(!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/formatting');

                result = this.http.get<any>(url.toString() ,{
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }
}
