import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PageTitleService } from 'src/app/services/page-title.service';
import { FILTER_STORE_TOKEN, FilterStore } from './filter.store';
import { Subscription } from 'rxjs';
import { EventTrackService } from 'src/app/services/event-track.service';

interface FilterObject { [key: string]: any }

export const LAST_SELECTED_ACCOUNT_KEY = 'last-selected-accounts';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
    @Input()
    public filtersToTrack: string[] = [];

    @Input()
    public isFilterValid = true;

    @Output()
    public filterEvent = new EventEmitter<FilterObject>();

    private filterStoreSubscription!: Subscription;

    private filters: FilterObject = {};

    public constructor(
        @Inject(FILTER_STORE_TOKEN)
        private readonly filterStore: FilterStore,
        private readonly pageTitleService: PageTitleService,
        private readonly eventTrackService: EventTrackService,
        private readonly router: Router,
    ) { }

    public ngOnInit(): void {
        this.filterStoreSubscription = this.filterStore.filters$.subscribe(filters => {
            this.filters = filters;
            this.applyFiltersToRoute(filters);
        });
    }

    public ngOnDestroy() {
        this.filterStoreSubscription?.unsubscribe();
    }

    public applyFilter() {
        if (!this.isFilterValid) {
            return;
        }

        this.filterStore.update(this.filters);
    }

    public toggle(): void {
        const containerEl = document.getElementById('container');
        const filterBarEl = document.getElementById('filter-bar');

        containerEl?.classList.toggle('hide-filter');
        filterBarEl?.classList.toggle('hide-filter');
    }

    public updateFilters(newFilter: FilterObject) {
        this.filters = { ...this.filters, ...newFilter };

        if ('accounts' in this.filters && 'accountType' in this.filters) {
            this.storeAccountsOnLocalStorage();
        }
    }

    public toggleView() {
        const collapsableFilterContent = document.getElementById('collapsable-filter-content');

        collapsableFilterContent?.classList.toggle('expanded');
    }

    public applyFiltersToRoute(filters: FilterObject) {
        const url = new URL(window.location.href);

        Object.keys(filters)
            .filter(filterKey => this.filtersToTrack.includes(filterKey) || this.filtersToTrack.length === 0)
            .forEach(filterKey => {
                const value = filters[filterKey];

                if (value === '') {
                    return;
                }

                if (Array.isArray(value) && value.length <= 0) {
                    url.searchParams.delete(filterKey);

                    return;
                }

                url.searchParams.set(filterKey, JSON.stringify(filters[filterKey]));
            });

        url.searchParams.delete('instance');

        const pageTitle = this.pageTitleService.getPageTitle();

        this.pageTitleService.updatePageTitleBasedOnUrl(pageTitle, filters);

        this.router.navigateByUrl(url.pathname + '?' + url.searchParams.toString());

        this.eventTrackService.registerApplyFilter(filters);
    }

    private storeAccountsOnLocalStorage(): void {
        const accounts: string[] = this.filters['accounts'].filter((account: string) => account);
        const accountType: string = this.filters['accountType'];

        if (accounts.length <= 0) {
            localStorage.removeItem(LAST_SELECTED_ACCOUNT_KEY);
        }

        localStorage.setItem(LAST_SELECTED_ACCOUNT_KEY, JSON.stringify({ accounts, accountType }));
    }
}
