export const environment = {
    production: false,
    environmentName: 'dev',
    // apiUrl: 'https://dev-api.amifyos.com/api/',
    apiUrl: 'https://dev-api.amifydata.com/api/',
    apiV2Url: 'https://dev2-api.amifyos.com/api/',
    url: "https://dev.amifyos.com/",
    authClientId: "0oajkiuwx1V349O8b696",
    redirectUri: 'https://dev.amifyos.com/auth',
    googleOauthClientID: '714035456696-o8dv22e6o8iet9mu7br5c67ej1dbmhbb.apps.googleusercontent.com',
    userPoolId: 'us-east-1_Z9KNLiyOD',
    userPoolWebClientId: '4om8fn8omev4etishc6vi5d7tb',
    awsRegion: 'us-east-1',
    looker_url_internal: 'https://amify.cloud.looker.com',
    looker_url_external: 'https://amifyexternal.cloud.looker.com',
    pusher: {
        key: '4d9b6b03a86bee8988fa',
        cluster: 'us2'
    },
    clarity: {
        projectId: "hmv6trrk8y"
    },
    intercom: {
        appId: "cetwajfp"
    },
    agGridLicenseKey: 'Using_this_AG_Grid_Enterprise_key_( AG-048490 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Amify Inc )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Amify )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Amify )_need_to_be_licensed___( Amify )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 27 September 2024 )____[v2]_MTcyNzM5MTYwMDAwMA==f949a561e9fcd0ae9a12a3dca130539e',
    sentry: {
        dsn: 'https://090b3889db054051a8466e8ce33b4a74@o1309267.ingest.sentry.io/6555261',
        enabled: true,
        sampleRate: 1.0
    },
    openbridge: {
        accountId: 3093,
        userId: 3236,
        refreshToken: "ce3a1387046c4481a65ddbf9f837f607",
        oauthInitializeUrl: "https://oauth.api.openbridge.io/oauth/initialize",
        authenticationAPI: "https://authentication.api.openbridge.io/auth/api/ref",
        stateAPI: "https://state.api.openbridge.io/state/oauth"
    },
    auth0: {
        domain: 'iam.bc.dev.americommerce.cloud',
        clientId: 'GOnMpnfHmnYIDAOVFP7LvaX8JmItPXk3',
    }
};
