import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

export enum AccountTypeEnum {
    SellerCentral = 'SELLER_CENTRAL',
    VendorCentral = 'VENDOR_CENTRAL',
};

export type AccountType = 'SELLER_CENTRAL' | 'VENDOR_CENTRAL' | AccountTypeEnum;

export interface Account { name: string; type: AccountType; marketplace: string; currency: string; includeTax: string; clientStatus?: string }

export type AccountResult = Account[];

interface GetAccountsParams { type?: AccountType };

@Injectable()
export class AccountsService {
    public base_url = environment.apiV2Url;

    public constructor(
      private http: HttpClient,
      private userService: UserService
    ) { }

    public async getAccounts(params: GetAccountsParams = {}): Promise<Observable<AccountResult>> {
        try {
            const user = this.userService.getUser()!;

            const { type } = params;

            let url = new URL(this.base_url + 'filter-options/accounts');

            if (type) {
                url.searchParams.append('type', type);
            }

            return this.http.get<[]>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getUnifiedAccounts(): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.base_url + 'filter-options/unified-accounts', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getAdProducts(accounts: string[]): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<any>(this.base_url + 'filter-options/ad-products', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getNotificationProducts(accounts: string[]): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<any>(this.base_url + 'filter-options/notification-products', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getAdCampaigns(accounts: string[] = [], marketplaces: string[] = []): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<any>(this.base_url + 'filter-options/ad-campaigns', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    'marketplaces[]': marketplaces
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getKeywordsTexts(accounts: string[], filters: any): Promise<Observable<any>> {
        try {
            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];

            const user = this.userService.getUser()!;

            return this.http.get<Array<any>>(this.base_url + 'filter-options/keyword-texts-for-accounts', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    startDate: fromDateFormatted,
                    endDate: toDateFormatted,
                    'marketplaces[]': filters.marketplaces
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }
}
