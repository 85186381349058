export function formatAsCurrencyWithFractionDigits(value: number | string, digits: number = 2) {
    const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: digits });

    return formatter.format(+value);
}

export function formatToLocaleString(value: number | string | null | undefined, options?: Intl.NumberFormatOptions | undefined): string {
    return value ? value.toLocaleString('en-US', options) : '0';
}

function convertToNumber(value: number | string | null | undefined): number {
    return value ? parseFloat(value + '') : 0;
}

export function round(num: number, places: number): number {
    if (places === 0) {
        return Math.round(num);
    }

    return +(Math.round(<any>(num + "e+" + places)) + "e-" + places);
}

export function formatAsCurrency(value: number | string | null | undefined, places: number = 2): string {
    value = convertToNumber(value);

    if (places !== undefined) {
        value = round(value, places);
    }

    if (value >= 0) {
        return `$${formatToLocaleString(value)}`;
    }

    return `-$${formatToLocaleString(value * -1)}`;
}

export function formatAsPercent(value: number | string | null | undefined): string {
    value = value ? parseFloat(value + '').toFixed(2) : value;

    return `${formatToLocaleString(value)}%`;
}

export function formatAsRoundedPercent(value: number | null | undefined): string {
    value = value ? Math.round(value) : value;

    return `${formatToLocaleString(value)}%`;
}

export function calculateCurrencyDifference(value: number, otherValue: number, opts: { decimals: number } = { decimals: 2 }): string {
    const result = round(value - otherValue, opts.decimals);
    const signal = (result > 0 ? '+' : '');

    return `${signal}${formatAsCurrency(result)}`;;
}

export function calculatePercentageDifference(value: number, otherValue: number): string {
    if ((value === 0 || !value) && (otherValue === 0 || !otherValue)) {
        return '0%';
    }

    if (otherValue === 0 || !otherValue) {
        return '+100%';
    }

    const result = Math.round(((value - otherValue) / otherValue) * 100);
    const signal = (result > 0 ? '+' : '');

    return signal + result + '%';
}
