import { Injectable } from '@angular/core';
import LocalStorageCache from 'localstorage-cache';
import { Observable, share } from 'rxjs';
import { BuildVersionService } from './build-version.service';

type CacheType = 'Memory' | 'LocalStorage';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    private storageCache = new LocalStorageCache(3 * 1024, 'LRU');
    private memoryCache: any = {};
    private observables: any = {};

    public constructor(private buildVersionService: BuildVersionService) { }

    public cacheObservable(key: string, observable: Observable<any>): typeof observable {
        key = this.formatKey(key);

        if (this.observables[key]) {
            return this.observables[key];
        }

        this.observables[key] = new Observable((observer) => {
            observable.subscribe({
                next: (result) => {
                    observer.next(result);
                    observer.complete();

                    delete this.observables[key];
                },
                error: (error) => {
                    observer.error(error);
                    observer.complete();

                    delete this.observables[key];
                },
            });
        }).pipe(share());

        return this.observables[key];
    }

    public setCache(type: CacheType, key: string, data: any, time: number) {
        key = this.formatKey(key);

        this.memoryCache[key] = JSON.stringify(data);
    }

    public getCache(type: CacheType, key: string) {
        key = this.formatKey(key);

        let cache;

        cache = this.memoryCache[key] ? JSON.parse(this.memoryCache[key]) : undefined;

        if (cache) {
            console.info('Cache hit', type, key);
        }

        return cache;
    }

    private formatKey(key: string) {
        return this.buildVersionService.buildVersion?.buildNumber + '.' + key;
    }
}
