import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';

interface IntercomUser {
    email: string;
    name: string;
    hash: string;
}

const INTERCOM_LOCAL_STORAGE_KEY = 'intercom.user-hash';

@Injectable({
    providedIn: 'root'
})
export class IntercomService {
    public constructor(
        private userService: UserService,
        private http: HttpClient
    ) {}

    public async boot(): Promise<void> {
        try {
            const { name, email } = await this.userService.getData();

            const localStorageHash = localStorage.getItem(INTERCOM_LOCAL_STORAGE_KEY);

            if (localStorageHash) {
                return this.init({ name, email, hash: localStorageHash });
            }

            const { hash } = await lastValueFrom(await this.generateUserHash());

            return this.init({ name, email, hash });
        } catch {}
    }

    public shutdown(): void {
        (window as any).Intercom('shutdown');
        localStorage.removeItem(INTERCOM_LOCAL_STORAGE_KEY);
    }

    public async generateUserHash(): Promise<Observable<{ hash: string }>> {
        const user = this.userService.getUser()!;

        const url = new URL(environment.apiV2Url + 'auth/intercom/user-hash');

        return this.http.get<any>(url.toString(), {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        });
    }

    private init(user: IntercomUser) {
        const { name, email, hash } = user;

        const intercomConfig = {
            app_id: environment.intercom.appId,
            name,
            email,
            user_hash: hash
        };

        localStorage.setItem(INTERCOM_LOCAL_STORAGE_KEY, hash);

        (window as any).Intercom('boot', intercomConfig);
    }
}
