import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { DateHelperService } from "./date-helper.service";
import ApiResponse from "../types/api-response";
import { UserService } from "./user.service";

export interface OperationIssueFilters {
    fromDate: Date;
    toDate: Date;
    status: string;
    categories?: Array<string>;
    subCategories?: Array<string>;
    asins?: Array<string>;
    asinTiers?: Array<string>;
    issueType?: Array<string>;
}

@Injectable()
export class OperationIssueService {
    public v1Api = environment.apiUrl;
    public v2Api = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private dateService: DateHelperService,
        private userService: UserService
    ) { }

    public async getIssues(accounts: string[], filters: OperationIssueFilters, issues_paused: number): Promise<Observable<ApiResponse>> {
        try {
            const from_date_formatted = filters.fromDate.toISOString().split('T')[0];
            const to_date_formatted = filters.toDate.toISOString().split('T')[0];
            const status = filters.status;

            const user = this.userService.getUser()!;
            const url = new URL(this.v2Api + 'manage/operation-issues');

            return this.http.get<Array<any>>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'account[]': accounts,
                    'status': status,
                    'startDate': from_date_formatted,
                    'endDate': to_date_formatted,
                }
            }).pipe(
                map((result) => ({
                    data: result,
                    last_refresh: this.dateService.getRefreshTime(),
                }))
            ).pipe(map(result => this.applyLocalFilter(result, filters)));
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getScrapingSchedule(): Promise<Observable<ApiResponse>> {
        try {
            const user = this.userService.getUser()!;
            const url = new URL(this.v2Api + 'manage/scraping-schedule/');

            return this.http.get<Array<any>>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            }).pipe(map((result) => ({
                data: result,
            })));
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async pauseIssue(account: string, issue_id: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.post(this.v2Api + 'manage/operation-issues/pause',
                {
                    issueId: issue_id,
                    account,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    }
                }
            );
        } catch (err) {
            return throwError(() => err);
        }
    }

    private applyLocalFilter(response: ApiResponse, filters: OperationIssueFilters): ApiResponse {
        let filtered: Array<any> = response.data;

        if (filters.asins?.length) {
            filtered = filtered.filter(item => filters.asins?.includes(item.asin));
        }

        if (filters.categories?.length) {
            filtered = filtered.filter(item => filters.categories?.includes(item.category));
        }

        if (filters.subCategories?.length) {
            filtered = filtered.filter(item => filters.subCategories?.includes(item.sub_category));
        }

        if (filters.asinTiers?.length) {
            filtered = filtered.filter(item => filters.asinTiers?.includes(item.priority_asin));
        }

        if (filters.issueType?.length) {
            filtered = filtered.filter(item => filters.issueType?.includes(item.issue_type));
        }

        response.data = filtered;

        return response;
    }
}
