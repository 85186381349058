import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-profitability-account-column-renderer',
    template: `<div>{{accountName}} | {{marketplace}}</div>`,
    encapsulation: ViewEncapsulation.None
})
export class ProfitabilityAccountColumnRendererComponent implements ICellRendererAngularComp {
    public accountName!: string;
    public marketplace!: string;

    public constructor() { }

    public agInit(params: ICellRendererParams): void {
        this.updateComponent(params.data);
    }

    public updateComponent(data: any) {
        this.accountName = data['Account'];
        this.marketplace = data['Marketplace'];
    }

    public refresh(params: ICellRendererParams): boolean {
        this.updateComponent(params.data);

        return true;
    }
}
