import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { buildCacheKey } from "../../../utils/cache.util";
import { CacheService } from "./../../cache.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { transformTaxFilter } from "src/app/utils/transform-tax-filter";
import { UserService } from "../../user.service";

export interface ScorecardAdvertisingFilter {
    accounts: string[];
    reportLevel: string;
    lookbackPeriod: string;
    campaignTypes?: string[];
    strategicPillars?: string[];
    brands?: string[];
    categories?: string[];
    subcategories?: string[];
    currency: string;
    marketplaces: string[];
    includeTax: string;
}

@Injectable({providedIn: 'root'})
export class SellerCentralScorecardAdvertisingService {
    private baseUrl = environment.apiV2Url;

    public constructor(
        private cacheService: CacheService,
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async getAdvertisingOverview(filters: ScorecardAdvertisingFilter) {
        try {
            filters = transformTaxFilter(filters);

            const { accounts, reportLevel, lookbackPeriod, currency, marketplaces, includeTax } = filters;

            const key = buildCacheKey(['scorecard_adv_overview', accounts.join(','), reportLevel, lookbackPeriod, marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if(!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/advertising/overview');

                result = this.http.get<any>(url.toString() ,{
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        reportLevel,
                        lookbackPeriod,
                        currency: currency,
                        'marketplaces[]': marketplaces,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingByStrategicPillar(filters: ScorecardAdvertisingFilter) {
        try {
            filters = transformTaxFilter(filters);

            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'scorecard/advertising/by-strategic-pillar');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filters.accounts,
                    reportLevel: filters.reportLevel,
                    lookbackPeriod: filters.lookbackPeriod,
                    'campaignTypes[]': filters.campaignTypes || [],
                    'strategicPillars[]': filters.strategicPillars || [],
                    'brands[]': filters.brands || [],
                    'categories[]': filters.categories || [],
                    'subcategories[]': filters.subcategories || [],
                    currency: filters.currency,
                    'marketplaces[]': filters.marketplaces
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingByCampaignType(filters: ScorecardAdvertisingFilter) {
        try {
            filters = transformTaxFilter(filters);

            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'scorecard/advertising/by-campaign-type');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filters.accounts,
                    reportLevel: filters.reportLevel,
                    lookbackPeriod: filters.lookbackPeriod,
                    'campaignTypes[]': filters.campaignTypes || [],
                    'strategicPillars[]': filters.strategicPillars || [],
                    'brands[]': filters.brands || [],
                    'categories[]': filters.categories || [],
                    'subcategories[]': filters.subcategories || [],
                    currency: filters.currency,
                    'marketplaces[]': filters.marketplaces
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingBySubcategory(filters: ScorecardAdvertisingFilter) {
        try {
            filters = transformTaxFilter(filters);

            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'scorecard/advertising/by-subcategory');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filters.accounts,
                    reportLevel: filters.reportLevel,
                    lookbackPeriod: filters.lookbackPeriod,
                    'campaignTypes[]': filters.campaignTypes || [],
                    'strategicPillars[]': filters.strategicPillars || [],
                    'brands[]': filters.brands || [],
                    'categories[]': filters.categories || [],
                    'subcategories[]': filters.subcategories || [],
                    currency: filters.currency,
                    'marketplaces[]': filters.marketplaces
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingByCategory(filters: ScorecardAdvertisingFilter) {
        try {
            filters = transformTaxFilter(filters);

            const user = this.userService.getUser()!;
            const url = new URL(this.baseUrl + 'scorecard/advertising/by-category');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filters.accounts,
                    reportLevel: filters.reportLevel,
                    lookbackPeriod: filters.lookbackPeriod,
                    'campaignTypes[]': filters.campaignTypes || [],
                    'strategicPillars[]': filters.strategicPillars || [],
                    'brands[]': filters.brands || [],
                    'categories[]': filters.categories || [],
                    'subcategories[]': filters.subcategories || [],
                    currency: filters.currency,
                    'marketplaces[]': filters.marketplaces
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingByBrand(filters: ScorecardAdvertisingFilter) {
        try {
            filters = transformTaxFilter(filters);

            const user = this.userService.getUser()!;
            const url = new URL(this.baseUrl + 'scorecard/advertising/by-brand');

            return this.http.get<any>(url.toString() ,{
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': filters.accounts,
                    reportLevel: filters.reportLevel,
                    lookbackPeriod: filters.lookbackPeriod,
                    'campaignTypes[]': filters.campaignTypes || [],
                    'strategicPillars[]': filters.strategicPillars || [],
                    'brands[]': filters.brands || [],
                    'categories[]': filters.categories || [],
                    'subcategories[]': filters.subcategories || [],
                    currency: filters.currency,
                    'marketplaces[]': filters.marketplaces
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getAdvertisingFormatting(accounts: string[]) {
        try {
            const key = buildCacheKey(['scorecard_adv_formatting', accounts.join(',')]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if(!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/advertising-formatting');

                result = this.http.get<any>(url.toString() ,{
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }
}
