import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { BuildVersionService } from './build-version.service';
import { BuildVersionHttpService } from './build-version.http.service';

@Injectable({ providedIn: 'root' })
export class VersionCheckService {
    private buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);
    private buildVersionAtStartup: string;

    public constructor(buildVersionService: BuildVersionService, private vesionService: BuildVersionHttpService) {
        this.buildVersionAtStartup = buildVersionService.buildVersion.buildNumber!;
        this.pollForBuildNumber();
    }

    public get buildIsUpToDate(): Observable<boolean> {
        return this.buildIsUpToDateSubject;
    }

    private pollForBuildNumber() {
        const pollInterval = 60000;

        timer(pollInterval, pollInterval).subscribe(async () => {
            const version = await this.vesionService.fetchBuildDetails();
            const newBuildNumber = version.buildNumber;

            if (this.buildVersionAtStartup !== newBuildNumber) {
                this.buildIsUpToDateSubject.next(false);
            }
        });
    }
}
