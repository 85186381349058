import { CellClickedEvent, ColGroupDef, GridOptions, ValueFormatterFunc } from "ag-grid-community";
import { GridHelperService, TimeseriesGridData } from "src/app/services/grid-helper.service";
import { UrlManipulationService } from "src/app/services/url-manipulation.service";
import { calculatePoPChange } from "src/app/utils/scorecard.util";
import { makeMetricTooltipConfig } from "src/app/utils/make-custom-metric-tooltip-config.util";
import { UnifiedReportingAccountColumnRendererComponent } from "src/app/components/timeseries/unified-reporting-account-column-renderer.component";

interface TimeseriesMetric {
    internalName: string;
    displayName: string;
    type: string;
    decimals: number;
    isDefault: boolean;
    platforms: string[];
    parent?: string | null;
}

export const unifiedTimeseriesMetricsGridColumns = {
    metric: 'Metric',
    date: 'Date',
    account: 'Account',
    country: 'Country',
    vendor: 'Vendor',
    brand: 'Brand',
    channel: 'Channel',
    subchannel: 'Subchannel',
    tactic: 'Tactic',
    campaignName: 'Campaign Name',
    rollingTotal: 'Rolling N Total',
    rollingAverage: 'Rolling N Avg',
};

export const unifiedTimeseriesMetrics: TimeseriesMetric[] = [
    { internalName: 'sales', displayName: 'Sales', type: 'currency', decimals: 0, platforms: ['DTC & Marketplaces'], isDefault: true },
    { internalName: 'orders', displayName: 'Orders', type: 'number', decimals: 0, platforms: ['DTC & Marketplaces'], isDefault: true },
    { internalName: 'units', displayName: 'Units', type: 'number', decimals: 0, platforms: ['DTC & Marketplaces'], isDefault: true },
    { internalName: 'page_views', displayName: 'Page Views', type: 'number', decimals: 0, platforms: ['DTC & Marketplaces'], isDefault: true },
    { internalName: 'sessions', displayName: 'Sessions', type: 'number', decimals: 0, platforms: ['DTC & Marketplaces'], isDefault: true },
    { internalName: 'ad_spend', displayName: 'Ad Spend', type: 'currency', decimals: 0, platforms: ['DTC & Marketplaces', 'Growth Marketing'], isDefault: true },
    { internalName: 'platform_ad_sales', displayName: 'Ad Sales', type: 'currency', decimals: 0, platforms: ['DTC & Marketplaces', 'Growth Marketing'], isDefault: true },
    { internalName: 'clicks', displayName: 'Clicks', type: 'number', decimals: 0, platforms: ['DTC & Marketplaces', 'Growth Marketing'], isDefault: true },
    { internalName: 'impressions', displayName: 'Impressions', type: 'number', decimals: 0, platforms: ['DTC & Marketplaces', 'Growth Marketing'], isDefault: true },
    { internalName: 'platform_ad_orders', displayName: 'Ad Orders', type: 'number', decimals: 0, platforms: ['DTC & Marketplaces', 'Growth Marketing'], isDefault: true },
    { internalName: 'platform_ad_roas', displayName: 'Ad ROAS', type: 'number', decimals: 2, platforms: ['Growth Marketing'], isDefault: false },
    { internalName: 'platform_ad_acos', displayName: 'Ad ACOS', type: 'percentage', decimals: 2, platforms: ['Growth Marketing'], isDefault: false },
    { internalName: 'platform_ad_conversion_rate', displayName: 'Ad Conversion Rate', decimals: 2, type: 'percentage', platforms: ['Growth Marketing'], isDefault: false },
    { internalName: 'ad_ctr', displayName: 'Ad CTR', type: 'percentage', decimals: 2, platforms: ['Growth Marketing'], isDefault: false },
    { internalName: 'ad_cpm', displayName: 'Ad CPM', type: 'currency', decimals: 2, platforms: ['Growth Marketing'], isDefault: false },
    { internalName: 'ad_cpc', displayName: 'Ad CPC', type: 'currency', decimals: 2, platforms: ['Growth Marketing'], isDefault: false },
    { internalName: 'platform_ad_cpo', displayName: 'Ad CPO', type: 'currency', decimals: 2, platforms: ['Growth Marketing'], isDefault: false },
    { internalName: 'platform_ad_aov', displayName: 'Ad AOV', type: 'currency', decimals: 2, platforms: ['Growth Marketing'], isDefault: false },
];

export function getDefaultUnifiedTimeseriesMetrics(platform: string): TimeseriesMetric[] {
    return unifiedTimeseriesMetrics.filter((metric: TimeseriesMetric) => metric.isDefault && metric.platforms.includes(platform));
}

export function makeGridColumnDefs(dateColumns: Array<ColGroupDef>): Array<ColGroupDef> {
    const identifierColumn: ColGroupDef = {
        headerName: 'Identifiers',
        groupId: 'IDENTIFIERS',
        children: [
            {
                colId: 'DATE_IDENTIFIER',
                field: unifiedTimeseriesMetricsGridColumns.date,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                displayParams: {
                    aggregationTypes: ['Date'],
                    showOnTable: true,
                    showOnExport: true,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.metric,
                pinned: 'left',
                width: 400,
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                cellRenderer: 'agGroupCellRenderer',
                showRowGroup: true,
                cellRendererParams: {
                    suppressCount: true
                },
                displayParams: {
                    aggregationTypes: ['Metric'],
                    showOnTable: true,
                    showOnExport: true,
                },
                ...makeMetricTooltipConfig('cell'),
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.account,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                cellRenderer: UnifiedReportingAccountColumnRendererComponent,
                hide: false,
                filterParams: {
                    filterOptions: ['contains', 'notContains'],

                    textMatcher: (params: any) => {
                        return params.data[unifiedTimeseriesMetricsGridColumns.account].toLowerCase().includes(params.filterText)
                            || params.data[unifiedTimeseriesMetricsGridColumns.country].toLowerCase().includes(params.filterText);
                    }
                },
                displayParams: {
                    aggregationTypes: ['Account', 'Vendor', 'Brand', 'Channel', 'Subchannel', 'Tactic', 'Campaign Name'],
                    showOnTable: true,
                    showOnExport: false,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.account,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                hide: true,
                displayParams: {
                    aggregationTypes: ['Account', 'Vendor', 'Brand', 'Channel', 'Subchannel', 'Tactic', 'Campaign Name'],
                    showOnTable: false,
                    showOnExport: true,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.country,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                width: 135,
                cellStyle: { 'text-align': 'center' },
                hide: true,
                displayParams: {
                    aggregationTypes: ['Account', 'Vendor', 'Brand', 'Channel', 'Subchannel', 'Tactic', 'Campaign Name'],
                    showOnTable: false,
                    showOnExport: true,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.vendor,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
                width: 200,
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                displayParams: {
                    aggregationTypes: ['Vendor'],
                    showOnTable: true,
                    showOnExport: true,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.brand,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
                width: 200,
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                displayParams: {
                    aggregationTypes: ['Brand'],
                    showOnTable: true,
                    showOnExport: true,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.channel,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
                width: 200,
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                displayParams: {
                    aggregationTypes: ['Channel'],
                    showOnTable: true,
                    showOnExport: true,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.subchannel,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
                width: 200,
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                displayParams: {
                    aggregationTypes: ['Subchannel'],
                    showOnTable: true,
                    showOnExport: true,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.tactic,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
                width: 200,
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                displayParams: {
                    aggregationTypes: ['Tactic'],
                    showOnTable: true,
                    showOnExport: true,
                },
            },
            {
                field: unifiedTimeseriesMetricsGridColumns.campaignName,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
                width: 200,
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                displayParams: {
                    aggregationTypes: ['Campaign Name'],
                    showOnTable: true,
                    showOnExport: true,
                },
            },
        ],
    };

    const rollingColumns: ColGroupDef = {
        headerName: 'Rolling Metrics',
        groupId: 'ROLLING_METRICS',
        children: [
            {
                colId: `rolling_total`,
                headerName: 'Rolling Total',
                filter: 'agNumberColumnFilter',
                hide: true
            },
            {
                colId: `rolling_average`,
                headerName: 'Rolling Avg',
                filter: 'agNumberColumnFilter',
                hide: true
            }
        ]
    };

    return [
        ...[identifierColumn],
        ...dateColumns,
        ...[rollingColumns]
    ];
}

export function makeGridOptions(colDefs: Array<ColGroupDef>, urlManipulationService: UrlManipulationService, gridHelper: GridHelperService): GridOptions {
    return {
        columnDefs: colDefs,
        enableRangeSelection: true,
        tooltipShowDelay: 500,
        defaultColDef: {
            width: 150,
            editable: false,
            resizable: true,
            sortable: true
        },
        pagination: true,
        defaultColGroupDef: {
            marryChildren: true,
        },
        rowData: [],
        getRowHeight: function (params) {
            const MIN_HEIGHT = 50;

            const data = params.data['Product Name'];

            if (data) {
                const height = MIN_HEIGHT + (data.length * 0.7);

                return height > MIN_HEIGHT ? height : MIN_HEIGHT;
            }

            return 40;
        },
        onFilterChanged: (params) => {
            urlManipulationService.setAgGridFilterToUrl('Profitability Products', 'filter', params);
        },
        onSortChanged: (params) => {
            urlManipulationService.setAgGridSortToUrl('Profitability Products', 'sort', params);
        },
        onCellClicked: (event: CellClickedEvent) => {
            gridHelper.copyCellToClipboard(event.value);
        },
        getDataPath: (data) => data.path,
        groupDisplayType: 'custom',
        treeData: true,
    };
}

export function mapGridData(data: any, dates: string[]) {
    const grid_data: any = [];

    data.forEach((row: any) => {
        let rec: any = {};

        rec['path'] = [row.aggregation_text];
        rec[unifiedTimeseriesMetricsGridColumns.date] = row.aggregation_text;
        rec[unifiedTimeseriesMetricsGridColumns.account] = row.account;
        rec[unifiedTimeseriesMetricsGridColumns.country] = row.country;
        rec[unifiedTimeseriesMetricsGridColumns.vendor] = row.vendor;
        rec[unifiedTimeseriesMetricsGridColumns.brand] = row.brand;
        rec[unifiedTimeseriesMetricsGridColumns.channel] = row.channel;
        rec[unifiedTimeseriesMetricsGridColumns.subchannel] = row.subchannel;
        rec[unifiedTimeseriesMetricsGridColumns.tactic] = row.tactic;
        rec[unifiedTimeseriesMetricsGridColumns.campaignName] = row.campaign_name;

        dates.forEach((date, index: number) => {
            const rowValue = row.values[index];
            const rowPop = row.pop ? row.pop[index] : null;
            const rowYoy = row.yoy ? row.yoy[index] : null;

            rec[date] = {};

            for (const metricDefinition of unifiedTimeseriesMetrics) {
                rec[date][metricDefinition.internalName] = rowValue?.[metricDefinition.internalName];
                rec[date][`${metricDefinition.internalName}_pop`] = calculatePoPChange(rowValue?.[metricDefinition.internalName], rowPop?.[metricDefinition.internalName]);
                rec[date][`${metricDefinition.internalName}_pop_diff`] = rowValue?.[metricDefinition.internalName] - rowPop?.[metricDefinition.internalName];
                rec[date][`${metricDefinition.internalName}_yoy`] = calculatePoPChange(rowValue?.[metricDefinition.internalName], rowYoy?.[metricDefinition.internalName]);
                rec[date][`${metricDefinition.internalName}_yoy_diff`] = rowValue?.[metricDefinition.internalName] - rowYoy?.[metricDefinition.internalName];
            }
        });

        rec[unifiedTimeseriesMetricsGridColumns.rollingTotal] = row.rolling?.total;
        rec[unifiedTimeseriesMetricsGridColumns.rollingAverage] = row.rolling?.average;

        grid_data.push(rec);
    });

    return grid_data;
}

export function mapGridDataByMetric(gridData: TimeseriesGridData, data: any) {
    const output = [];
    const flatten = (row: any, values: any[]) => {
        const [firstValue] = values || [];

        if (!firstValue) {
            return null;
        }

        return {
            ...row,
            ...firstValue
        };
    };

    const flattenValues = data.map((row: any) => flatten(row, row.values)).filter((row: any) => row);
    const flattenPop = data.map((row: any) => flatten(row, row.pop)).filter((row: any) => row);

    const distinctDates: string[] = Array.from(new Set(flattenValues.map((row: any) => row.aggregation_text)));

    for (const internalName of gridData.metrics) {
        const metricDefinition = gridData.gridHelper.getMetricDefinitionByInternalName(internalName, unifiedTimeseriesMetrics);
        const metricInternalName = metricDefinition.internalName;
        const metric = metricDefinition.displayName;

        const row: any = {};
        const popRowChangePctRow: any = {};
        const popRowChangeDiffRow: any = {};
        const yoyRowChangePctRow: any = {};
        const yoyRowChangeDiffRow: any = {};
        const lyRow: any = {};

        const makePath = (segment: string | null = null) => {
            const path = [];

            if (metricDefinition.parent) {
                path.push(metricDefinition.parent);
            }

            path.push(metricInternalName);

            if (segment) {
                path.push(`${metricInternalName}.${segment}`);
            }

            return path;
        };

        row[unifiedTimeseriesMetricsGridColumns.metric] = metric;
        row['metricName'] = metricInternalName;
        row.path = makePath();
        row.type = 'actual';

        popRowChangePctRow[unifiedTimeseriesMetricsGridColumns.metric] = `${metric} PoP % Change`;
        popRowChangePctRow['metricName'] = metricInternalName;
        popRowChangePctRow.path = makePath('pop_change');
        popRowChangePctRow.type = 'pop_change';

        popRowChangeDiffRow[unifiedTimeseriesMetricsGridColumns.metric] = `${metric} PoP Diff`;
        popRowChangeDiffRow['metricName'] = metricInternalName;
        popRowChangeDiffRow.path = makePath('pop_diff');
        popRowChangeDiffRow.type = 'pop_diff';

        yoyRowChangePctRow[unifiedTimeseriesMetricsGridColumns.metric] = `${metric} YoY % Change`;
        yoyRowChangePctRow['metricName'] = metricInternalName;
        yoyRowChangePctRow.path = makePath('yoy_change');
        yoyRowChangePctRow.type = 'yoy_change';

        yoyRowChangeDiffRow[unifiedTimeseriesMetricsGridColumns.metric] = `${metric} YoY Diff`;
        yoyRowChangeDiffRow['metricName'] = metricInternalName;
        yoyRowChangeDiffRow.path = makePath('yoy_diff');
        yoyRowChangeDiffRow.type = 'yoy_diff';

        lyRow[unifiedTimeseriesMetricsGridColumns.metric] = `${metric} LY`;
        lyRow['metricName'] = metricInternalName;
        lyRow.path = makePath('ly');
        lyRow.type = 'ly';

        for (const date of distinctDates) {
            const actualForDate = flattenValues.find((row: any) => row.aggregation_text === date);
            const popForDate = flattenPop.find((row: any) => row.aggregation_text === date);
            const yoyForDate = flattenValues.find((row: any) => row.aggregation_text === date);

            row[date] = actualForDate?.[metricInternalName];
            popRowChangePctRow[date] = calculatePoPChange(actualForDate?.[metricInternalName], popForDate?.[metricInternalName]);
            popRowChangeDiffRow[date] = actualForDate?.[metricInternalName] - popForDate?.[metricInternalName];
            yoyRowChangePctRow[date] = calculatePoPChange(actualForDate?.[metricInternalName], yoyForDate?.[metricInternalName]);
            yoyRowChangeDiffRow[date] = actualForDate?.[metricInternalName] - yoyForDate?.[metricInternalName];
            lyRow[date] = yoyForDate?.[metricInternalName];
        }

        output.push(row);

        if (gridData.hasPoPChange) {
            output.push(popRowChangePctRow);
        }

        if (gridData.hasPoPDifference) {
            output.push(popRowChangeDiffRow);
        }

        if (gridData.hasYoYChange) {
            output.push(yoyRowChangePctRow);
        }

        if (gridData.hasYoYDifference) {
            output.push(yoyRowChangeDiffRow);
        }

        if (gridData.hasLyActuals) {
            output.push(lyRow);
        }
    }

    return output;
}
