import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PusherService } from './pusher.service';

export interface ChangeNotification {
    account: string;
    date: Date;
    type: string;
}

@Injectable()
export class ChangeNotificationListenerService {
    public $onReceive: Subject<ChangeNotification> = new Subject<ChangeNotification>();

    public constructor(
        private pusherService: PusherService,
    ) {
        this.bindEvents();
    }

    private bindEvents(): void {
        this.pusherService
            .pusher
            .subscribe('change-notifications')
            .bind('change-notification', (data: any) => {
                this.$onReceive.next({
                    account: data.account,
                    date: new Date(data.date),
                    type: data.type
                });
            });
    }
}
