import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { LayoutComponent } from './components/layout/layout.component';
import { CartLoginCallbackComponent } from './components/layout/components/cart-login-callback.component';
import { RedirectComponent } from './components/layout/components/redirect-component.component';

const routes: Routes = [
    { path: '', redirectTo: 'redirect', pathMatch: 'prefix' },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'optimize', loadChildren: () => import('src/app/pages/optimize/optimize.module').then(m => m.OptimizeModule)},
            { path: 'investigate', loadChildren: () => import('src/app/pages/investigate/investigate.module').then(m => m.InvestigateModule) },
            { path: 'measures', loadChildren: () => import('src/app/pages/measure/measure.module').then(m => m.MeasureModule) },
            { path: 'manage', loadChildren: () => import('src/app/pages/manage/manage.module').then(m => m.ManageModule) },
            { path: 'settings', loadChildren: () => import('src/app/pages/settings/settings.module').then(m => m.SettingsModule) },
            { path: 'onboard', loadChildren: () => import('src/app/pages/onboard/onboard.module').then(m => m.OnboardModule) },
            { path: 'documents', loadChildren: () => import('src/app/pages/documents/document.module').then(m => m.DocumentModule) },
            { path: 'profitability', loadChildren: () => import('src/app/pages/profitability/profitability.module').then(m => m.ProfitabilityModule) },
            { path: 'cross-clients', loadChildren: () => import('src/app/pages/cross-clients/cross-clients.module').then(m => m.CrossClientsModule) },
            { path: 'user-analytics', loadChildren: () => import('src/app/pages/user-analytics/user-analytics.module').then(m => m.UserAnalyticsModule) },
            { path: 'vendor-central', loadChildren: () => import('src/app/pages/vendor-central/vendor-central.module').then(m => m.VendorCentralModule) },
            { path: 'scorecard', loadChildren: () => import('src/app/pages/scorecard/scorecard.module').then(m => m.ScorecardModule) },
            { path: 'planning', loadChildren: () => import('src/app/pages/planning/planning.module').then(m => m.PlanningModule) },
            { path: 'data-integrity', loadChildren: () => import('src/app/pages/data-integrity/data-integrity.module').then(m => m.DataIntegrityModule) },
            { path: 'forecast', loadChildren: () => import('src/app/pages/forecast/forecast.module').then(m => m.ForecastModule) },
            { path: 'generated-reports', loadChildren: () => import('src/app/pages/generated-reports/generated-reports.module').then(m => m.GeneratedReportsModule) },
            { path: 'growth-marketing', loadChildren: () => import('src/app/pages/sigma-report/sigma-report.module').then(m => m.SigmaReportModule) },
            { path: 'unified-reports/summary', loadChildren: () => import('src/app/pages/unified-reports/unified-overview/unified-overview.module').then(m => m.UnifiedOverviewModule) },
            { path: 'growth-marketing-explore', loadChildren: () => import('src/app/pages/tableau-report/tableau-report.module').then(m => m.TableauReportModule) },
            { path: 'unified-reports/timeseries', loadChildren: () => import('src/app/pages/unified-reports/unified-timeseries/unified-timeseries.module').then(m => m.UnifiedTimeseriesModule) },
            { path: 'redirect', component: RedirectComponent },
        ],
    },
    { path: 'login/callback', component: CartLoginCallbackComponent, },
    { path: 'unsubscribe-email', loadChildren: () => import('src/app/pages/unsubscribe-email/unsubscribe-email.module').then(m => m.UnsubscribeEmailModule) },
    { path: 'scorecard/unsubscribe-email', loadChildren: () => import('src/app/pages/unsubscribe-email/unsubscribe-email.module').then(m => m.UnsubscribeEmailModule) },
    { path: '**', redirectTo: 'redirect' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
