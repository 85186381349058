import { Injectable } from '@angular/core';
import { FilterChangedEvent, SortChangedEvent } from 'ag-grid-community';
import { EventTrackService } from './event-track.service';

@Injectable()
export class UrlManipulationService {
    public constructor(private eventTrackService: EventTrackService) { }

    public setAgGridFilterToUrl(pageTitle: string, uriIdentifier: string, filter: FilterChangedEvent) {
        const filtermodel = JSON.stringify(filter.api.getFilterModel());
        const url = new URL(window.location.href);

        url.searchParams.set(uriIdentifier, filtermodel);
        window.history.pushState(null, '', url);

        this.eventTrackService.registerFilterTable(filter.api.getFilterModel());
    }

    public setAgGridSortToUrl(pageTitle: string, uriIdentifier: string, sort: SortChangedEvent) {
        const sortedColumns = sort.columnApi.getColumnState().filter(col => col.sort);
        const columnstate = { state: [{}] };

        if (sortedColumns.length > 0) {
            sortedColumns.forEach(col => {
                columnstate.state.push({ colId: col.colId, sort: col.sort });
            });
        }

        const url = new URL(window.location.href);

        url.searchParams.set(uriIdentifier, JSON.stringify(columnstate));
        window.history.pushState(null, '', url);

        this.eventTrackService.registerSortTable({
            sort_model: JSON.stringify(columnstate.state),
            report: pageTitle
        });
    }
}
