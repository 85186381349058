import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { getUniqueColorList } from "../utils/ag-grid.util";

declare let zingchart: any;

@Injectable()
export class ChartHelperService {
    public chartsDef = {
        products: {
            1: {
                title: 'Conversion Rate vs Sessions',
                x: 'Conversion Rate Percentile',
                y: 'Sessions Percentile',
                bubbleMax: 100
            },
            2: {
                title: 'Ad CTR vs Impressions',
                x: 'Ad Click Through Rate Percentile',
                y: 'Impressions Percentile',
                bubbleMax: 100
            },
            3: {
                title: 'Ad Conversion Rate vs Clicks',
                x: 'Ad Conversion Rate Percentile',
                y: 'Clicks Percentile',
                bubbleMax: 50
            },
            4: {
                title: 'Ad Spend vs ROAS',
                x: 'Ad Spend Percentile',
                y: 'Return On Ad Spend Percentile',
                bubbleMax: 50
            }
        },
        campaigns: {
            1: {
                title: 'Ad CTR vs Impressions',
                x: 'Ad Click Through Rate Percentile',
                y: 'Impressions Percentile',
                bubbleMax: 100
            },
            2: {
                title: 'Conversion Rate vs Clicks',
                x: 'Conversion Rate Percentile',
                y: 'Clicks Percentile',
                bubbleMax: 50
            },
            3: {
                title: 'Ad Spend vs ROAS',
                x: 'Ad Spend Percentile',
                y: 'Return On Ad Spend Percentile',
                bubbleMax: 50
            }
        },
        keywords: {
            1: {
                title: 'Ad CTR vs Impressions',
                x: 'Ad Click Through Rate Percentile',
                y: 'Impressions Percentile',
                bubbleMax: 100
            },
            2: {
                title: 'Conversion Rate vs Clicks',
                x: 'Conversion Rate Percentile',
                y: 'Clicks Percentile',
                bubbleMax: 50
            },
            3: {
                title: 'Ad Spend vs ROAS',
                x: 'Ad Spend Percentile',
                y: 'Return On Ad Spend Percentile',
                bubbleMax: 50
            }
        }
    };

    public currentNodeId: Observable<{ type: string; value: string }>;
    public currentStatus: Observable<boolean>;

    private nodeId = new BehaviorSubject({ type: '', value: '' });
    private refreshFlag = new BehaviorSubject(true);

    public constructor() {
        this.currentNodeId = this.nodeId.asObservable();
        this.currentStatus = this.refreshFlag.asObservable();
    }

    public defineChart(title: string, x_label: string, y_label: string, tooltip: string, bubbleMaxSize: number, legendLayout: string) {
        return {
            title: {
                text: title,
                width: 800,
                height: 30,
                'offset-x': 15,
                'offset-y': 10,
                'padding-top': "10px",
                'font-size': 14,
            },
            noData: {
                text: "No data",
                fontSize: 20,
                textAlpha: .9,
                alpha: .6
            },
            backgroundColor: 'transparent',
            type: 'mixed',
            plotarea: {
                'margin-bottom': "12%",
                marginLeft: 'dynamic'
            },
            globals: {
                'font-family': 'museo-sans',
                'font-size': 14
            },
            scaleX: {
                zooming: true,
                minValue: 0,
                format: "%v%",
                maxValue: 100,
                step: 20,
                label: {
                    text: x_label,
                    'font-size': 14,
                    'font-weight': "none"
                },
                item: {
                    'font-size': 14
                },
                markers: [{
                    type: 'area',
                    range: [0, 50],
                    valueRange: true,
                    backgroundColor: '#FCF8E8',
                    alpha: 0.2,
                },
                {
                    type: 'area',
                    range: [50, 100],
                    valueRange: true,
                    backgroundColor: '#ECB390',
                    alpha: 0.2,
                }
                ]
            },
            scaleY: {
                zooming: true,
                minValue: 0,
                format: "%v%",
                maxValue: 100,
                step: 20,
                label: {
                    text: y_label,
                    'font-size': 14,
                    'font-weight': "none"
                },
                item: {
                    'font-size': 14
                },
                markers: [{
                    type: 'area',
                    range: [0, 50],
                    valueRange: true,
                    backgroundColor: '#ECDFC8',
                    alpha: 0.2
                },
                {
                    type: 'area',
                    range: [50, 100],
                    valueRange: true,
                    backgroundColor: '#DF7861',
                    alpha: 0.2
                }
                ]
            },
            scrollX: {},
            scrollY: {},
            legend: {
                'toggle-action': "remove",
                'background-color': "transparent",
                layout: legendLayout,
                "adjust-layout": true,
                'border-width': 0,
                "align": "center",
                "vertical-align": "bottom"
            },
            tooltip: {
                "html-mode": true,
                text: tooltip,
                'background-color': "white",
                'border-width': 1,
                'border-color': "grey",
                'border-radius': "9px",
                'font-color': "black",
                'font-size': 14,
                padding: "20%",
                alpha: 0.9

            },
            plot: {
                minSize: 4,
                maxSize: bubbleMaxSize,
                marker: {
                    'border-color': "black",
                    'border-width': 1,
                    alpha: 0.7
                }
            },
            series: [] as any
        };
    }

    public setLegendStatus(charts: any, legendVisibilityMap: any) {
        for (let i = 0; i < charts.series.length; i++) {
            charts.series[i].visible = legendVisibilityMap[charts.series[i].text];
        }

        return charts;
    }

    public loadingScreen(id: string) {
        const noData = {
            noData: {  text: 'Loading...',  backgroundColor: '#FFFFFF', fontSize: '14px' },

            series: [{
                values: []
            }],
        };

        this.renderChart(noData, id);
    }

    public renderChart(chartData: any, id: string) {
        zingchart.render({
            id: id,
            width: '100%',
            height: '100%',
            data: chartData
        });
    }

    public exportToPNG(id: string, fileName: string) {
        zingchart.exec(id, 'getimagedata', {
            filetype: 'png',
            callback: (image: any) => {
                const a = document.createElement("a");

                a.href = image;
                a.download = fileName;
                a.click();
                a.remove();
            }
        });
    }

    public defineColors(charts: Array<any>) {
        const colors = getUniqueColorList();
        const colorMap: Array<{}> = [];

        let allCaptions = charts
            .map((chart: any) => chart.series.map((serieItem: any) => serieItem.text))
            .flat();

        allCaptions = allCaptions.filter((item: any, pos: number) => allCaptions.indexOf(item) == pos);

        for (let i = 0; i < allCaptions.length; i++) {
            const text = allCaptions[i];
            const color = colors[i];

            colorMap[text] = color;
        }

        charts.forEach((chart: any) => {
            chart.series.forEach((seriesItem: any) => {
                let color = colorMap[seriesItem.text];

                seriesItem['background-color'] = color;

                seriesItem['marker'] = {
                    'background-color': color,
                };
            });
        });
    }

    public prepareForAnimation(charts: Array<any>, animationStep: string) {
        const colors = getUniqueColorList();

        charts.forEach((chart: any) => {
            chart.series.forEach((seriesItem: any) => {
                const visible = seriesItem['animationPeriod'] === animationStep;
                const color = colors[0];

                seriesItem['visible'] = visible;
                seriesItem['background-color'] = color;

                seriesItem['marker'] = {
                    'background-color': color,
                    'visible': visible
                };
            });
        });
    }

    public updateNodeId(type: string, id: string) {
        this.nodeId.next({ type, value: id });
    }

    public setRefreshStatus() {
        this.refreshFlag.next(true);
    }
}
