import { Component, HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { VersionCheckService } from './services/check-version.service';
import { EventTrackService } from './services/event-track.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserTimeTrackingService } from './services/user-time-tracking.service';
import { IntercomService } from './services/intercom.service';
import { ClarityService } from './services/clarity.service';

interface AppIcon {
    name: string;
    path: string;
}

@Component({
    selector: '#cartdotcom-container',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    public buildIsUpToDate = true;

    public constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitzer: DomSanitizer,
        private router: Router,
        private userTimeTracking: UserTimeTrackingService,
        private intercomService: IntercomService,
        private clarityService: ClarityService,
        versionCheckService: VersionCheckService,
        eventTrackService: EventTrackService,
    ) {
        const appIcons: AppIcon[] = [
            { name: 'amify-white', path: 'assets/icons/amify-white.svg' },
            { name: 'analyze', path: 'assets/icons/analyze.svg' },
            { name: 'compete', path: 'assets/icons/compete.svg' },
            { name: 'manage', path: 'assets/icons/manage.svg' },
            { name: 'measure', path: 'assets/icons/measure.svg' },
            { name: 'onboard', path: 'assets/icons/onboard.svg' },
            { name: 'optimize', path: 'assets/icons/optimize.svg' },
            { name: 'profile', path: 'assets/icons/profile.svg' },
            { name: 'report', path: 'assets/icons/report.svg' },
            { name: 'settings', path: 'assets/icons/settings.svg' },
            { name: 'target', path: 'assets/icons/target.svg' },
            { name: 'x-channel', path: 'assets/icons/x-channel.svg' },
            { name: 'investigate', path: 'assets/icons/Investigate.svg' },
            { name: 'looker', path: 'assets/icons/looker.svg' },
            { name: 'nav-white-left', path: 'assets/icons/arrow.svg' },
            { name: 'nav-white-right', path: 'assets/icons/arrow-right.svg' },
            { name: 'chevron-right', path: 'assets/icons/chevron-right.svg' },
            { name: 'chevron-down', path: 'assets/icons/chevron-down.svg' },
            { name: 'amify', path: 'assets/icons/amify.svg' },
            { name: 'information', path: 'assets/icons/information.svg' },
            { name: 'menu', path: 'assets/icons/menu.svg' },
            { name: 'logout', path: 'assets/icons/logout.svg' },
            { name: 'copy', path: 'assets/icons/copy.svg' },
            { name: 'edit', path: 'assets/icons/edit.svg' },
            { name: 'delete', path: 'assets/icons/trash.svg' },
            { name: 'user-guide', path: 'assets/icons/user-guide.svg' },
            { name: 'vendor-central', path: 'assets/icons/vendor-central.svg' },
            { name: 'forecast', path: 'assets/icons/forecast.svg' },
            { name: 'circle-question', path: 'assets/icons/circle-question.svg' },
            { name: 'products', path: 'assets/icons/products.svg' },
            { name: 'advertising', path: 'assets/icons/advertising.svg' },
            { name: 'cross-clients', path: 'assets/icons/cross-clients.svg' },
            { name: 'drill-down-date',  path: 'assets/icons/drill-down-date.svg' },
            { name: 'drill-down-keyword', path: 'assets/icons/drill-down-keyword.svg' },
            { name: 'drill-down-campaign', path: 'assets/icons/drill-down-campaign.svg' },
            { name: 'drill-down-other-asin', path: 'assets/icons/drill-down-other-asin.svg' },
            { name: 'explore', path: 'assets/icons/explore.svg' },
            { name: 'planning', path: 'assets/icons/planning.svg' },
            { name: 'scorecard', path: 'assets/icons/scorecard.svg' },
            { name: 'data-integrity', path: 'assets/icons/data-integrity.svg' },
        ];

        appIcons.forEach(({ name, path }: AppIcon) => {
            this.matIconRegistry.addSvgIcon(name, this.domSanitzer.bypassSecurityTrustResourceUrl(path));
        });

        console.log('Current environment:', environment.environmentName);

        versionCheckService.buildIsUpToDate.subscribe(buildIsUpToDate => {
            this.buildIsUpToDate = buildIsUpToDate;
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const [nextPage] = event.urlAfterRedirects.split('?');

                if (nextPage !== this.userTimeTracking.getCurrentPage()) {
                    this.userTimeTracking.stopPageTimerAndPersist();
                    this.userTimeTracking.startPageTimer(nextPage);
                }

                this.intercomService.boot();
                this.clarityService.boot();
            }
        });
    }

    @HostListener('document:visibilitychange', ['$event'])
    public visibilitychange() {
        if (document.hidden) {
            this.userTimeTracking.stopPageTimerAndPersist();

            return;
        }

        const currentPage = this.userTimeTracking.getCurrentPage();

        this.userTimeTracking.startPageTimer(currentPage);
    }

    @HostListener('window:mousemove')
    public refreshUserState() {
        this.userTimeTracking.markAsNotIdle();
    }
}
