export function getDataDictionary() {
    const windowDictionary = (window as any).DATA_DICTIONARY;

    if (windowDictionary) {
        return windowDictionary;
    }

    const localStorageDictionary = localStorage.getItem('DATA_DICTIONARY');

    if (localStorageDictionary) {
        return JSON.parse(localStorageDictionary);
    }
}
