export type SupportedCurrencies = 'EUR' | 'USD' | 'GBP' | 'CAD' | 'MXN' | 'BRL';

export type Currency = { [key in SupportedCurrencies]: (value: number, opts?: Intl.NumberFormatOptions) => string };

const SUPPORTED_CURRENCIES: Currency = {
    EUR: (value: number, opts?: Intl.NumberFormatOptions) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR', ...opts }).format(value),
    USD: (value: number, opts?: Intl.NumberFormatOptions) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', ...opts }).format(value),
    CAD: (value: number, opts?: Intl.NumberFormatOptions) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD', ...opts }).format(value),
    GBP: (value: number, opts?: Intl.NumberFormatOptions) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP', ...opts }).format(value),
    MXN: (value: number, opts?: Intl.NumberFormatOptions) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'MXN', ...opts }).format(value),
    BRL: (value: number, opts?: Intl.NumberFormatOptions) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'BRL', ...opts }).format(value),
};

export const formatCurrency = (value: number, currency: SupportedCurrencies, opts?: Intl.NumberFormatOptions): string => {
    const isCurrencySupported = currency in SUPPORTED_CURRENCIES;

    if (!isCurrencySupported) {
        return SUPPORTED_CURRENCIES['USD'](value, opts);
    }

    return SUPPORTED_CURRENCIES[currency](value, opts);
};

export const getCurrencySymbol = (currency: string, locale: string = 'en-US'): string => {
    const [symbol] = Intl.NumberFormat(locale, { style: 'currency', currency })
        .formatToParts(0)
        .filter(part => part.type === 'currency');

    return symbol.value;
};
