import { Injectable } from '@angular/core';
import { BuildVersion } from '../build-version';

@Injectable({ providedIn: 'root' })
export class BuildVersionService {
    public buildVersion: BuildVersion;

    public constructor() {
        this.buildVersion = new BuildVersion();
    }
}
