import { Injectable } from "@angular/core";
import { UserAnalyticsService } from "./user-analytics.service";
import { EventTrackService } from "./event-track.service";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserTimeTrackingService {
    public readonly MIN_STAY_ON_PAGE_IN_SECONDS = 5;
    public readonly IDLE_TIME_IN_MS = 60 * 1000;
    public readonly ONE_SECOND_IN_MS = 1000;

    private idle: boolean = false;
    private pageTimer: NodeJS.Timeout = setTimeout(() => {}, 0);
    private idleTimer: NodeJS.Timeout = setTimeout(() => {}, 0);
    private currentPage: string = '';
    private timeSpent: number = 0;

    public constructor(
        private readonly userAnalyticsService: UserAnalyticsService,
        private readonly eventTrackService: EventTrackService
    ) { }

    public getCurrentPage(): string {
        return this.currentPage;
    }

    public getTimeSpent(): number {
        return this.timeSpent;
    }

    public eligibleForPersisting(): boolean {
        return this.timeSpent >= this.MIN_STAY_ON_PAGE_IN_SECONDS && !this.idle;
    }

    public startPageTimer(pageUrl: string) {
        clearInterval(this.pageTimer);

        this.currentPage = pageUrl;
        this.timeSpent = 0;

        this.pageTimer = setInterval(() => {
            this.timeSpent += 1;
        }, this.ONE_SECOND_IN_MS);

        this.startIdleTimer();
    }

    public startIdleTimer() {
        clearTimeout(this.idleTimer);

        this.idleTimer = setTimeout(() => {
            this.persist();
            this.idle = true;
            this.timeSpent = 0;
            this.stopPageTimer();
        }, this.IDLE_TIME_IN_MS);
    }

    public markAsNotIdle() {
        if (this.idle) {
            this.startPageTimer(this.currentPage);
        }

        this.idle = false;
        this.startIdleTimer();
    }

    public stopPageTimer() {
        clearInterval(this.pageTimer);
    }

    public async persist() {
        if (!environment.production && environment.environmentName !== 'staging') {
            return;
        }

        if (!this.eligibleForPersisting()) {
            return;
        }

        const { currentDashboard, currentModule, currentQueryParams: fields } = this.eventTrackService.context;

        if (!currentDashboard && !currentModule) {
            return;
        }

        lastValueFrom(await this.userAnalyticsService.persistTimeSpent({
            dashboard: currentDashboard,
            module: currentModule,
            timeSpent: this.timeSpent,
            account: fields['account'] || (fields['Account Name'] || 'All Accounts')
        }));
    }

    public async stopPageTimerAndPersist() {
        this.stopPageTimer();
        this.persist();
    }
}
