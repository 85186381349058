import { Injectable } from '@angular/core';
import { EventTrackService } from './event-track.service';
import { Router } from '@angular/router';

@Injectable()
export class PageTitleService {
    private currentTitle!: string;

    public constructor(
        private readonly eventTrackService: EventTrackService,
        private readonly router: Router
    ) { }

    public updatePageTitleBasedOnUrl(pageTitle: string, params: any = {}, moduleName?: string) {
        let fragments: any[] = ['Cart.com | Reporting', pageTitle];
        const ignoreKeys = ['skus', 'product_titles', 'allAccounts'];
        const includeKeys: string[] = [];

        const isJson = (json: string) => {
            try {
                JSON.parse(json);

                return true;
            } catch (e) {
                return false;
            }
        };

        const paramsKeys = Object.keys(params || {});

        paramsKeys.forEach(key => {
            if (!includeKeys.includes(key)) {
                return;
            }

            const filter = params[key];

            if (filter == '[]' || !filter) {
                return;
            }

            if (filter) {
                if (filter instanceof Date) {
                    fragments.push(filter.toISOString().split('T')[0]);

                    return;
                } else if (isJson(filter)) {
                    const jsonFilter = JSON.parse(filter);

                    if (Array.isArray(jsonFilter)) {
                        fragments = fragments.concat(jsonFilter);

                        return;
                    }
                }

                fragments.push(filter);
            }
        });

        document.title = fragments.join(' | ');

        if (this.currentTitle !== pageTitle) {
            this.currentTitle = pageTitle;

            const filters = { ...params };

            ignoreKeys.forEach((key: string) => delete filters[key]);

            this.eventTrackService.registerPageView(pageTitle, filters, moduleName);
        }
    }

    public getPageTitle(): string {
        return this.router.url
            .split('/')
            .filter(subpath => subpath)
            .map(subpath => subpath.charAt(0).toUpperCase() + subpath.slice(1)).join(' ')
            .split('?')[0];
    }
}
