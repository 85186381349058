import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { EventTrackService } from './event-track.service';

@Injectable()
export class ErrorHandlerService {
    public constructor(
        private messageService: MessageService,
        private eventTrackService: EventTrackService,
    ) { }

    public handle(error: any) {
        this.eventTrackService.registerError(error);

        console.error(error);

        const message = this.getFriendlyErrorMessage(error);

        if (!message) {
            return;
        }

        this.messageService.clear();

        this.messageService.add({
            severity: 'error',
            summary: message,
            detail: message,
            sticky: true,
        });
    }

    private getFriendlyErrorMessage(error: any) {
        return 'An error occurred';
    }
}
