import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuBarComponent } from './menu-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
    declarations: [
        MenuBarComponent
    ],
    exports: [
        MenuBarComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        RouterModule,
        TooltipModule
    ]
})
export class MenuBarModule { }
