export function formatZingChartAxisPercentile(percentile: number, portion: number): number {
    if (portion > 0 && percentile == 0) {
        return 0.00001;
    }

    return percentile;
}

export function getPercentileRange(value: number) {
    if (isNaN(value)) {
        return value;
    }

    value = value > 0 ? value - 1 : 0;

    return (Math.floor(value / 10) * 10) + 10;
}

export function extractPercentRangeFilterValue(keyToCompare: string, filter: string) {
    const filterFragments = filter.split(' ');
    const value = filterFragments.shift();

    // remove percent word
    filterFragments.shift();

    const key = filterFragments.join(' ').trim();

    if (key.toLocaleLowerCase() === keyToCompare.toLocaleLowerCase()) {
        return parseInt(value || '');
    }

    return undefined;
}
