import { Injectable } from '@angular/core';
import { UserAction, UserActionTypeEnum, UserAnalyticsService } from './user-analytics.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/browser";
import { UserService } from './user.service';
import { ClarityService } from './clarity.service';

interface ActionContext {
    currentDashboard: string;
    currentModule: string;
    currentQueryParams: any;
}

interface SortModel {
    colId: string;
    sort: string;
}

@Injectable()
export class EventTrackService {
    public context: ActionContext = {
        currentDashboard: '',
        currentModule: '',
        currentQueryParams: {}
    };

    public constructor(
        private userService: UserService,
        private userAnalyticsService: UserAnalyticsService,
        private clarityService: ClarityService
    ) {}

    public async init() {
        this.loadUser();
    }

    public async loadUser() {
        let email = 'unauthenticated@user.com';
        let department = '';

        try {
            const user = await this.userService.getData();

            email = user.email;
            department = user.department;
        } catch (e) {
            throw e;
        }

        Sentry.setUser({
            email: email,
            username: email,
        });
    }

    public async registerPageView(pageName: string, queryParams = {}, moduleName?: string) {
        const module = (moduleName) ? moduleName : pageName.split(' ')[0];

        this.setContext({
            currentModule: module,
            currentDashboard: pageName,
            currentQueryParams: queryParams
        });

        const action = this.getActionPayload();

        this.dispatchAction(action);

        this.clarityService.changePage(pageName);

        Sentry.setContext("current_page", {
            page_name: pageName
        });
    }

    public async registerApplyFilter(filterData: { [key: string]: any }) {
        const fields = filterData;

        this.setContext({ ...this.context, currentQueryParams: fields });

        const action = this.getActionPayload();

        this.dispatchAction({
            ...action,
            type: UserActionTypeEnum.ApplyFilter,
            fields
        });

        Sentry.setContext("applied_filter", {
            filterData
        });
    }

    public async registerSelectAsin(asin: string) {
        const action = this.getActionPayload();

        this.dispatchAction({
            ...action,
            type: UserActionTypeEnum.SelectAsin
        });

        Sentry.setContext("selected_asin", {
            asin
        });
    }

    public async registerFilterTable(filterData: { [key: string]: any }) {
        const fields = filterData;

        const action = this.getActionPayload();

        this.dispatchAction({
            ...action,
            type: UserActionTypeEnum.FilterTable,
            fields
        });

        Sentry.setContext("applied_table_filter", filterData);
    }

    public async registerSortTable(sortData: { [key: string]: any }) {
        const sortModel: SortModel[] = JSON.parse(sortData['sort_model']);

        const formattedSortModel: string = sortModel
            .filter((model: SortModel) => Object.keys(model).length > 0)
            .map((model: SortModel) => `${model.colId}-${model.sort}`)
            .join(', ');

        const action = this.getActionPayload();

        this.dispatchAction({
            ...action,
            type: UserActionTypeEnum.SortTable,
            fields: { ...this.context.currentQueryParams, sort: formattedSortModel }
        });

        Sentry.setContext("applied_sort_filter", sortData);
    }

    public async registerUserGuideView(section: string) {
        const action = this.getActionPayload();

        this.dispatchAction({
            ...action,
            type: UserActionTypeEnum.ViewUserGuide,
            module: 'Documents',
            dashboard: 'Documents User Guide',
            fields: { section }
        });

        Sentry.setContext("clicked_on_user_guide", { section });
    }

    public async registerExportButtonClick(params: { fileName: string; reportName: string }) {
        const action = this.getActionPayload();

        const { fileName, reportName } = params;

        this.dispatchAction({
            ...action,
            type: UserActionTypeEnum.ExportButton,
            fields: {
                ...action.fields,
                fileName,
                reportName
            }
        });

        Sentry.setContext("clicked_on_export_button", { fileName, reportName });
    }

    public registerError(error: any) {
        Sentry.captureException(error);
    }

    private setContext(context: ActionContext): void {
        this.context = context;
    }

    private async dispatchAction(action: UserAction): Promise<void> {
        if (!environment.production && environment.environmentName !== 'staging') {
            return;
        }

        lastValueFrom(await this.userAnalyticsService.dispatchAction({...action}));
    }

    private getActionPayload(): UserAction {
        const fields = this.context.currentQueryParams;

        const getAccount = (fields: { [key: string]: any }): string => {
            if ('account' in fields && fields['account']) {
                return fields['account'];
            }

            if ('accounts' in fields && fields['accounts'].length > 0) {
                return fields['accounts'].join('-');
            }

            return fields['Account Name'] || fields['account_name'] || 'All Accounts';
        };

        const getMarketplace = (fields: { [key: string]: any }): string => {
            if ('marketplaces' in fields && fields['marketplaces'].length > 0) {
                return fields['marketplaces'].join('-');
            }

            return 'US';
        };

        return {
            module: this.context.currentModule,
            dashboard: this.context.currentDashboard,
            fields: this.context.currentQueryParams,
            account: getAccount(fields),
            marketplace: getMarketplace(fields),
            type: UserActionTypeEnum.Navigate
        };
    }
}
