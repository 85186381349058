import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "../user.service";

export interface CreateOrUpdateData {
    id?: string;
    accounts: string[];
    marketplaces: string[];
    currency: string;
    recipients: string;
    hour: string;
    day: string;
    status: string;
}

export interface BulkUpdateData {
    ids: string[];
    day: string;
    hour: string;
    status: string;
}

@Injectable()
export class ScorecardSettingsService {
    public base_url = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async getAll(): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.base_url + 'scorecard/settings/configurations');

            return this.http.get<Array<any>>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getAccounts(): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.base_url + 'scorecard/settings/accounts');

            return this.http.get<Array<any>>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getTemplates(): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.base_url + 'scorecard/settings/templates');

            return this.http.get<Array<any>>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async createScorecardConfiguration(data: CreateOrUpdateData): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(`${this.base_url}scorecard/settings/configurations`);

            return this.http.post(url.toString(), data, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async updateScorecardConfiguration(data: CreateOrUpdateData): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(`${this.base_url}scorecard/settings/configurations/${data.id}`);

            return this.http.put(url.toString(), data, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async deleteScorecardConfiguration(id: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(`${this.base_url}scorecard/settings/configurations/${id}`);

            url.searchParams.append('id', id);

            return this.http.delete(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async bulkUpdateScorecardConfigurations(data: BulkUpdateData): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(`${this.base_url}scorecard/settings/configurations/bulk-update`);

            return this.http.post(url.toString(), data, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async unsubscribeEmail(email: string, reportTypes: string[]): Promise<Observable<any>> {
        try {
            const url = new URL(`${this.base_url}scorecard/settings/email/unsubscribe`);

            return this.http.post(url.toString(), {
                email: email,
                reportTypes: reportTypes
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async checkIfEmailIsUnsubscribed(emails: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(`${this.base_url}scorecard/settings/email/is-unsubscribed`);

            url.searchParams.append('emails', emails);

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }
}
