import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

export enum ForecastGranularity {
    Category = 'CATEGORY',
    Account = 'ACCOUNT',
};

export interface ForecastHistoricalFilters {
  accounts: string[];
  marketplaces: string[];
  brands: string[];
  lookbackPeriod: number;
}

export interface ForecastRequest {
    account: string;
    marketplace: string;
    granularity: ForecastGranularity;
    categories: string[];
    year: number;
    requestedUser?: string;
}

@Injectable({
    providedIn: 'root'
})

export class ForecastService {
    private baseUrl = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async getSellerCentralForecastHistorical(forecastHistoricalFilters: ForecastHistoricalFilters): Promise<Observable<any>> {
        try {
            const { accounts, marketplaces, lookbackPeriod } = forecastHistoricalFilters;

            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.baseUrl + 'forecast/seller-central/historical-data', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    'marketplaces[]': marketplaces,
                    lookbackPeriod
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getVendorCentralForecastHistorical(forecastHistoricalFilters: ForecastHistoricalFilters): Promise<Observable<any>> {
        try {
            const { accounts, marketplaces, brands, lookbackPeriod } = forecastHistoricalFilters;

            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.baseUrl + 'forecast/vendor-central/historical-data', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    'marketplaces[]': marketplaces,
                    'brands[]': brands,
                    lookbackPeriod
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getAccountMonthlySales(accountName: string, marketplace: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.baseUrl + 'forecast/account-montly-sales', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'account': accountName,
                    'marketplace': marketplace
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getAccountCategoryMonthlySales(accountName: string, marketplace: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.baseUrl + 'forecast/account-category-montly-sales', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'account': accountName,
                    'marketplace': marketplace
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async sendForecastRequest(data: ForecastRequest): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            data.requestedUser = user.email;

            const url = new URL(this.baseUrl + 'forecast/forecast-request');

            return this.http.post(url.toString(), data, {
                reportProgress: true,
                observe: 'events',
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getForecastRequests(accounts: string[], marketplaces: string[]): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.baseUrl + 'forecast/forecast-requests', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    'marketplaces[]': marketplaces,
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getForecastResult(requestId: string, granularity: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.baseUrl + 'forecast/forecast-result', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    requestId: requestId,
                    granularity: granularity
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getForecastRequestById(requestId: string, granularity: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.baseUrl + 'forecast/forecast-request-by-id', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    requestId: requestId,
                    granularity: granularity
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }
}
