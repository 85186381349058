import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable()
export class TableauReportService {
    public baseUrl = environment.apiV2Url;

    public constructor(private http: HttpClient, private userService: UserService) { }

    public async getTrustedTicket(): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const url = new URL(this.baseUrl + 'tableau-report/get-trusted-ticket');

            const currentUserAppSetting = user.orgService?.userAppSettings?.find((s: { type: string }) => s.type === 'CURRENT_BUSINESS_CONTEXT');
            const context = currentUserAppSetting ? JSON.parse(currentUserAppSetting.setting) : undefined;

            const organization = user.orgService.organizations.find((o: { id: any }) => o.id === context?.organizationId) || user.orgService.organizations[0];
            const business = organization?.businesses.find((b: { id: any }) => b.id === context?.businessId) || organization?.businesses[0];

            console.log(organization, business);

            if (!user.orgService?.auth0Id) {
                throw new Error('auth0 id not found');
            }

            if (!organization || !business) {
                throw new Error('current context not found');
            }

            return this.http.get<string>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user?.token
                },
                params: {
                    tableauUsername: user.orgService?.auth0Id,
                    orgId: organization.id,
                    businessId: business.id
                }
            });
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getTableauPermissions(): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const url = new URL(this.baseUrl + 'tableau-report/get-user-permissions');

            const currentUserAppSetting = user.orgService?.userAppSettings?.find((s: { type: string }) => s.type === 'CURRENT_BUSINESS_CONTEXT');
            const context = currentUserAppSetting ? JSON.parse(currentUserAppSetting.setting) : undefined;
            const organization = user.orgService.organizations.find((o: { id: any }) => o.id === context?.organizationId) || user.orgService.organizations[0];

            return this.http.get<string>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user?.token
                },
                params: {
                    orgId: organization.id,
                }
            });
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }
}
