import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Auth } from 'aws-amplify';
import { CacheService } from "./cache.service";
import { buildCacheKey } from "../utils/cache.util";
import ApiResponse from "../types/api-response";
import { DateHelperService } from "./date-helper.service";

interface Event {
  notification_id: string;
  account_name: string;
  asin: string;
  sku: string;
  attr_name: string;
  old_value: string;
  new_value: string;
  assessment: string;
  brand: string;
  event_time: string;
}

interface Filter {
  from_date: Date;
  to_date: Date;
  ignore_cache?: boolean;
}

@Injectable()
export class ContentChangeNotificationsService {
    private base_url = environment.apiUrl;
    private v2Api = environment.apiV2Url;

    public constructor(
      private http: HttpClient,
      private cacheService: CacheService,
      private dateService: DateHelperService
    ) { }

    public async get_content_changes_for_account(account_name: string, filter: Filter): Promise<Observable<ApiResponse>> {
        try {
            const from_date_formatted = filter.from_date.toISOString().split('T')[0];
            const to_date_formatted = filter.to_date.toISOString().split('T')[0];

            const key = buildCacheKey(['content_changes', account_name, from_date_formatted, to_date_formatted]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if(!cache || filter.ignore_cache) {
                const user = await Auth.currentAuthenticatedUser();
                const accessToken = user.signInUserSession.accessToken.jwtToken;
                let url = new URL(this.base_url + 'notifications/content/');

                url.searchParams.append('account', account_name);
                url.searchParams.append('from_date', from_date_formatted);
                url.searchParams.append('to_date', to_date_formatted);

                result = this.http.get<Array<any>>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + accessToken!
                    }
                })
                    .pipe(map((result) => ({
                        data: result,
                        last_refresh: this.dateService.getRefreshTime(),
                    })))
                    .pipe(map((response) => {
                        this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                        return response;
                    }));
            }

            return result;
        } catch (err)  {
            return of({'status_code': 401, 'detail': <string>err});
        }
    }

    public async getDimensionsAndWeightForAccounts(account_name: string[], filter: Filter): Promise<Observable<ApiResponse>> {
        try {
            const from_date_formatted = filter.from_date.toISOString().split('T')[0];
            const to_date_formatted = filter.to_date.toISOString().split('T')[0];

            const key = buildCacheKey(['dimensions_and_weight', account_name.join(','), from_date_formatted, to_date_formatted]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if(!cache || filter.ignore_cache) {
                const user = await Auth.currentAuthenticatedUser();
                const accessToken = user.signInUserSession.idToken.jwtToken;
                let url = new URL(this.v2Api + 'manage/package/scraped-dimension-and-weight');

                result = this.http.get<Array<any>>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + accessToken!
                    },
                    params: {
                        'account[]': account_name,
                        'startDate': from_date_formatted,
                        'endDate': to_date_formatted
                    }
                })
                    .pipe(map((result) => ({
                        data: result,
                        last_refresh: this.dateService.getRefreshTime(),
                    })))
                    .pipe(map((response) => {
                        this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                        return response;
                    }));
            }

            return result;
        } catch (err)  {
            return of({'status_code': 401, 'detail': <string>err});
        }
    }

    public async get_content_change_impact_for_asin(account_name: string, amz_account: string, asin: string, event_dates: Array<string>): Promise<Observable<any>> {
        try {
            const key = buildCacheKey(['content_change_impact', amz_account, asin, event_dates]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if(!cache) {
                const user = await Auth.currentAuthenticatedUser();
                const accessToken = user.signInUserSession.accessToken.jwtToken;
                let url = new URL(this.base_url + 'notifications/content/impact/');

                url.searchParams.append('account', account_name);
                url.searchParams.append('amz_account', amz_account);
                url.searchParams.append('asin', asin);
                url.searchParams.append('event_dates', JSON.stringify(event_dates));

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + accessToken!
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async assess_content_change_notification(account_name: string, event: Event): Promise<Observable<any>> {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const accessToken = user.signInUserSession.accessToken.jwtToken;
            let url = new URL(this.base_url + 'notifications/content/assess');

            url.searchParams.append('account', account_name);

            return this.http.post(url.toString(), { event }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken!
                }
            });
        } catch (error) {
            return of({'status_code': 401, 'detail': error});
        }
    }
}
