import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { OptimizeService } from './services/optimize.service';
import { FormsModule } from '@angular/forms';
import { AccountsService } from './services/accounts.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateHelperService } from './services/date-helper.service';
import { BuildVersionHttpService } from './services/build-version.http.service';
import { Amplify } from 'aws-amplify';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { ChartHelperService } from './services/chart-helper.service';
import { GridHelperService } from './services/grid-helper.service';
import { MenuStateService } from './services/menu-state.service';
import { UrlHelperService } from './services/url-helper.service';
import { PercentileFilterService } from './services/percentile-filter.service';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { OperationIssueService } from './services/operation-issue.service';
import { ContentChangeNotificationsService } from './services/content-change-notifications.service';

import { NotificationConfigurationService } from './services/notification-configuration.service';
import { UserService } from './services/user.service';
import { IssueTicketService } from './services/issue-ticket.service';
import { LayoutModule } from './components/layout/layout.module';
import { TaosOutOfDateModule } from './components/taos-out-of-date/taos-out-of-date.module';
import { EventTrackService } from './services/event-track.service';
import { PageTitleService } from './services/page-title.service';
import { UrlManipulationService } from './services/url-manipulation.service';
import { OperationIssueChangeDetectorService } from './services/operation-issue-change-detector.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastModule } from 'primeng/toast';
import { ErrorHandlerService } from './services/error-handler.service';
import { MessageService } from 'primeng/api';
import { PusherService } from './services/pusher.service';
import { ChangeNotificationListenerService } from './services/change-notification-listener.service';
import { UserAnalyticsService } from './services/user-analytics.service';
import { ScorecardSettingsService } from './services/scorecard/scorecard-settings.service';
import { SellerCentralScorecardOverviewService } from './services/scorecard/seller-central/seller-central-scorecard-overview.service';
import { SellerCentralScorecardAdvertisingService } from './services/scorecard/seller-central/seller-central-scorecard-advertising.service';
import { VendorCentralScorecardOverviewService } from './services/scorecard/vendor-central/vendor-central-scorecard-overview.service';
import { VendorCentralScorecardAdvertisingService } from './services/scorecard/vendor-central/vendor-central-scorecard-advertising.service';
import { UserTimeTrackingService } from './services/user-time-tracking.service';
import { ClarityModule } from './libs/clarity.module';
import { IntercomService } from './services/intercom.service';
import { ClarityService } from './services/clarity.service';
import { PlanningService } from './services/planning.service';
import { FILTER_STORE_TOKEN, FilterStore } from './components/filter/filter.store';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(environment.agGridLicenseKey);

import 'ag-grid-enterprise';
import { CrossClientsService } from './services/cross-client.service';
import { ForecastService } from './services/forecast.service';
import { MetricsDictionaryService } from './services/metrics-dictionary.service';
import { ManageReplenService } from './services/manage-replen.service';
import { AmazonDownloaderService } from './services/amazon-downloader.service';
import { ManageGlobaldocService } from './services/manage-globaldoc.service';
import { AuthModule } from '@auth0/auth0-angular';
import { SigmaReportService } from './services/sigma-report.service';
import Auth0Utils from './services/auth0-utils';
import { JwtModule } from '@auth0/angular-jwt';
import { TableauReportService } from './services/tableau-report.service';

Amplify.configure({
    Auth: {
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
        region: environment.awsRegion
    }
});

export function tokenGetter() {
    return Auth0Utils.getCachedAccessToken();
}

export function initializeApp(
    buildVersionService: BuildVersionHttpService
) {
    return () => buildVersionService.fetchBuildDetails();
}

let sentryProviders: Provider[] = [];

if (environment.sentry.enabled) {
    sentryProviders = [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        }
    ];
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        LayoutModule,
        TaosOutOfDateModule,
        MatTooltipModule,
        ToastModule,
        ClarityModule.forRoot({
            enabled: environment.production,
            projectId: environment.clarity.projectId
        }),

        AuthModule.forRoot({
            domain: environment.auth0.domain,
            clientId: environment.auth0.clientId,
            authorizationParams: {
                audience: 'api.local',
                redirect_uri: window.location.origin + '/login/callback'
            },
            httpInterceptor: {
                allowedList: [
                    'api/authentication/loginWithAuth0'
                ]
            },
            cacheLocation: 'localstorage',
        }),

        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
            //   allowedDomains: ["example.com"],
            },
        }),

    ],
    providers: [
        ...sentryProviders,
        AppComponent,
        OptimizeService,
        AccountsService,
        DateHelperService,
        AuthGuardService,
        AuthService,
        ChartHelperService,
        GridHelperService,
        MenuStateService,
        UrlHelperService,
        PercentileFilterService,
        OperationIssueService,
        ContentChangeNotificationsService,
        NotificationConfigurationService,
        UserService,
        PlanningService,
        UserTimeTrackingService,
        IssueTicketService,
        EventTrackService,
        PageTitleService,
        UrlManipulationService,
        OperationIssueChangeDetectorService,
        PusherService,
        ChangeNotificationListenerService,
        ErrorHandlerService,
        UserAnalyticsService,
        MessageService,
        SellerCentralScorecardOverviewService,
        SellerCentralScorecardAdvertisingService,
        VendorCentralScorecardOverviewService,
        VendorCentralScorecardAdvertisingService,
        ForecastService,
        CrossClientsService,
        IntercomService,
        ClarityService,
        ScorecardSettingsService,
        ManageReplenService,
        AmazonDownloaderService,
        ManageGlobaldocService,
        SigmaReportService,
        TableauReportService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [BuildVersionHttpService, MetricsDictionaryService],
            multi: true
        },
        {
            provide: FILTER_STORE_TOKEN,
            useFactory: (injector: Injector) => new FilterStore(injector),
            deps: [Injector]
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
