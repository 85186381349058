import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

export interface UpdateAmazonDownloaderAccountParams {
    amzAccount: string;
    login: string;
    configJson: string;
    entityId: string;
}

@Injectable()
export class AmazonDownloaderService {
    public baseUrl = environment.apiV2Url;

    public constructor(
        private readonly http: HttpClient,
        private readonly userService: UserService,
    ) { }

    public async getLogins(): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const accessToken = user.token;

            const url = new URL(this.baseUrl + 'amazon-downloader/logins');

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
                params: {}
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getAccounts(login: string, marketplaces: string[]): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const accessToken = user.token;

            const url = new URL(this.baseUrl + 'amazon-downloader/accounts');

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
                params: {
                    'login': login,
                    'marketplaces[]': marketplaces,
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async updateAccountData(params: UpdateAmazonDownloaderAccountParams): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const accessToken = user.token;

            const url = new URL(this.baseUrl + 'amazon-downloader/account-data');

            return this.http.post(url.toString(), params, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }
}
