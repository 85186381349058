import { Injectable } from "@angular/core";
import { ColDef, GridOptions } from "ag-grid-community";
import { BehaviorSubject, Observable } from "rxjs";
import { formatAsPercent, formatToLocaleString, round } from 'src/app/utils/number-format.util';
import { getPoPKeyName } from 'src/app/utils/scorecard.util';
import { makeMetricTooltipConfig } from 'src/app/utils/make-custom-metric-tooltip-config.util';
import { formatCurrency, SupportedCurrencies } from "../utils/currency.util";

export interface TimeseriesGridData {
    gridHelper: GridHelperService;
    dates: any[];
    metrics: string[];
    currency: SupportedCurrencies;
    metricsMap: any;
    metricsDefinitions?: any[];
    reportLevel: string;
    hasActuals: boolean;
    hasPoPChange: boolean;
    hasPoPDifference: boolean;
    hasYoYChange: boolean;
    hasYoYDifference: boolean;
    hasLyActuals: boolean;
}

export interface TimeseriesDisplayParams {
    aggregationTypes: string[];
    showOnTable: boolean;
    showOnExport: boolean;
}

export interface TimeseriesColDef extends ColDef {
    displayParams: TimeseriesDisplayParams;
}

@Injectable()
export class GridHelperService {
    public currentMap: Observable<{}>;
    public currentGridData: Observable<any[]>;

    public mapRollingMeasureMetrics: { [key: string]: string } = {
        'Daily': 'Day',
        'Weekly': 'Week',
        'Monthly': 'Month',
        'Quarterly': 'Quarter',
        'Yearly': 'Year'
    };

    private quadrantMap = new BehaviorSubject({});

    private gridData = new BehaviorSubject([]);

    public constructor() {
        this.currentMap = this.quadrantMap.asObservable();
        this.currentGridData = this.gridData.asObservable();
    }

    public updateMap(map: {}) {
        this.quadrantMap.next(map);
    }

    public formatRecords(data: any) {
        return (data) ? data.toLocaleString('en-US') : 0;
    }

    public currencyFormatter(data: any) {
        return '$' + this.formatRecords(data);
    }

    public percentFormatter(data: any) {
        return this.formatRecords(data) + '%';
    }

    public updateASINChangeLog(data: any) {
        this.gridData.next(data);
    }

    public export(gridOptions: GridOptions, fileName: string) {
        gridOptions.api?.exportDataAsCsv({ fileName, skipColumnGroupHeaders: true });
    }

    public exportWithHeaders(gridOptions: GridOptions, fileName: string) {
        gridOptions.api?.exportDataAsCsv({ fileName, skipColumnGroupHeaders: false });
    }

    public copyCellToClipboard(data: any) {
        navigator.clipboard.writeText(data);
    }

    public getMetricDefinitionByInternalName(metricInternalName: string, metricDefinitions: any[]) {
        const metric = metricDefinitions.find((m: any) => m.internalName === metricInternalName);

        if (metric) {
            return metric;
        }

        throw new Error(`Metric ${metricInternalName} not found in METRICS_MAP`);
    };

    public getMetricDefinitionByDisplayName(metricDisplayName: string, metricDefinitions: any[]) {
        const metric = metricDefinitions.find((m: any) => m.displayName === metricDisplayName);

        if (metric) {
            return metric;
        }

        throw new Error(`Metric ${metricDisplayName} not found in METRICS_MAP`);
    }

    public getMetricValue(date: string, metric: string, params: any) {
        const dateValue = params.data[date];

        if (dateValue && dateValue[metric]) {
            return dateValue[metric];
        }

        return null;
    };

    public hasRollingMetrics(lookbackWindow: number, selectedMetrics: Array<string>, aggregationType: string) {
        return lookbackWindow > 1 &&
            selectedMetrics.length === 1 &&
            !['Date', 'Metric'].includes(aggregationType);
    }

    public getMultipleMetricHeaders(data: TimeseriesGridData) {
        const popKeyName = getPoPKeyName(data.reportLevel);
        const popChangeName = 'PoP % Change'.replace('PoP', popKeyName);
        const popDifferenceName = 'PoP Difference'.replace('PoP', popKeyName);

        return data.dates.map((date: any) => {
            const children: any[] = [];

            for (const metric of data.metrics) {
                const metricDefinition = this.getMetricDefinitionByInternalName(metric, data.metricsDefinitions || []);
                const colId = `${date}_${metricDefinition.internalName}`;

                if (data.hasActuals) {
                    children.push({
                        colId,
                        headerName: metricDefinition.displayName,
                        metric: metric,
                        filter: 'agNumberColumnFilter',
                        hide: false,
                        comparator: (v1: any, v2: any, nodeA: any, nodeB: any) => {
                            const value1 = this.getMetricValue(date, metricDefinition.internalName, nodeA);
                            const value2 = this.getMetricValue(date, metricDefinition.internalName, nodeB);

                            return value1 - value2;
                        },
                        valueGetter: (params: any) => this.getMetricValue(date, metricDefinition.internalName, params),
                        valueFormatter: (params: any) => {
                            const value = this.getMetricValue(date, metricDefinition.internalName, params);

                            return this.getCell('actuals', data.currency, metricDefinition, value);
                        },
                        ...makeMetricTooltipConfig('header')
                    });
                }

                if (data.hasPoPChange) {
                    children.push({
                        colId: `${colId}_pop`,
                        headerName: `${metricDefinition.displayName} ${popChangeName}`,
                        filter: 'agNumberColumnFilter',
                        hide: false,
                        width: 180,
                        valueGetter: (params: any) => this.getMetricValue(date, `${metricDefinition.internalName}_pop`, params),
                        valueFormatter: (params: any) => {
                            const value = this.getMetricValue(date, `${metricDefinition.internalName}_pop`, params);

                            return this.getCell('pop_change', data.currency, metricDefinition, value);
                        }
                    });
                }

                if (data.hasYoYChange) {
                    children.push({
                        colId: `${colId}_yoy`,
                        headerName: `${metricDefinition.displayName} YoY % Change`,
                        filter: 'agNumberColumnFilter',
                        hide: false,
                        valueGetter: (params: any) => this.getMetricValue(date, `${metricDefinition.internalName}_yoy`, params),
                        valueFormatter: (params: any) => {
                            const value = this.getMetricValue(date, `${metricDefinition.internalName}_yoy`, params);

                            return this.getCell('yoy_change', data.currency, metricDefinition, value);
                        }
                    });
                }

                if (data.hasPoPDifference) {
                    children.push({
                        colId: `${colId}_pop_diff`,
                        headerName: `${metricDefinition.displayName} ${popDifferenceName}`,
                        filter: 'agNumberColumnFilter',
                        hide: false,
                        width: 180,
                        valueGetter: (params: any) => this.getMetricValue(date, `${metricDefinition.internalName}_pop_diff`, params),
                        valueFormatter: (params: any) => {
                            const value = this.getMetricValue(date, `${metricDefinition.internalName}_pop_diff`, params);

                            return this.getCell('pop_diff', data.currency, metricDefinition, value);
                        }
                    });
                }

                if (data.hasYoYDifference) {
                    children.push({
                        colId: `${colId}_yoy_diff`,
                        headerName: `${metricDefinition.displayName} YoY Difference`,
                        filter: 'agNumberColumnFilter',
                        hide: false,
                        valueGetter: (params: any) => this.getMetricValue(date, `${metricDefinition.internalName}_yoy_diff`, params),
                        valueFormatter: (params: any) => {
                            const value = this.getMetricValue(date, `${metricDefinition.internalName}_yoy_diff`, params);

                            return this.getCell('yoy_diff', data.currency, metricDefinition, value);
                        }
                    });
                }

                if (data.hasLyActuals) {
                    children.push({
                        colId: `${colId}_ly_actuals`,
                        headerName: `${metricDefinition.displayName} LY Actuals`,
                        filter: 'agNumberColumnFilter',
                        hide: false,
                        valueGetter: (params: any) => this.getMetricValue(date, `${metricDefinition.internalName}_ly_actuals`, params),
                        valueFormatter: (params: any) => {
                            const value = this.getMetricValue(date, `${metricDefinition.internalName}_ly_actuals`, params);

                            return this.getCell('ly_actuals', data.currency, metricDefinition, value);
                        }
                    });
                }
            }

            return {
                headerName: date,
                groupId: `date_${date}`,
                children
            };
        });
    }

    public getAggregationByMetricHeaders(data: TimeseriesGridData): ColDef<any>[] {
        const columns: any = [];

        for (const date of data.dates) {
            columns.push({
                headerName: date,
                field: date,
                filter: 'agNumberColumnFilter',
                hide: false,
                valueFormatter: (params: any) => {
                    const metric = this.getMetricDefinitionByInternalName(params.data['metricName'], data.metricsDefinitions || []);
                    const value = params.value;

                    return this.getCell(params.data['type'], data.currency, metric, value);
                }
            });
        }

        return columns;
    }

    public getRollingColumnsName(lookbackWindow: string, reportLevel: string, metric = 'Avg'): string {
        const validReportLevel = reportLevel in this.mapRollingMeasureMetrics;

        if (!validReportLevel) {
            return `Rolling ${lookbackWindow} ${metric}`;
        }

        return `Rolling ${lookbackWindow} ${this.mapRollingMeasureMetrics[reportLevel]} ${metric}`;
    }

    public getChangeColumnName(reportLevel: string) {
        if (reportLevel === 'Daily') {
            return 'DoD % Change';
        }

        if (reportLevel === 'Weekly') {
            return 'WoW % Change';
        }

        if (reportLevel === 'Monthly') {
            return 'MoM % Change';
        }

        if (reportLevel === 'Yearly') {
            return 'YoY % Change';
        }

        return 'QoQ % Change';
    }

    public getCell(type: any, currency: SupportedCurrencies, metric: any, value: any) {
        if (value === undefined) {
            return '-';
        }

        if (['yoy_change', 'pop_change'].includes(type)) {
            return this.formatMetricValue(value, currency, 'percentage', 2);
        }

        return this.formatMetricValue(value, currency, metric.type, metric.decimals);
    }

    private formatMetricValue(value: any, currency: SupportedCurrencies, format: string, decimals: number) {
        let number = parseFloat(value);

        if (isNaN(number)) {
            return '-';
        }

        if (!isFinite(number)) {
            number = 0;
        }

        switch (format) {
            case 'currency':
                return formatCurrency(number, currency, { maximumFractionDigits: decimals });
            case 'percentage':
                return formatAsPercent(number);
            case 'number':
                return formatToLocaleString(round(number, decimals));
        }

        return '-';
    }
}
