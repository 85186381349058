import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-taos-out-of-date',
    templateUrl: './taos-out-of-date.component.html',
    styleUrls: ['./taos-out-of-date.component.css']
})
export class TaosOutOfDateComponent implements OnInit {
    public constructor() { }

    public ngOnInit(): void {
    }

    public refreshApp() {
        setTimeout(()=>{
            window.location.reload();
        }, 100);
    }
}
