interface FilterWithTax {
    marketplaces: string[];
    includeTax?: string;
}

export function transformTaxFilter<T>(filter: T & FilterWithTax): typeof filter {
    if (filter.includeTax && filter.marketplaces.includes('US')) {
        return {
            ...filter,
            includeTax: 'Yes'
        };
    }

    return filter;
}
