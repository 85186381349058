import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

export interface ManageReplenFilters {
    accounts: string[];
    marketplaces: string[];
}

export interface ManageReplenAction {
    id: string;
}

export interface ExportManageReplenHistory {
    id: string;
    range: string;
}

@Injectable()
export class ManageReplenService {
    public v2Api = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async get(filters: ManageReplenFilters): Promise<Observable<any>> {
        try {
            const { accounts, marketplaces } = filters;

            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.v2Api + 'manage/replen', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    'marketplaces[]': marketplaces,
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async refreshReport(dto: ManageReplenAction): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.post(this.v2Api + 'manage/replen/refresh', dto, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async submitReport(dto: ManageReplenAction): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.post(this.v2Api + 'manage/replen/submit', dto, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async export(dto: ExportManageReplenHistory): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get(this.v2Api + 'manage/replen/export-history', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    id: dto.id,
                    exportRange: dto.range,
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }
}
