import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { getDataDictionary } from 'src/app/utils/get-data-dictionary.util';

interface TermDefinition {
  acronym: string;
  definition: string;
  logic: string;
};

@Component({
    standalone: true,
    imports: [NgIf],
    templateUrl: './custom-header-metric-tooltip.component.html',
    styleUrls: ['./custom-header-metric-tooltip.component.css']
})
export class CustomHeaderMetricTooltipComponent implements ITooltipAngularComp {
    public params!: ITooltipParams;

    public acronym: string | null = null;

    public definition: string | null = null;

    public logic: string | null = null;

    public get dictionary(): any {
        return getDataDictionary();
    }

    public agInit(params: ITooltipParams): void {
        this.params = params;

        const cellValue = this.params.value;
        const headerValue = this.params.colDef?.headerName;

        const isValidHeader = this.params.location === 'header' && this.params.colDef && headerValue && headerValue in this.dictionary;
        const isValidCell = this.params.location === 'cell' && this.params.colDef && cellValue && cellValue in this.dictionary;

        if (isValidHeader) {
            const termDefinition: TermDefinition = headerValue ? this.dictionary[headerValue] : {};

            this.acronym = termDefinition.acronym;
            this.definition = termDefinition.definition;
            this.logic = termDefinition.logic;
        } else if (isValidCell) {
            const termDefinition: TermDefinition = cellValue ? this.dictionary[cellValue] : {};

            this.acronym = termDefinition.acronym;
            this.definition = termDefinition.definition;
            this.logic = termDefinition.logic;
        } else {
            this.acronym = (params.colDef as ColDef).field || headerValue || '';
        }

        if (!this.acronym && !this.definition && !this.logic) {
            this.acronym = headerValue || cellValue;
        }
    }
}
