import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

export interface GetGlobaldocDefinitionsFilter {
    account: string;
}

export interface GetGlobaldocProductsFilter {
    account: string;
    marketplaces: string[];
    fields: string[];
}

export interface UpdateGlobaldocFieldDTO {
    account?: string;
    marketplace: string;
    productId: string;
    channelSku: string;
    field: string;
    value: string;
}

export interface DeleteProductDTO {
    account?: string;
    marketplace: string;
    productId: string;
    channelSku: string;
}

@Injectable()
export class ManageGlobaldocService {
    public v2Api = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async getDefintions(filters: GetGlobaldocDefinitionsFilter): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.v2Api + 'manage/globaldoc/field-definitions', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'account': filters.account,
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getProducts(filters: GetGlobaldocProductsFilter): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.v2Api + 'manage/globaldoc/products', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'account': filters.account,
                    'fields[]': filters.fields,
                    'marketplaces[]': filters.marketplaces
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async exportProducts(filters: GetGlobaldocProductsFilter): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get(this.v2Api + 'manage/globaldoc/products/export', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'account': filters.account,
                    'fields[]': filters.fields,
                    'marketplaces[]': filters.marketplaces
                },
                responseType: 'blob'
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async updateDefinitions(account: string, definitions: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.post<[]>(this.v2Api + 'manage/globaldoc/field-definitions', {
                account,
                jsonSettings: definitions
            }, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async updateField(params: UpdateGlobaldocFieldDTO): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.post<[]>(this.v2Api + 'manage/globaldoc/products/update-field', params, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async deleteProduct(params: DeleteProductDTO): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.post<[]>(this.v2Api + 'manage/globaldoc/products/delete', params, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async importGlobalDoc(formData: any) {
        try {
            const user = this.userService.getUser()!;

            return this.http.post(this.v2Api + 'manage/globaldoc/products/import', formData, {
                reportProgress: true,
                observe: 'events',
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getImportRequests(account: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get(this.v2Api + 'manage/globaldoc/products/import-requests', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    account
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async downloadImportRequestLogs(account: string, requestId: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            return this.http.get(this.v2Api + 'manage/globaldoc/products/import-request-result', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    account,
                    requestId
                },
                responseType: 'blob'
            });
        } catch (error) {
            return throwError(() => error);
        }
    }
}
