export function calculatePoPChange(currentWeekValue: any, lastWeekValue: any) {
    if (!currentWeekValue && !lastWeekValue) {
        return 0;
    }

    if (lastWeekValue <= 0 && currentWeekValue > 0) {
        return 100;
    }

    return ((currentWeekValue - lastWeekValue) / lastWeekValue) * 100;
}

export function parseAndCalculatePoPChange(reportLevel: string, metric: string, values: any[]) {
    let [previous, current] = values.slice(-3);
    let pop = current && previous ? calculatePoPChange(current[metric], previous[metric]) : 0;

    return { previous, current, pop };
}

export function getPoPKeyName(reportLevel: string) {
    if (reportLevel === 'Daily') {
        return 'DoD';
    }

    if (reportLevel === 'Weekly') {
        return 'WoW';
    }

    if (reportLevel === 'Monthly') {
        return 'MoM';
    }

    if (reportLevel === 'Quarterly') {
        return 'QoQ';
    }

    if (reportLevel === 'Yearly') {
        return 'YoY';
    }

    return 'PoP';
}
