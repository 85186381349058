import { Component } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { BoardService } from 'src/app/services/board.service';
import { Auth } from 'aws-amplify';
import { UserService } from 'src/app/services/user.service';
import { Menu, UserGuideItem } from 'src/app/types/menu';
import { MenuStateService } from 'src/app/services/menu-state.service';
import { UserGuideSectionEnum } from 'src/app/pages/documents/user-guide/user-guide-constants';
import { NavigationEnd, Router } from '@angular/router';
import { EventTrackService } from 'src/app/services/event-track.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { IntercomService } from 'src/app/services/intercom.service';
import { LAST_SELECTED_ACCOUNT_KEY } from '../filter/filter.component';

const profitabilityUsers = [
    'phil.college@chromeindustries.com',
    'm.hancuff@chromeindustries.com',
    'clarissa.klimek@chromeindustries.com',
    'meghan.litchfield@chromeindustries.com',
    'heather.green@chromeindustries.com',
    'michelle.p@yoobi.com',
    'pet+yoobi@goamify.com',
    'Xavier.Toledo@econugenics.com'
];

@Component({
    selector: 'app-menu-bar',
    templateUrl: './menu-bar.component.html',
    styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent {
    @Output() public toggleMenu = new EventEmitter<boolean>();

    public icon = "amify-white";
    public menu_retracted = false;
    public showMenu = true;

    public menus: Array<Menu> = [
        {
            name: "Scorecard",
            icon: "scorecard",
            internal: false,
            submenuRoute: ['scorecard'],
            amzAccountType: "*",
            userGuide: {
                path: '/documents/scorecard-guide',
                section: UserGuideSectionEnum.Scorecard
            },
            class: "",
            submenu: [
                {
                    name: "Overview",
                    icon: "",
                    class: "",
                    routerLink: ['/scorecard/overview']
                },
                {
                    name: "Advertising",
                    icon: "",
                    class: "",
                    routerLink: ['/scorecard/advertising'],
                },
                {
                    name: "Timeseries",
                    icon: "",
                    class: "",
                    routerLink: ['/scorecard/timeseries']
                },
                {
                    name: "Inventory",
                    icon: "",
                    class: "",
                    routerLink: ['/scorecard/inventory']
                },
                {
                    name: "Pacing",
                    icon: "",
                    class: "",
                    routerLink: ['/scorecard/pacing']
                }
            ],
        },
        {
            name: "Reports",
            icon: "report",
            internal: true,
            submenuRoute: ['generated-reports'],
            routerLink: ['/generated-reports'],
            amzAccountType: "*",
            class: "",
        },
        {
            name: "Profitability",
            icon: "forecast",
            amzAccountType: "asc",
            class: "",
            submenuRoute: ['/profitability'],
            internal: true,
            disableDemo: true,
            allowSpecificExternalUsers: profitabilityUsers,
            userGuide: {
                path: '/documents/profitability-guide',
                section: UserGuideSectionEnum.Profitability,
            },
            submenu: [
                // {
                //     name: "Overview",
                //     icon: "",
                //     class: "",
                //     routerLink: ['/profitability/overview']
                // },
                // {
                //     name: "Product",
                //     icon: "",
                //     class: "",
                //     routerLink: ['/profitability/product']
                // },
                {
                    name: "Products",
                    icon: "",
                    class: "",
                    routerLink: ['/profitability/products']
                },
                // {
                //     name: "Finance",
                //     icon: "",
                //     class: "",
                //     internal: true,
                //     routerLink: ['/profitability/finance']
                // },
                // {
                //     name: "Finance Products",
                //     icon: "",
                //     class: "",
                //     internal: true,
                //     routerLink: ['/profitability/finance-products']
                // },
                {
                    name: "Orders",
                    icon: "",
                    class: "",
                    routerLink: ['/profitability/orders']
                },
            ],
        },
        {
            name: 'Forecast',
            icon: 'compete',
            amzAccountType: 'asc',
            class: '',
            submenuRoute: ['/forecast'],
            internal: true,
            disableDemo: true,
            submenu: [
                {
                    name: 'Historical',
                    icon: '',
                    class: '',
                    routerLink: ['/forecast/historical']
                },
                {
                    name: 'Request',
                    icon: '',
                    class: '',
                    routerLink: ['/forecast/request']
                },
                {
                    name: 'Result',
                    icon: '',
                    class: '',
                    routerLink: ['/forecast/result']
                }
            ]

        },
        {
            name: "Measure",
            icon: "measure",
            amzAccountType: "asc",
            submenuRoute: ['measures'],
            class: "",
            internal: false,
            submenu: [
                {
                    name: "Overview",
                    icon: "",
                    class: "",
                    routerLink: ['/measures/overview']
                }
            ],
            userGuide: {
                path: '/documents/user-guide',
                section: UserGuideSectionEnum.Measure
            }
        },
        {
            name: "Optimize",
            icon: "optimize",
            amzAccountType: "asc",
            class: "",
            submenuRoute: ['/optimize'],
            internal: false,
            submenu: [
                {
                    name: "Products",
                    icon: "",
                    class: "",
                    routerLink: ['/optimize/products']
                },
                {
                    name: "Campaigns",
                    icon: "",
                    class: "",
                    routerLink: ['/optimize/campaigns']
                },
                {
                    name: "Keywords",
                    icon: "",
                    class: "",
                    routerLink: ['/optimize/keywords']
                },
            ],
            submenuVisible: false,
            userGuide: {
                path: '/documents/user-guide',
                section: UserGuideSectionEnum.Optimize
            }
        },
        {
            name: "Investigate",
            icon: "investigate",
            amzAccountType: "asc",
            class: "",
            submenuRoute: ['/investigate'],
            internal: false,
            submenu: [
                {
                    name: "Products",
                    icon: "",
                    class: "",
                    routerLink: ['/investigate/products']
                },
                {
                    name: "Campaigns",
                    icon: "",
                    class: "",
                    routerLink: ['/investigate/campaigns']
                },
                {
                    name: "Keywords",
                    icon: "",
                    class: "",
                    routerLink: ['/investigate/keywords']
                },
            ],
            submenuVisible: false,
            userGuide: {
                path: '/documents/user-guide',
                section: UserGuideSectionEnum.Investigate
            }
        },
        {
            name: "Data Integrity",
            icon: "data-integrity",
            class: "",
            submenuRoute: ['/data-integrity'],
            internal: true,
            hideDemo: true,
            submenu: [
                {
                    name: "Validation Request",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/validation-request']
                },
                {
                    name: "Bulk Validation Request",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/bulk-validation-request']
                },
                {
                    name: "Validation Result",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/validation-result']
                },
                {
                    name: "Bulk Validation Result",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/bulk-validation-result']
                },
                {
                    name: "Validation All",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/validation-all']
                },
                {
                    name: "Daily Refresh",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/daily-refresh']
                },
                {
                    name: "Weekly Report",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/weekly-report']
                },
                {
                    name: "Monthly Report",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/monthly-report']
                },
                // {
                //     name: "Quarterly Report",
                //     icon: "",
                //     class: "",
                //     routerLink: ['/data-integrity/quarterly-report']
                // },
                {
                    name: "Global Doc Check",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/global-docs-issues']
                },
                {
                    name: "Missing Listings",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/global-docs-missing']
                },
                {
                    name: "Metric Consistency",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/metrics-consistency']
                },
                {
                    name: "Metric Health",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/metric-health']
                },
                {
                    name: "Data Feed",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/data-feed']
                },
                {
                    name: "Pipeline Healthcheck",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/pipeline-healthcheck']
                },
                {
                    name: "Report Request",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/report-refresh-request']
                },
                {
                    name: "Amazon Downloader",
                    icon: "",
                    class: "",
                    routerLink: ['/data-integrity/amazon-downloader']
                }
            ],
            submenuVisible: false,
            userGuide: {
                path: '/documents/data-validation-guide',
                section: UserGuideSectionEnum.DataValidation
            }
        },
        {
            name: "Cross-Clients",
            icon: "cross-clients",
            class: "",
            submenuRoute: ['/cross-clients'],
            internal: true,
            disableDemo: true,
            userGuide: {
                path: '/documents/cross-clients-guide',
                section: UserGuideSectionEnum.CrossClients,
            },
            submenu: [
                {
                    name: "Sales",
                    icon: "",
                    class: "",
                    routerLink: ['/cross-clients/sales']
                }
            ]
        },
        {
            name: "Manage",
            icon: "manage",
            class: "",
            submenuRoute: ['/manage'],
            internal: true,
            submenu: [
                // {
                //     name: "Globaldoc",
                //     icon: "",
                //     class: "",
                //     routerLink: ['/manage/globaldoc'],
                // },
                {
                    name: "Operations",
                    icon: "",
                    class: "",
                    routerLink: ['/manage/operations'],
                    userGuide: {
                        path: '/documents/listing-monitoring',
                        section: ''
                    }
                },
                {
                    name: "Packages",
                    icon: "",
                    class: "",
                    routerLink: ['/manage/packages']
                },
                {
                    name: "Scraping Schedule",
                    icon: "",
                    class: "",
                    hideDemo: true,
                    routerLink: ['/manage/scraping-schedule']
                },
                {
                    name: "Replen",
                    icon: "",
                    class: "",
                    hideDemo: true,
                    routerLink: ['/manage/replen']
                }
            ],
            submenuVisible: false
        },
        {
            name: "Onboard",
            icon: "onboard",
            amzAccountType: "asc",
            class: "",
            routerLink: ['/onboard'],
            internal: true,
            externalProspect: true
        },
        {
            name: "User Analytics",
            icon: "analyze",
            amzAccountType: "asc",
            class: "",
            routerLink: ['/user-analytics'],
            internal: true,
            submenu: [
                {
                    name: "Overview",
                    icon: "",
                    class: "",
                    internal: true,
                    routerLink: ['/user-analytics/overview']
                },
                {
                    name: "Activities",
                    icon: "",
                    class: "",
                    internal: true,
                    routerLink: ['/user-analytics/activities']
                },
            ]
        },
        {
            name: "Sigma Report",
            icon: "report",
            amzAccountType: "*",
            class: "",
            routerLink: ['/sigma-report'],
            internal: true,
        },
        {
            name: "Settings",
            icon: "settings",
            class: "",
            amzAccountType: "*",
            externalProspect: true,
            submenuRoute: ['/settings'],
            submenu: [
                {
                    name: "Notifications",
                    icon: "",
                    class: "",
                    internal: true,
                    routerLink: ['/settings/notifications']
                },
                {
                    name: "Scorecard",
                    icon: "",
                    class: "",
                    internal: true,
                    hideDemo: true,
                    routerLink: ['/settings/scorecard']
                },
                {
                    name: "Change Password",
                    icon: "",
                    class: "",
                    routerLink: ['/settings/change-password'],
                    externalProspect: true,
                },
                {
                    name: "Profile Info",
                    icon: "",
                    class: "",
                    routerLink: ['/settings/profile-info']
                },
                {
                    name: "Create Users",
                    icon: "",
                    class: "",
                    internal: true,
                    hideDemo: true,
                    routerLink: ['/settings/create-users']
                },
                {
                    name: "Admin Panel",
                    icon: "",
                    class: "",
                    internal: true,
                    hideDemo: true,
                    routerLink: ['/settings/admin-panel']
                },
                {
                    name: "Accounts",
                    icon: "",
                    class: "",
                    internal: true,
                    hideDemo: true,
                    routerLink: ['/settings/accounts']
                },
                {
                    name: "Submit Issue",
                    icon: "",
                    class: "",
                    internal: true,
                    hideDemo: true,
                    clickEvent: () => {
                        (<any>window)?.showCollectorDialog();
                    },
                },
            ]
        },
        {
            name: "Documents",
            icon: "user-guide",
            class: "",
            submenuRoute: ['/documents'],
            submenu: [
                {
                    name: "User Guide",
                    icon: "",
                    class: "",
                    routerLink: ['/documents/user-guide'],
                },
                {
                    name: "Release Notes",
                    icon: "",
                    class: "",
                    routerLink: ['/documents/release-notes'],
                },
                {
                    name: "Report Caveats",
                    icon: "",
                    class: "",
                    routerLink: ['/documents/report-caveats'],
                },
                {
                    name: "Listing Monitoring",
                    icon: "",
                    class: "",
                    internal: true,
                    routerLink: ['/documents/listing-monitoring'],
                },
                {
                    name: "Settlement Report Guide",
                    icon: "",
                    class: "",
                    internal: true,
                    routerLink: ['/documents/settlement-report-guide'],
                },
                {
                    name: "Data Validation Guide",
                    icon: "",
                    class: "",
                    routerLink: ['/documents/data-validation-guide'],
                },
            ]
        },
        {
            name: "Log Out",
            icon: "logout",
            class: "",
            amzAccountType: "*",
            externalProspect: true
        },
    ];

    public menus$ = this.getAvailableMenuList(this.menus);

    public constructor(public boardService: BoardService,
        private menuStateStore: MenuStateService,
        private userService: UserService,
        private eventTrackService: EventTrackService,
        private errorHandlerService: ErrorHandlerService,
        private router: Router,
        private intercomService: IntercomService
    ) {
        router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.updateSubmenuVisibility();
            }
        });
    }

    public updateSubmenuVisibility() {
        this.menus$.then(menus => {
            menus.forEach(menu => {
                menu.submenuVisible = this.assessMenuVisibility(menu);

                if (menu.submenu) {
                    menu.submenu?.forEach(submenu => {
                        submenu.submenuVisible = this.assessMenuVisibility(submenu);

                        if (submenu.submenuVisible) {
                            menu.submenuVisible = true;
                        }
                    });
                }
            });
        });
    }

    public assessMenuVisibility(menu: Menu) {
        const [currentRoute] = this.router.url.split('?');

        return menu.submenu?.some(m => m.routerLink?.join() == currentRoute);
    }

    public async getAvailableMenuList(menus: Array<Menu>) {
        const group = await this.userService.getGroup();
        const isDemoUser = await this.userService.isDemoUser();

        const allowedAccounts = await this.userService.getAllowedAccount();
        const allowedAccountTypes = await this.userService.getAllowedAccountTypes();

        if (isDemoUser) {
            return menus
                .filter(menu => !menu.hideDemo)
                .map(menu => {
                    if (menu.submenu) {
                        menu.submenu = menu.submenu.filter(submenu => !submenu.hideDemo);
                    }

                    if (menu.disableDemo) {
                        menu.class = 'inactive';
                        menu.tooltip = 'Module undergoing upgrades';
                    }

                    return menu;
                });
        }

        if (group == 'external') {
            const { email: userEmail } = await this.userService.getData();

            const filteredMenuList = menus
                .filter(menu => (!menu.internal && menu.class !== 'inactive') || menu.allowSpecificExternalUsers?.includes(userEmail))
                .filter(menu => menu.amzAccountType === '*' || menu.amzAccountType === allowedAccounts || allowedAccountTypes.includes(menu.amzAccountType || ''));

            return filteredMenuList
                .map(menu => {
                    if (menu.submenu) {
                        menu.submenu = menu.submenu.filter(submenu => !submenu.internal);
                    }

                    return menu;
                });
        }

        if (group == 'external_prospect') {
            const filteredMenuList = menus.filter(menu => menu.externalProspect);

            return filteredMenuList
                .map(menu => {
                    if (menu.submenu) {
                        menu.submenu = menu.submenu.filter(submenu => submenu.externalProspect);
                    }

                    return menu;
                });
        }

        return menus.filter(menu => !menu.hide).map(menu => {
            if (menu.submenu && menu.submenu.length > 0) {
                menu.submenu = menu.submenu.filter(submenu => !submenu.hide);
            }

            return menu;
        });
    }

    public goToUserGuide(userGuide: UserGuideItem) {
        const { path, section } = userGuide;

        this.eventTrackService.registerUserGuideView(section);

        this.router.navigate([path], { queryParams: { section } });
    }

    public toggleMenuSize() {
        const wrapperElements = document.getElementsByClassName('wrapper');

        for (let i = 0; i < wrapperElements.length; i++) {
            wrapperElements[i].classList.toggle('hide-menu');
        }

        this.menu_retracted = !this.menu_retracted;
        this.updateMenuState();
    }

    public updateMenuState() {
        this.showMenu = (this.showMenu) ? false : true;
        this.menuStateStore.updateState(this.showMenu);
        this.toggleMenu.emit(this.showMenu);
    }

    public toggleSubmenuVisibility(menu: Menu) {
        menu.submenuVisible = !menu.submenuVisible;
    }

    public async logout() {
        try {
            localStorage.removeItem(LAST_SELECTED_ACCOUNT_KEY);
            await Auth.signOut();
            this.intercomService.shutdown();
            location.href = 'login';
        } catch (error) {
            this.errorHandlerService.handle(error);
        }
    }
}
