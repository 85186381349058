<p-dialog header="Application version out of date" [modal]="true" [visible]="true">
  <p class="p-m-0">
      A new version of the application is available.
  </p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check"
          (click)="refreshApp()" label="Refresh"
          class="p-button-text">
      </p-button>
  </ng-template>
</p-dialog>
