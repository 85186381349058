import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-profitability-product-column-renderer',
    template: `<div><b>{{productName}}</b></div><div>{{asin}} | {{category}} - {{subcategory}}</div>`,
    encapsulation: ViewEncapsulation.None
})
export class ProfitabilityProductColumnRendererComponent implements ICellRendererAngularComp {
    public productName!: string;
    public asin!: string;
    public category!: string;
    public subcategory!: string;

    public constructor() { }

    public agInit(params: ICellRendererParams): void {
        this.updateComponent(params.data);
    }

    public updateComponent(data: any) {
        this.asin = data['ASIN'];
        this.productName = data['Product Name'];
        this.category = data['Category'];
        this.subcategory = data['Subcategory'];
    }

    public refresh(params: ICellRendererParams): boolean {
        this.updateComponent(params.data);

        return true;
    }
}
