import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuBarModule } from '../menu-bar/menu-bar.module';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { LayoutWrapperComponent } from './components/layout-wrapper.component';

@NgModule({
    declarations: [
        LayoutComponent,
        LayoutWrapperComponent
    ],
    exports: [
        LayoutComponent,
    ],
    imports: [
        CommonModule,
        MenuBarModule,
        RouterModule
    ]
})
export class LayoutModule { }
