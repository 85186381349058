import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable()
export class SigmaReportService {
    public baseUrl = environment.apiV2Url;

    public constructor(
        private readonly http: HttpClient,
        private readonly userService: UserService,
    ) { }

    public async generateEmbedURL(embedPathId: string, uri: any, embedPathNodeId: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const url = new URL(this.baseUrl + 'sigma-reports/generate-embed-url');

            return this.http.get<string>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    userId: user.email,
                    embedPathId: embedPathId,
                    embedVariables: uri,
                    embedPathNodeId: embedPathNodeId
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }
}
