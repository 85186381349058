<div id="filter-bar" class="wrapper" style="display: none;">
    <div class="filter-menu">
        <div class="filter-container">

        </div>

        <div style="height: 70px;">
            <div class="fixed-button-background">
                <div class="button fixed-button">
                    <!-- <button (click)="applyFilter()" id="button" [ngClass]="{ 'inactive': !isFilterValid, 'active': isFilterValid }" class="filter-button">Apply Filter</button> -->
                </div>
            </div>
        </div>
    </div>

    <div class="hamburger">
        <div class="inner_hamburger" (click)="toggle()">
            <span class="arrow">
                <mat-icon class="menu" svgIcon="menu"></mat-icon>
            </span>
        </div>
    </div>
</div>

<div class="taos-md-card" style="position: relative;">
    <ng-content></ng-content>

    <div class="grid w-full justify-content-end">
        <div>
            <p-button class="filter-button" title="Apply Filter" (click)="applyFilter()" [disabled]="!isFilterValid">Apply Filter</p-button>
        </div>
    </div>
</div>
