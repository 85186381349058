export enum UserGuideSectionEnum {
  Measure = 'measure',
  Optimize = 'optimize',
  Investigate = 'investigate',
  VendorCentral = 'vendorCentral',
  VendorCentralAdvertising = 'vendorCentralAdvertising',
  VendorCentralSales = 'vendorCentralSales',
  VendorCentralInventory = 'vendorCentralInventory',
  Profitability = 'profitability',
  Scorecard = 'scorecard',
  DataValidation = 'dataValidation',
  CrossClients = 'crossClients',
}

export const sectionLinksMap: { [key: string]: string } = {
    [UserGuideSectionEnum.Measure]: 'h.1wjkcsckuclu',
    [UserGuideSectionEnum.Optimize]: 'h.4zl8pjcq92nc',
    [UserGuideSectionEnum.Investigate]: 'h.4q9bd6j55onl',
    [UserGuideSectionEnum.VendorCentral]: 'h.919jo9edc05x',
    [UserGuideSectionEnum.VendorCentralAdvertising]: 'h.3bzmzgdfpn6m',
    [UserGuideSectionEnum.VendorCentralInventory]: 'h.jsrwfo8982p1',
    [UserGuideSectionEnum.VendorCentralSales]: 'h.haacgjb6ieay'
};
