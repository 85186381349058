import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-profitability-category-column-renderer',
    template: `<div>{{category}} - {{subcategory}}</div>`,
    encapsulation: ViewEncapsulation.None
})
export class ProfitabilityCategoryColumnRendererComponent implements ICellRendererAngularComp {
    public category!: string;
    public subcategory!: string;

    public constructor() { }

    public agInit(params: ICellRendererParams): void {
        this.updateComponent(params.data);
    }

    public updateComponent(data: any) {
        this.category = data['Category'];
        this.subcategory = data['Subcategory'];
    }

    public refresh(params: ICellRendererParams): boolean {
        this.updateComponent(params.data);

        return true;
    }
}
