import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-unified-reporting-account-column-renderer',
    template: `<div>{{accountName}} | {{country}}</div>`,
    encapsulation: ViewEncapsulation.None
})
export class UnifiedReportingAccountColumnRendererComponent implements ICellRendererAngularComp {
    public accountName!: string;
    public country!: string;

    public constructor() { }

    public agInit(params: ICellRendererParams): void {
        this.updateComponent(params.data);
    }

    public updateComponent(data: any) {
        this.accountName = data['Account'];
        this.country = data['Country'];
    }

    public refresh(params: ICellRendererParams): boolean {
        this.updateComponent(params.data);

        return true;
    }
}
