import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class MenuStateService {
    public currentState: Observable<boolean>;
    private showMenu = new BehaviorSubject(true);

    public constructor() {
        this.currentState = this.showMenu.asObservable();
    }

    public updateState(state: boolean) {
        this.showMenu.next(state);
    }
}
