import { Injectable } from '@angular/core';
import { ChangeNotification } from '../pages/manage/components/webhook-change-detector/webhook-change-detector.component';

interface OperationIssue {
    [key: string]: any;
    issue_id: string;
    notification_key: string;
    detection_status?: 'NEW' | 'RESOLVED' | 'DID_NOT_CHANGE';
}

interface OperationIssueChanges {
    new: OperationIssue[];
    resolved: OperationIssue[];
    all: OperationIssue[];
}

interface OperationIssueFilter {
    accounts: string[];
    status: string[];
    from_date: Date;
    to_date: Date;
}

@Injectable()
export class OperationIssueChangeDetectorService {
    public needsRefresh(filter: OperationIssueFilter, changeNotification: ChangeNotification): boolean {
        const hasAccountChange = filter.accounts.includes(changeNotification.account) || filter.accounts.length === 0;
        const hasStatusChange = filter.status.includes(changeNotification.type) || filter.status.length === 0;
        const hasDateChange = filter.from_date.getTime() <= changeNotification.date.getTime() && filter.to_date.getTime() >= changeNotification.date.getTime();

        return hasAccountChange && hasStatusChange && hasDateChange;
    }

    public getChanges(oldIssues: OperationIssue[], newIssues: OperationIssue[]): OperationIssueChanges {
        const result: OperationIssueChanges = {
            new: [],
            resolved: [],
            all: [],
        };

        for (const newIssue of newIssues) {
            const oldIssue = oldIssues.find(i => i.issue_id === newIssue.issue_id);

            newIssue.detection_status = 'DID_NOT_CHANGE';

            if (!oldIssue) {
                newIssue.detection_status = 'NEW';
                result.new.push(newIssue);
            }

            result.all.push(newIssue);
        }

        // We check resolved issues by notifcation key, to avoid marking an issue as resolved if it was updated
        // (had a new occurrence with a different ID)
        for (const oldIssue of oldIssues) {
            const newIssue = newIssues.find(i => i.notification_key === oldIssue.notification_key);

            if (!newIssue) {
                oldIssue.detection_status = 'RESOLVED';
                result.resolved.push(oldIssue);
            }
        }

        return result;
    }
}
