import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

interface Event {
    notification_id?: string;
    attribute_changed?: string;
    ticket_url: string;
    issue_type: string;
    event_date?: string;
    asin?: string;
    notification_type?: string;
}

@Injectable()
export class IssueTicketService {
    public base_url = environment.apiUrl;

    public constructor(
      private http: HttpClient,
      private userService: UserService
    ) { }

    public async update_ticket_for_issue(account_name: string, event: Event): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            let url = new URL(this.base_url + 'notifications/update-ticket');

            url.searchParams.append('account', account_name);

            return this.http.post(url.toString(), { event }, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }});
        } catch (error) {
            return of({'status_code': 401, 'detail': error});
        }
    }
}
