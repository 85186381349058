import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MenuBarComponent } from 'src/app/components/menu-bar/menu-bar.component';
import { AccountsService } from 'src/app/services/accounts.service';

export enum GroupTypeEnum {
    Internal = 'internal',
    External = 'external',
    CartInternal = 'cart-internal',
    CartExternal = 'cart-external',
    ProspectExternal = 'prospect-external'
}

@Injectable()
export class UserAttributesService {
    public departments = [{ name: 'Advertising' },
        { name: 'Content' },
        { name: 'Client Success' },
        { name: 'Engineering' },
        { name: 'Executive' },
        { name: 'Marketing' },
        { name: 'Operations' },
        { name: 'Project Management' },
        { name: 'Reporting' },
        { name: 'Strategy' },
        { name: 'Test'}];

    public groups = [
        { name: 'Internal', value: GroupTypeEnum.Internal},
        { name: 'External', value: GroupTypeEnum.External},
        { name: 'Cart Internal', value: GroupTypeEnum.CartInternal},
        { name: 'Cart External', value: GroupTypeEnum.CartExternal},
        { name: 'Prospect External', value: GroupTypeEnum.ProspectExternal}];

    public accessTypes = [{name: 'Read Only', value: 'read_only'},
        {name: 'Read Write', value: 'read_write'}];

    public roles = [{name: 'Account Management', value: 'account_management'}];

    public constructor(
        private accountsService: AccountsService,
        private menuComponent: MenuBarComponent
    ) { }

    public async getAccounts(accountType: any) {
        const obs = await this.accountsService.getAccounts(accountType);

        return await lastValueFrom(obs);
    }

    public getModules() {
        return this.menuComponent.menus.filter(module => module.class != 'inactive' && !['Settings', 'Documents', 'Log Out'].includes(module.name))
            .map(module => ({ name: module.name }));
    }

    public getDefaultModule(moduleRoute: string) {
        return this.menuComponent.menus.filter(module => module.submenu)
            .find(module => module.submenu![0].routerLink![0] == moduleRoute)?.name;
    }

    public getDefaultModuleRoute(moduleName: string) {
        const menu = this.menuComponent.menus.find(menu => menu.name == moduleName)!;

        return menu.submenuRoute ? menu.submenu![0].routerLink![0]: menu.routerLink![0];
    }
}
