import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { take, takeWhile } from 'rxjs';

@Component({
    selector: 'app-login-callback',
    template: '<p>Loading...</p>',
})
export class CartLoginCallbackComponent {
    private handledCallback = false;

    public constructor(private auth: AuthService, private router: Router) { }

    public async ngOnInit() {
        this.auth.appState$.pipe(take(1)).subscribe(() => {
            this.handledCallback = true;
        });

        await this.auth.isLoading$.pipe(takeWhile(isLoading => isLoading)).toPromise();

        const handler = setTimeout(() => {
            this.router.navigate(['/redirect'], { replaceUrl: true });
        }, 5000);

        if (!this.handledCallback) {
            clearTimeout(handler);

            this.router.navigate(['/redirect'], { replaceUrl: true });
        }
    }
}
