import { Injectable } from '@angular/core';
import {
    sub,
    differenceInDays,
    startOfToday,
    startOfYesterday,
    startOfWeek,
    endOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
    lastDayOfMonth,
    startOfQuarter,
    subQuarters,
    lastDayOfQuarter,
    startOfYear,
    subYears,
    lastDayOfYear,
    add
} from 'date-fns';
import { CacheService } from './cache.service';
import * as moment from 'moment';

@Injectable()
export class DateHelperService {
    public today = new Date();
    public hide_date = new Date();
    public date_range_period_map: any = {};

    public validPeriods = ['today', 'yesterday', 'this week', 'last week', 'this month', 'last month',
        'this quarter', 'last quarter', 'this year', 'last year', 'trailing 7 days', 'trailing 14 days',
        'trailing 30 days', 'trailing 60 days', 'trailing 90 days', 'trailing 120 days', 'trailing 180 days'];

    public validWeekStarts = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    public constructor(private cacheService: CacheService) {
        this.define_date_periods(2);
        this.define_date_periods(3);
        this.define_date_periods(0);
    }

    public capitalize(str: string) {
        return str.replace(/\b\w/g, word => word.toUpperCase());
    };

    public availablePeriods({ offset, todayDate }: any) {
        let date = todayDate ? new Date(new Date(todayDate).toLocaleString('en-US', { timeZone: 'UTC' })) : Date.now(),
            today = startOfToday(),
            yesterday = startOfYesterday(),
            thisWeekStart = startOfWeek(date),
            thisMonthStart = startOfMonth(date),
            thisQuarterStart = startOfQuarter(date),
            thisYearStart = startOfYear(date);

        if (differenceInDays(date, today) <= offset) {
            this.validPeriods = this.validPeriods.filter(p => p != 'today');
        }

        if (differenceInDays(date, yesterday) <= offset) {
            this.validPeriods = this.validPeriods.filter(p => p != 'yesterday');
        }

        if (differenceInDays(date, thisWeekStart) <= offset) {
            this.validPeriods = this.validPeriods.filter(p => p != 'this week');
        }

        if (differenceInDays(date, thisMonthStart) <= offset) {
            this.validPeriods = this.validPeriods.filter(p => p != 'this month');
        }

        if (differenceInDays(date, thisQuarterStart) <= offset) {
            this.validPeriods = this.validPeriods.filter(p => p != 'this quarter');
        }

        if (differenceInDays(date, thisYearStart) <= offset) {
            this.validPeriods = this.validPeriods.filter(p => p != 'this year');
        }

        // return array of valid periods
        return this.validPeriods.map(p => this.capitalize(p));
    };

    public define_date_periods(offset: number) {
        const availablePeriods = this.getPredefinedDatePeriods();

        this.date_range_period_map[offset] = {};

        let from_date = new Date();
        let to_date = new Date();

        let
            date = Date.now(),

            // recent
            yesterday = startOfYesterday(),

            // week
            dayIndex: any = 0,

            weekOptions = { weekStartsOn: dayIndex },
            thisWeekStart = startOfWeek(date, weekOptions),
            thisWeekEnd = endOfWeek(date, weekOptions),
            lastWeekStart = subWeeks(thisWeekStart, 1),
            lastWeekEnd = subWeeks(thisWeekEnd, 1),

            // month
            thisMonthStart = startOfMonth(date),
            lastMonthStart = subMonths(thisMonthStart, 1),
            lastMonthEnd = lastDayOfMonth(lastMonthStart),

            // quarter
            thisQuarterStart = startOfQuarter(date),
            lastQuarterStart = subQuarters(thisQuarterStart, 1),
            lastQuarterEnd = lastDayOfQuarter(lastQuarterStart),

            // year
            thisYearStart = startOfYear(date),
            lastYearStart = subYears(thisYearStart, 1),
            lastYearEnd = lastDayOfYear(lastYearStart),

            // trailing (end date is today)
            trailing7Days = sub(this.today, { days: 7 }),
            trailing14Days = sub(this.today, { days: 14 }),
            trailing30Days = sub(this.today, { days: 30 }),
            trailing60Days = sub(this.today, { days: 60 }),
            trailing90Days = sub(this.today, { days: 90 }),
            trailing120Days = sub(this.today, { days: 120 }),
            trailing180Days = sub(this.today, { days: 180 });

        availablePeriods.forEach(period => {
            switch (period.toLowerCase()) {
                case 'today':
                    from_date = this.today;
                    to_date = this.today;
                    break;
                case 'yesterday':
                    from_date = yesterday;
                    to_date = yesterday;
                    break;
                case 'this week':
                    from_date = thisWeekStart;
                    to_date = this.today;
                    break;
                case 'last week':
                    from_date = lastWeekStart;
                    to_date = lastWeekEnd;
                    break;
                case 'this month':
                    from_date = thisMonthStart;
                    to_date = this.today;
                    break;
                case 'last month':
                    from_date = lastMonthStart;
                    to_date = lastMonthEnd;
                    break;
                case 'this quarter':
                    from_date = thisQuarterStart;
                    to_date = this.today;
                    break;
                case 'last quarter':
                    from_date = lastQuarterStart;
                    to_date = lastQuarterEnd;
                    break;
                case 'this year':
                    from_date = thisYearStart;
                    to_date = this.today;
                    break;
                case 'last year':
                    from_date = lastYearStart;
                    to_date = lastYearEnd;
                    break;
                case 'trailing 7 days':
                    from_date = trailing7Days;
                    to_date = this.today;
                    break;
                case 'trailing 14 days':
                    from_date = trailing14Days;
                    to_date = this.today;
                    break;
                case 'trailing 30 days':
                    from_date = trailing30Days;
                    to_date = this.today;
                    break;
                case 'trailing 60 days':
                    from_date = trailing60Days;
                    to_date = this.today;
                    break;
                case 'trailing 90 days':
                    from_date = trailing90Days;
                    to_date = this.today;
                    break;
                case 'trailing 120 days':
                    from_date = trailing120Days;
                    to_date = this.today;
                    break;
                case 'trailing 180 days':
                    from_date = trailing180Days;
                    to_date = this.today;
                    break;
            }

            if (offset && period.toLocaleLowerCase()!.match('^(this|trailing)')) {
                to_date = sub(to_date, { days: Math.abs(offset) });
            }

            if (period.toLocaleLowerCase() == 'last month' && (lastMonthEnd < this.today && this.today < add(lastMonthEnd, { days: Math.abs(offset) }))
                || period.toLocaleLowerCase() == 'last quarter' && (lastQuarterEnd < this.today && this.today < add(lastQuarterEnd, { days: Math.abs(offset) }))) {
                let offset = (this.today.getDate() == 1) ? -2 : -1;

                to_date = sub(to_date, { days: Math.abs(offset) });
            }

            from_date.setHours(0, 0, 0, 0);
            to_date.setHours(0, 0, 0, 0);
            this.date_range_period_map[offset][period] = { 'fromDate': from_date, 'toDate': to_date };
        });
    }

    public getDateRange(offset: number, { period, weekStart }: any) {
        let noResults = { startDate: '', endDate: '' };

        if (!period || typeof period != 'string' || !(period.trim().length > 0) || !this.validPeriods.some(i => i === period.toLowerCase())) {
            return {
                error: 'period is required, valid periods: ' + this.validPeriods.join(', '),
                ...noResults
            };
        } else {
            period = period.toLowerCase();
        }

        if (weekStart && (typeof weekStart != 'string' || !(weekStart.trim().length > 0) || weekStart && !this.validWeekStarts.some(i => i === weekStart.toLowerCase()))) {
            return {
                error: 'weekStart is optional, valid weekStarts: ' + this.validWeekStarts.join(', '),
                ...noResults
            };
        } else if (weekStart) {
            weekStart = weekStart.toLowerCase();
        }

        return this.date_range_period_map[offset][this.capitalize(period)];
    };

    public getDefaultDates(offset: number) {
        return this.getDateRange(offset, { period: 'trailing 30 days' });
    }

    public getHealthcheckDates() {
        return this.getDateRange(0, { period: 'trailing 7 days' });
    }

    public getInvalidDateRange(offsetDays: number) {
        this.hide_date.setDate(this.today.getDate() + offsetDays);
        this.hide_date.setHours(0, 0, 0, 0);

        const invalid_dates = [];

        while (this.hide_date <= this.today) {
            invalid_dates.push(new Date(this.hide_date));
            this.hide_date.setDate(this.hide_date.getDate() + 1);
        }

        return invalid_dates;
    }

    public getPredefinedDatePeriods() {
        return this.availablePeriods({ offset: 3, todayDate: this.today });
    }

    public getRefreshTime(): string {
        return moment(new Date()).local().format('YYYY-MM-DD hh:mm A');
    }
}
