import { Injectable } from '@angular/core';

import { BuildVersionService } from './build-version.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BuildVersion } from '../build-version';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuildVersionHttpService {
    public constructor(private http: HttpClient, private buildVersionService: BuildVersionService) {
    }

    public fetchBuildDetails(): Promise<BuildVersion> {
        const id = this.uuid();
        const httpOptions = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache'
            })
        };

        return new Promise(
            (resolve) => {
                firstValueFrom(this.http.get<BuildVersion>(`assets/build-version.json?v=${id}`, httpOptions)).then(response => {
                    this.buildVersionService.buildVersion = response!;
                    console.log('build version:', response);

                    resolve(this.buildVersionService.buildVersion);
                });
            }
        );
    }

    private uuid() {
        return "id" + Math.random().toString(16).slice(2);
    }
}
