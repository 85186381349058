import { CellClickedEvent, ColGroupDef, GridOptions, ValueFormatterFunc } from "ag-grid-community";
import { GridHelperService, TimeseriesGridData } from "src/app/services/grid-helper.service";
import { UrlManipulationService } from "src/app/services/url-manipulation.service";
import { calculatePoPChange } from "src/app/utils/scorecard.util";
import { ProfitabilityAccountColumnRendererComponent } from "./profitability-account-column-renderer.component";
import { ProfitabilityCategoryColumnRendererComponent } from "./profitability-category-column-renderer.component";
import { ProfitabilityProductColumnRendererComponent } from "./profitability-product-column-renderer.component";
import { makeMetricTooltipConfig } from "src/app/utils/make-custom-metric-tooltip-config.util";

const redirectURL = "https://amazon.com/dp/";
let debounceClick: NodeJS.Timeout | undefined;

export const profitabilityAmazonSettlementsGridColumns = {
    metric: 'Metric',
    date: 'Date',
    accountMarketplace: 'Account',
    account: 'Account',
    marketplace: 'Marketplace',
    product: 'ASIN',
    parentAsin: 'Parent ASIN',
    asin: 'ASIN',
    productName: 'Product Name',
    categorySubcategory: 'Category',
    cat: 'Category',
    subCat: 'Subcategory',
    userDefinedField: 'User Defined Field',
    rollingTotal: 'Rolling N Total',
    rollingAverage: 'Rolling N Avg',
};

export const profitabilityMetrics = [
    { internalName: 'units_shipped', displayName: 'Units Shipped', type: 'number', decimals: 0, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    { internalName: 'avg_selling_price', displayName: 'Avg Selling Price', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    // Sales
    { internalName: 'gross_sales', displayName: 'Gross Sales', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    { internalName: 'product_sales', displayName: 'Product Sales', type: 'currency', decimals: 2, parent: 'gross_sales' , aggregationTypes: ['Account', 'Product'] },
    { internalName: 'safe_t_reimbursement', displayName: 'Safe-T Reimbursement', type: 'currency', decimals: 2, parent: 'gross_sales', aggregationTypes: ['Account', 'Product'] },
    // Refunds
    { internalName: 'refunds', displayName: 'Refunds', type: 'currency', decimals: 2, isDefault: true , aggregationTypes: ['Account', 'Product'] },
    { internalName: 'base_refunds', displayName: 'Base Refunds', type: 'currency', decimals: 2, parent: 'refunds', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'prime_customer_refunds', displayName: 'Prime Customer Refunds', type: 'currency', decimals: 2, parent: 'refunds', aggregationTypes: ['Account', 'Product'] },
    // Promotional Rebates
    { internalName: 'promotional_rebates', displayName: 'Promotional Rebates', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    { internalName: 'lightning_deal_fee', displayName: 'Lightning Deal Fee', type: 'currency', decimals: 2, parent: 'promotional_rebates', aggregationTypes: ['Account'] },
    { internalName: 'promotion_cost', displayName: 'Promotion Cost', type: 'currency', decimals: 2, parent: 'promotional_rebates', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'promotion_cost_return', displayName: 'Promotion Cost Return', type: 'currency', decimals: 2, parent: 'promotional_rebates', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'promotion_cost_amazon_funded', displayName: 'Promotion Cost Amazon Funded', type: 'currency', decimals: 2, parent: 'promotional_rebates', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'promotion_cost_amazon_funded_return', displayName: 'Promotion Cost Amazon Funded Return', type: 'currency', decimals: 2, parent: 'promotional_rebates', aggregationTypes: ['Account', 'Product'] },
    // Gross to Net Adjustments
    { internalName: 'gross_to_net_adjustments', displayName: 'Gross to Net Adjustments', type: 'currency', decimals: 2, aggregationTypes: ['Account', 'Product'] },
    { internalName: 'subscription_fee', displayName: 'Subscription Fee', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account'] },
    { internalName: 'compensated_clawback', displayName: 'Compensated Clawback', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'gift_wrap_chargeback', displayName: 'Gift Wrap Chargeback', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'gift_wrap_chargeback_return', displayName: 'Gift Wrap Chargeback Return', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'gift_wrap_revenue', displayName: 'Gift Wrap Revenue', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'gift_wrap_revenue_return', displayName: 'Gift Wrap Revenue Return', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'payment_retraction', displayName: 'Payment Retraction', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'reimbursement_reversal', displayName: 'Reimbursement Reversal', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'return_shipping_compensation', displayName: 'Return Shipping Compensation', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'shipping_revenue', displayName: 'Shipping Revenue', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'shipping_revenue_return', displayName: 'Shipping Revenue Return', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'vine_enrollment_fee', displayName: 'Vine Enrollment Fee', type: 'currency', decimals: 2, parent: 'gross_to_net_adjustments', aggregationTypes: ['Account'] },
    // Net Sales
    { internalName: 'net_sales', displayName: 'Net Sales', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    // COGS
    { internalName: 'cogs', displayName: 'COGS', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    // Selling Fees
    { internalName: 'selling_fees', displayName: 'Selling Fees', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    { internalName: 'amazon_comission', displayName: 'Amazon Commission', type: 'currency', decimals: 2, parent: 'selling_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'amazon_comission_return', displayName: 'Amazon Commission Return', type: 'currency', decimals: 2, parent: 'selling_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'amazon_comission_refund', displayName: 'Amazon Commission Refund', type: 'currency', decimals: 2, parent: 'selling_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'shipping_holdback_fee', displayName: 'Shipping Holdback Fee', type: 'currency', decimals: 2, parent: 'selling_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'shipping_holdback_fee_return', displayName: 'Shipping Holdback Fee Return', type: 'currency', decimals: 2, parent: 'selling_fees', aggregationTypes: ['Account', 'Product'] },
    //  FBA Fees
    { internalName: 'fba_fees', displayName: 'FBA Fees', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    { internalName: 'fba_inbound_convenience_fee', displayName: 'FBA Inbound Convenience Fee', type: 'currency', decimals: 2, parent: 'fba_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_per_unit_fulfillment_fee', displayName: 'FBA Per Unit Fulfillment Fee', type: 'currency', decimals: 2, parent: 'fba_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'customer_return_per_unit_fee', displayName: 'Customer Return Per Unit Fee', type: 'currency', decimals: 2, parent: 'fba_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_disposal_fee', displayName: 'FBA Disposal Fee', type: 'currency', decimals: 2, parent: 'fba_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_inbound_defect_fee', displayName: 'FBA Inbound Defect Fee', type: 'currency', decimals: 2, parent: 'fba_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_inventory_storage_overage_fee', displayName: 'FBA Inventory Storage Overage Fee', type: 'currency', decimals: 2, parent: 'fba_fees', aggregationTypes: ['Account'] },
    { internalName: 'shipping_chargeback', displayName: 'Shipping Chargeback', type: 'currency', decimals: 2, parent: 'fba_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'shipping_chargeback_return', displayName: 'Shipping Chargeback Return', type: 'currency', decimals: 2, parent: 'fba_fees', aggregationTypes: ['Account', 'Product'] },
    // FBA Inventory Fees
    { internalName: 'fba_inventory_fees', displayName: 'FBA Inventory Fees', type: 'currency', decimals: 2, aggregationTypes: ['Account', 'Product'] },
    { internalName: 'fba_grade_and_resell_fee', displayName: 'FBA Grade and Resell Fee', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_grade_and_resell_refund', displayName: 'FBA Grade and Resell Refund', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_inbound_transportation_fee', displayName: 'FBA Inbound Transportation Fee', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_removal_fee', displayName: 'FBA Removal Fee', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_storage_fee', displayName: 'FBA Storage Fee', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'fba_lt_storage_fee', displayName: 'FBA LT Storage Fee', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_transportation_fee', displayName: 'FBA Transportation Fee', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    { internalName: 'fee_adjustment', displayName: 'Fee Adjustment', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_prep_fee', displayName: 'FBA Prep Fee', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_prep_fee_refund', displayName: 'FBA Prep Fee Refund', type: 'currency', decimals: 2, parent: 'fba_inventory_fees', aggregationTypes: ['Account'] },
    // Other Fees
    { internalName: 'other_fees', displayName: 'Other Fees', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    { internalName: 'amazon_fee_overcharge_reimbursement', displayName: 'Amazon Fee Overcharge Reimbursement', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'assessed_restocking_fee', displayName: 'Assessed Restocking Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'coupon_redemption_fee', displayName: 'Coupon Redemption Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'customer_goodwill', displayName: 'Customer Goodwill', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'customer_return_liquidation_comission', displayName: 'Customer Return Liquidation Commission', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'customer_return_liquidation_principal', displayName: 'Customer Return Liquidation Principal', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'damaged_warehouse_product_reimbursement', displayName: 'Damaged Warehouse Product Reimbursement', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'incorrect_product_return_reimbursement', displayName: 'Incorrect Product Return Reimbursement', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'lost_inbound_shipment_reimbursement', displayName: 'Lost Inbound Shipment Reimbursement', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'lost_warehouse_product_reimbursement', displayName: 'Lost Warehouse Product Reimbursement', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'prime_wardrobe_reimbursement', displayName: 'Prime Wardrobe Reimbursement', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'premium_experience_fee', displayName: 'Premium Experience Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'premium_experience_fee_return', displayName: 'Premium Experience Fee Return', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'removal_order_reimbursement', displayName: 'Removal Order Reimbursement', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account', 'Product'] },
    { internalName: 'retrocharge_shipping', displayName: 'Retrocharge Shipping', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'upstream_processing_fee', displayName: 'Upstream Processing Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'amazon_technology_fee', displayName: 'Amazon Technology Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'fiscal_representation_fee', displayName: 'Fiscal Representation Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'partner_fee', displayName: 'Partner Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_international_freight_fee', displayName: 'FBA International Freight Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'fba_inventory_placement_service_fee', displayName: 'FBA Inventory Placement Service Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'return_postage_billing', displayName: 'Return Postage Billing', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'paid_service_fee', displayName: 'Paid Service Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'csba_fee', displayName: 'CSBA Fee', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    { internalName: 'shipping_label_purchases', displayName: 'Shipping Label Purchases', type: 'currency', decimals: 2, parent: 'other_fees', aggregationTypes: ['Account'] },
    // Cost of Advertising
    { internalName: 'cost_of_advertising', displayName: 'Cost of Advertising', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    // Margin Pre Ad
    { internalName: 'margin_pre_ad', displayName: 'Margin Pre Ad', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    // Net Profit
    { internalName: 'net_profit', displayName: 'Net Profit', type: 'currency', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
    // Profit Margin
    { internalName: 'profit_margin', displayName: 'Profit Margin', type: 'percentage', decimals: 2, isDefault: true, aggregationTypes: ['Account', 'Product'] },
];

export function makeGridColumnDefs(dateColumns: Array<ColGroupDef>): Array<ColGroupDef> {
    const identifierColumn: ColGroupDef = {
        headerName: 'Identifiers',
        groupId: 'IDENTIFIERS',
        children: [
            {
                colId: 'DATE_IDENTIFIER',
                field: profitabilityAmazonSettlementsGridColumns.date,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.metric,
                pinned: 'left',
                width: 400,
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                cellRenderer: 'agGroupCellRenderer',
                showRowGroup: true,
                cellRendererParams: {
                    suppressCount: true
                },
                ...makeMetricTooltipConfig('cell'),
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.accountMarketplace,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                cellRenderer: ProfitabilityAccountColumnRendererComponent,
                hide: false,
                filterParams: {
                    filterOptions: ['contains', 'notContains'],

                    textMatcher: (params: any) => {
                        return params.data['Account'].toLowerCase().includes(params.filterText)
                            || params.data['Marketplace'].toLowerCase().includes(params.filterText);
                    }
                }
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.account,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.marketplace,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                width: 135,
                cellStyle: { 'text-align': 'center' },
                hide: true,
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.product,
                pinned: 'left',
                width: 400,
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                cellRenderer: ProfitabilityProductColumnRendererComponent,
                hide: false,
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                filterParams: {
                    filterOptions: ['contains', 'notContains'],

                    textMatcher: (params: any) => {
                        return params.data['Product Name']?.toLowerCase().includes(params.filterText)
                            || params.data['ASIN']?.toLowerCase().includes(params.filterText)
                            || params.data['Category']?.toLowerCase().includes(params.filterText)
                            || params.data['Subcategory']?.toLowerCase().includes(params.filterText);
                    }
                },
                onCellDoubleClicked: (event: CellClickedEvent) => {
                    clearTimeout(debounceClick);

                    debounceClick = setTimeout(() => {
                        window.open(redirectURL + event.data['ASIN']);
                    }, 150);
                },
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.parentAsin,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                onCellDoubleClicked: (event: CellClickedEvent) => {
                    clearTimeout(debounceClick);

                    debounceClick = setTimeout(() => {
                        window.open(redirectURL + event.value);
                    }, 150);
                },
                hide: true
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.asin,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                onCellDoubleClicked: (event: CellClickedEvent) => {
                    clearTimeout(debounceClick);

                    debounceClick = setTimeout(() => {
                        window.open(redirectURL + event.value);
                    }, 150);
                },
                hide: true,
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.productName,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                width: 250,
                hide: true,
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.categorySubcategory,
                pinned: 'left',
                lockPinned: true,
                cellClass: 'lock-pinned',
                filter: 'agTextColumnFilter',
                cellRenderer: ProfitabilityCategoryColumnRendererComponent,
                hide: false,
                width: 250,
                cellRendererParams: {},
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
                filterParams: {
                    filterOptions: ['contains', 'notContains'],

                    textMatcher: (params: any) => {
                        return params.data['Category']?.toLowerCase().includes(params.filterText)
                            || params.data['Subcategory']?.toLowerCase().includes(params.filterText);
                    }
                }
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.cat,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.subCat,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            {
                field: profitabilityAmazonSettlementsGridColumns.userDefinedField,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                hide: true,
                width: 200,
                cellStyle: { 'white-space': 'normal', 'line-height': 1.6 },
            }
        ],
    };

    const rollingColumns: ColGroupDef = {
        headerName: 'Rolling Metrics',
        groupId: 'ROLLING_METRICS',
        children: [
            {
                colId: `rolling_total`,
                headerName: 'Rolling Total',
                filter: 'agNumberColumnFilter',
                hide: true
            },
            {
                colId: `rolling_average`,
                headerName: 'Rolling Avg',
                filter: 'agNumberColumnFilter',
                hide: true
            }
        ]
    };

    return [
        ...[identifierColumn],
        ...dateColumns,
        ...[rollingColumns]
    ];
}

export function makeGridOptions(colDefs: Array<ColGroupDef>, urlManipulationService: UrlManipulationService, gridHelper: GridHelperService): GridOptions {
    return {
        columnDefs: colDefs,
        enableRangeSelection: true,
        tooltipShowDelay: 500,
        defaultColDef: {
            width: 150,
            editable: false,
            resizable: true,
            sortable: true
        },
        pagination: true,
        defaultColGroupDef: {
            marryChildren: true,
        },
        rowData: [],
        getRowHeight: function (params) {
            const MIN_HEIGHT = 50;

            const data = params.data['Product Name'];

            if (data) {
                const height = MIN_HEIGHT + (data.length * 0.7);

                return height > MIN_HEIGHT ? height : MIN_HEIGHT;
            }

            return 40;
        },
        onFilterChanged: (params) => {
            urlManipulationService.setAgGridFilterToUrl('Profitability Products', 'filter', params);
        },
        onSortChanged: (params) => {
            urlManipulationService.setAgGridSortToUrl('Profitability Products', 'sort', params);
        },
        onCellClicked: (event: CellClickedEvent) => {
            gridHelper.copyCellToClipboard(event.value);
        },
        getDataPath: (data) => data.path,
        groupDisplayType: 'custom',
        treeData: true,
    };
}

export function mapGridData(data: any, dates: string[]) {
    const grid_data: any = [];

    data.forEach((row: any) => {
        let rec: any = {};

        rec['path'] = [row.aggregation_text];
        rec[profitabilityAmazonSettlementsGridColumns.date] = row.aggregation_text;
        rec[profitabilityAmazonSettlementsGridColumns.account] = row.account;
        rec[profitabilityAmazonSettlementsGridColumns.marketplace] = row.marketplace;
        rec[profitabilityAmazonSettlementsGridColumns.parentAsin] = row.parent_asin;
        rec[profitabilityAmazonSettlementsGridColumns.asin] = row.asin;
        rec[profitabilityAmazonSettlementsGridColumns.productName] = row.product_name;
        rec[profitabilityAmazonSettlementsGridColumns.cat] = row.category;
        rec[profitabilityAmazonSettlementsGridColumns.subCat] = row.sub_category;
        rec[profitabilityAmazonSettlementsGridColumns.userDefinedField] = row.user_defined_field;

        dates.forEach((date, index: number) => {
            const rowValue = row.values[index];
            const rowPop = row.pop ? row.pop[index] : null;
            const rowYoy = row.yoy ? row.yoy[index] : null;

            rec[date] = {};

            for (const metricDefinition of profitabilityMetrics) {
                rec[date][metricDefinition.internalName] = rowValue?.[metricDefinition.internalName];
                rec[date][`${metricDefinition.internalName}_pop`] = calculatePoPChange(rowValue?.[metricDefinition.internalName], rowPop?.[metricDefinition.internalName]);
                rec[date][`${metricDefinition.internalName}_pop_diff`] = rowValue?.[metricDefinition.internalName] - rowPop?.[metricDefinition.internalName];
                rec[date][`${metricDefinition.internalName}_yoy`] = calculatePoPChange(rowValue?.[metricDefinition.internalName], rowYoy?.[metricDefinition.internalName]);
                rec[date][`${metricDefinition.internalName}_yoy_diff`] = rowValue?.[metricDefinition.internalName] - rowYoy?.[metricDefinition.internalName];
            }
        });

        rec[profitabilityAmazonSettlementsGridColumns.rollingTotal] = row.rolling?.total;
        rec[profitabilityAmazonSettlementsGridColumns.rollingAverage] = row.rolling?.average;

        grid_data.push(rec);
    });

    return grid_data;
}

export function mapGridDataByMetric(gridData: TimeseriesGridData, data: any) {
    const output = [];
    const flatten = (row: any, values: any[]) => {
        const [firstValue] = values || [];

        if (!firstValue) {
            return null;
        }

        return {
            ...row,
            ...firstValue
        };
    };

    const flattenValues = data.map((row: any) => flatten(row, row.values)).filter((row: any) => row);
    const flattenPop = data.map((row: any) => flatten(row, row.pop)).filter((row: any) => row);

    const distinctDates: string[] = Array.from(new Set(flattenValues.map((row: any) => row.aggregation_text)));

    for (const metricDefinition of profitabilityMetrics) {
        const metricInternalName = metricDefinition.internalName;
        const metric = metricDefinition.displayName;

        const row: any = {};
        const popRowChangePctRow: any = {};
        const popRowChangeDiffRow: any = {};
        const yoyRowChangePctRow: any = {};
        const yoyRowChangeDiffRow: any = {};
        const lyRow: any = {};

        const makePath = (segment: string | null = null) => {
            const path = [];

            if (metricDefinition.parent) {
                path.push(metricDefinition.parent);
            }

            path.push(metricInternalName);

            if (segment) {
                path.push(`${metricInternalName}.${segment}`);
            }

            return path;
        };

        row[profitabilityAmazonSettlementsGridColumns.metric] = metric;
        row['metricName'] = metricInternalName;
        row.path = makePath();
        row.type = 'actual';

        popRowChangePctRow[profitabilityAmazonSettlementsGridColumns.metric] = `${metric} PoP % Change`;
        popRowChangePctRow['metricName'] = metricInternalName;
        popRowChangePctRow.path = makePath('pop_change');
        popRowChangePctRow.type = 'pop_change';

        popRowChangeDiffRow[profitabilityAmazonSettlementsGridColumns.metric] = `${metric} PoP Diff`;
        popRowChangeDiffRow['metricName'] = metricInternalName;
        popRowChangeDiffRow.path = makePath('pop_diff');
        popRowChangeDiffRow.type = 'pop_diff';

        yoyRowChangePctRow[profitabilityAmazonSettlementsGridColumns.metric] = `${metric} YoY % Change`;
        yoyRowChangePctRow['metricName'] = metricInternalName;
        yoyRowChangePctRow.path = makePath('yoy_change');
        yoyRowChangePctRow.type = 'yoy_change';

        yoyRowChangeDiffRow[profitabilityAmazonSettlementsGridColumns.metric] = `${metric} YoY Diff`;
        yoyRowChangeDiffRow['metricName'] = metricInternalName;
        yoyRowChangeDiffRow.path = makePath('yoy_diff');
        yoyRowChangeDiffRow.type = 'yoy_diff';

        lyRow[profitabilityAmazonSettlementsGridColumns.metric] = `${metric} LY`;
        lyRow['metricName'] = metricInternalName;
        lyRow.path = makePath('ly');
        lyRow.type = 'ly';

        for (const date of distinctDates) {
            const actualForDate = flattenValues.find((row: any) => row.aggregation_text === date);
            const popForDate = flattenPop.find((row: any) => row.aggregation_text === date);
            const yoyForDate = flattenValues.find((row: any) => row.aggregation_text === date);

            row[date] = actualForDate?.[metricInternalName];
            popRowChangePctRow[date] = calculatePoPChange(actualForDate?.[metricInternalName], popForDate?.[metricInternalName]);
            popRowChangeDiffRow[date] = actualForDate?.[metricInternalName] - popForDate?.[metricInternalName];
            yoyRowChangePctRow[date] = calculatePoPChange(actualForDate?.[metricInternalName], yoyForDate?.[metricInternalName]);
            yoyRowChangeDiffRow[date] = actualForDate?.[metricInternalName] - yoyForDate?.[metricInternalName];
            lyRow[date] = yoyForDate?.[metricInternalName];
        }

        output.push(row);

        if (gridData.hasPoPChange) {
            output.push(popRowChangePctRow);
        }

        if (gridData.hasPoPDifference) {
            output.push(popRowChangeDiffRow);
        }

        if (gridData.hasYoYChange) {
            output.push(yoyRowChangePctRow);
        }

        if (gridData.hasYoYDifference) {
            output.push(yoyRowChangeDiffRow);
        }

        if (gridData.hasLyActuals) {
            output.push(lyRow);
        }
    }

    return output;
}
