import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AuthGuardService implements CanActivate {
    public constructor(private auth: AuthService, public router: Router, public userService: UserService) { }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            const authenticated = await this.auth.isAuthenticated();

            return authenticated;
        } catch (error) {
            try {
                await this.auth.setupOrgServiceUser();

                location.reload();
            } catch (error) {
            } finally {
                console.error(error);

                return false;
            }
        }
    }
}
