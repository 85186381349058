import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

export interface UserAction {
    type: UserActionTypeEnum;
    module: string;
    dashboard: string;
    marketplace: string;
    account: string;
    fields: { [key: string]: string };
}

export interface UserAnalyticsOverviewParams {
    accounts: string[];
    marketplaces: string[];
    reportLevel: string;
    aggregationType: string;
    lookbackPeriod: number;
    userType: string[];
}

export interface UserAnalyticsActivitiesParams {
    accounts: string[];
    marketplaces: string[];
    startDate: string;
    endDate: string;
}

export enum UserActionTypeEnum {
    Navigate = 'navigate',
    ApplyFilter = 'apply_filter',
    SelectAsin = 'select_asin',
    FilterTable = 'filter_table',
    SortTable = 'sort_table',
    ViewUserGuide = 'view_user_guide',
    ExportButton = 'export_button'
}

@Injectable()
export class UserAnalyticsService {
    public baseUrl = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private userService: UserService) { }

    public async getOverview(params: UserAnalyticsOverviewParams): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'user-analytics/overview');

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': params.accounts,
                    'marketplaces[]': params.marketplaces,
                    reportLevel: params.reportLevel,
                    aggregationType: params.aggregationType,
                    lookbackPeriod: params.lookbackPeriod,
                    'userType[]': params.userType
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getActions(params: UserAnalyticsActivitiesParams): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + 'user-analytics/actions');

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': params.accounts,
                    'marketplaces[]': params.marketplaces,
                    startDate: params.startDate,
                    endDate: params.endDate
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getActionFields(actionId: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const url = new URL(this.baseUrl + `user-analytics/actions/${actionId}/fields`);

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async dispatchAction(data: UserAction): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const payload = {
                ...data,
                email: user.email,
            };

            const url = new URL(this.baseUrl + 'user-analytics/action');

            return this.http.post(url.toString(), payload, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async persistTimeSpent(params: { timeSpent: number; module: string; dashboard: string; account: string }) {
        try {
            const user = this.userService.getUser()!;

            const { timeSpent, dashboard, account, module } = params;

            const url = new URL(this.baseUrl + 'user-analytics/time-spent');

            return this.http.post(url.toString(), {
                email: user.email,
                dashboard,
                timeSpent,
                account,
                module
            }, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return of({ 'status_code': 401, 'detail': error });
        }
    }
}
