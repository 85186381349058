import { InjectionToken, Injector } from "@angular/core";
import { Store, createStore, select, withProps } from "@ngneat/elf";
import { Observable, filter } from "rxjs";
import { profitabilityMetrics } from "src/app/pages/profitability/profitability-amazon-settlements/profibility-amazon-settlements.grid";
import { getDefaultUnifiedTimeseriesMetrics, unifiedTimeseriesMetrics } from "src/app/pages/unified-reports/unified-timeseries/unified-timeseries.grid";
import { UserService } from "src/app/services/user.service";

export const FILTER_STORE_TOKEN = new InjectionToken('FilterStore');

export interface FilterProps {
    accountType: string;
    accounts: string[];
    adBrands: string[];
    adCampaignTypes: string[];
    adCategories: string[];
    asins: string[];
    currency: string;
    fromDate: string;
    toDate: string;
    datePeriod: string;
    lookbackWindow: number;
    marketplaces: string[];
    metric: string;
    reportLevel: string;
    adStrategicPillars: string[];
    adSubcategories: string[];
    productTitles: string[];
    skus: string[];
    legend: string;
    size: string;
    campaignNames: string[];
    percentiles: string[];
    keywordTexts: string[];
    metricColumns: string[];
    assessments: string[];
    operationIssueStatus: string;
    operationIssueTypes: string[];
    asinTiers: string[];
    manageFromDate: string;
    manageToDate: string;
    rollingMetric: string;
    includeTax: string;
    scorecardTimeseriesAscAggregationType: string;
    scorecardTimeseriesAvcAggregationType: string;
    scorecardTimeseriesAscMetric: string;
    scorecardTimeseriesAvcMetric: string;
    scorecardInventoryAggregationType: string;
    scorecardTimeseriesMultimetricsMetrics: string[];
    scorecardTimeseriesMultimetricsInclude: string[];
    measureOverviewAggregationType: string;
    measureTableView: string;
    measureComparisonType: string;
    optimizeProductsAggregationType: string;
    optimizeKeywordsAggregationType: string;
    investigateProductsAggregationType: string;
    investigateProductsComparisonType: string;
    investigateCampaignsAggregationType: string;
    investigateKeywordsAggregationType: string;
    validationFromDate: string;
    validationToDate: string;
    dataIntegrityAccounts: string[];
    dataIntegrityAccountType: string;
    dataIntegrityMarketplaces: string[];
    dataIntegrityCurrency: string;
    dataIntegrityIncludeTax: string;
    dataIntegrityAmazonAccount: string;
    dataIntegrityAmazonMarketplace: string[];
    healthCheckFromDate: string;
    healthCheckToDate: string;
    includeIgnoredGlobalDocs: string;
    crossClientsAccountStatus: string;
    crossClientsAccounts: string[];
    crossClientsMarketplaces: string[];
    crossClientsCurrency: string;
    crossClientsIncludeTax: string;
    crossClientsReportLevel: string;
    crossClientsLookbackWindow: number;
    profitabilityAggregationType: string;
    userAnalyticsOverviewAccounts: string[];
    userAnalyticsOverviewMarketplaces: string[];
    userAnalyticsOverviewReportLevel: string;
    userAnalyticsOverviewAggregationType: string;
    userAnalyticsOverviewLookbackWindow: number;
    userAnalyticsOverviewStartDate: string;
    userAnalyticsOverviewEndDate: string;
    userAnalyticsOverviewUserType: string[];
    financeClientStatus: string;
    generatedReportReportTypes: string[];
    profitabilityFinanceProductsMetrics: string[];
    profitabilityFinanceProductsAggregationType: string;
    profitabilityFinanceProductsInclude: string[];
    profitabilityFinanceOrdersTransactionType: string;
    profitabilityFinanceOrdersStartDate: string;
    profitabilityFinanceOrdersEndDate: string;
    profitabilityAmazonSettlementsAggregationType: string;
    profitabilityAmazonSettlementsMetrics: string[];
    profitabilityAmazonSettlementsInclude: string[];
    globaldocAccount: string;
    globaldocMarketplaces: string[];
    unifiedAccounts: string[];
    unifiedCountries: string[];
    unifiedCurrency: string;
    unifiedTimeseriesReportLevel: string;
    unifiedTimeseriesLookbackWindow: number;
    unifiedTimeseriesAggregationType: string;
    unifiedTimeseriesMetrics: string[];
    unifiedTimeseriesInclude: string[];
    unifiedTimeseriesPlatform: string;
};

export class FilterStore {
    public readonly filters$!: Observable<FilterProps>;

    private ready = false;

    private readonly store!: Store;

    private readonly userService: UserService;

    private initialState: FilterProps = {
        accountType: 'ASC',
        accounts: [],
        adBrands: [],
        currency: 'USD',
        marketplaces: ['US'],
        lookbackWindow: 12,
        adCampaignTypes: [],
        adCategories: [],
        reportLevel: 'Weekly',
        adStrategicPillars: [],
        adSubcategories: [],
        metric: '',
        fromDate: '',
        toDate: '',
        datePeriod: '',
        asins: [],
        productTitles: [],
        skus: [],
        legend: '',
        size: '',
        campaignNames: [],
        percentiles: [],
        keywordTexts: [],
        metricColumns: [],
        assessments: [],
        operationIssueStatus: 'open',
        operationIssueTypes: ['lost buybox', 'buybox suppressed', 'search suppressed'],
        asinTiers: [],
        manageFromDate: '',
        manageToDate: '',
        rollingMetric: '12',
        includeTax: 'Yes',
        validationFromDate: '',
        validationToDate: '',

        /** DataIntegrity-specific filters */
        dataIntegrityAccounts: [],
        dataIntegrityAccountType: 'ASC',
        dataIntegrityMarketplaces: [],
        dataIntegrityCurrency: 'USD',
        dataIntegrityIncludeTax: 'Yes',
        healthCheckFromDate: '',
        healthCheckToDate: '',
        dataIntegrityAmazonAccount: '',
        dataIntegrityAmazonMarketplace: [],

        /** Scorecard-specific filters */
        scorecardTimeseriesAscAggregationType: 'ASIN',
        scorecardTimeseriesAvcAggregationType: 'ASIN',
        scorecardTimeseriesAscMetric: 'Sales',
        scorecardTimeseriesAvcMetric: 'Shipped Revenue',
        scorecardInventoryAggregationType: 'ASIN',
        scorecardTimeseriesMultimetricsMetrics: [],
        scorecardTimeseriesMultimetricsInclude: ['Actuals'],

        /** Measure-specific filters */
        measureOverviewAggregationType: 'Date',
        measureTableView: 'Metric',
        measureComparisonType: 'Year Over Year',

        /** Optimize-specific filters */
        optimizeProductsAggregationType: 'ASIN',
        optimizeKeywordsAggregationType: 'Keyword',

        /** Investigate-specific filters */
        investigateProductsAggregationType: 'ASIN',
        investigateProductsComparisonType: 'Year Over Year',
        investigateCampaignsAggregationType: 'Campaign Name',
        investigateKeywordsAggregationType: 'Keyword',

        /** Global Docs-specific filters */
        includeIgnoredGlobalDocs: 'not ignored',

        /** Cross-clients specific filters */
        crossClientsAccountStatus: 'Active',
        crossClientsAccounts: [],
        crossClientsMarketplaces: ['US'],
        crossClientsCurrency: 'USD',
        crossClientsIncludeTax: 'Yes',
        crossClientsReportLevel: 'Monthly',
        crossClientsLookbackWindow: 12,

        /** Profitability specific filters */
        profitabilityAggregationType: 'ASIN',
        profitabilityFinanceProductsMetrics: ['Net Sales', 'Amazon Fees', 'COGS', 'Ad Spend', 'Operating Profit'],
        profitabilityFinanceProductsAggregationType: 'ASIN',
        profitabilityFinanceProductsInclude: ['Actuals'],
        profitabilityFinanceOrdersTransactionType: 'Sales',
        profitabilityFinanceOrdersStartDate: '',
        profitabilityFinanceOrdersEndDate: '',
        profitabilityAmazonSettlementsMetrics: profitabilityMetrics.filter(metric => metric.isDefault).map(metric => metric.displayName),
        profitabilityAmazonSettlementsAggregationType: 'ASIN',
        profitabilityAmazonSettlementsInclude: ['Actuals'],

        /** User analytics overview specific filters */
        userAnalyticsOverviewAccounts: [],
        userAnalyticsOverviewMarketplaces: ['US'],
        userAnalyticsOverviewReportLevel: 'Weekly',
        userAnalyticsOverviewAggregationType: 'Date',
        userAnalyticsOverviewLookbackWindow: 4,
        userAnalyticsOverviewStartDate: '',
        userAnalyticsOverviewEndDate: '',
        userAnalyticsOverviewUserType: ['Internal'],

        /** Finance-specific filters */
        financeClientStatus: 'Active',

        /** Generated Reports-specific filters */
        generatedReportReportTypes: [],

        /** Globaldoc filters */
        globaldocAccount: '',
        globaldocMarketplaces: [],

        /** Unified Reports-specific filters */
        unifiedAccounts: [],
        unifiedCountries: ['US'],
        unifiedCurrency: 'USD',
        unifiedTimeseriesReportLevel: 'Weekly',
        unifiedTimeseriesLookbackWindow: 4,
        unifiedTimeseriesAggregationType: 'Account',
        unifiedTimeseriesMetrics: getDefaultUnifiedTimeseriesMetrics('Growth Marketing').map(metric => metric.displayName),
        unifiedTimeseriesInclude: ['Actuals'],
        unifiedTimeseriesPlatform: 'Growth Marketing',
    };

    public constructor(public injector: Injector) {
        this.userService = injector.get(UserService);

        this.store = createStore(
            { name: FILTER_STORE_TOKEN.toString() },
            withProps<FilterProps>(this.initialState)
        );

        this.filters$ = this.store.pipe(filter(() => this.ready), select((state) => state));

        this.initStore();
    }

    public async initStore() {
        const { accounts, accountType } = await this.userService.getDefaultFilterValues();

        const { fromDate, toDate, datePeriod } = this.userService.getDefaultDateRange({ offset: 2 });

        const { fromDate: defaultFromDateZeroOffset, toDate: defaultToDateZeroOffset } = this.userService.getDefaultDateRange({ offset: 0 });

        const { fromDate: healthCheckFromDateZeroOffset, toDate: healthCheckToDateZeroOffset } = this.userService.getHealthcheckDateRange();

        const unifiedAccounts = accounts;

        this.initialState = {
            ...this.initialState,
            accountType,
            accounts,
            fromDate,
            toDate,
            datePeriod,
            manageFromDate: defaultFromDateZeroOffset,
            manageToDate: defaultToDateZeroOffset,
            validationFromDate: defaultFromDateZeroOffset,
            validationToDate: defaultToDateZeroOffset,
            crossClientsAccounts: accounts,
            userAnalyticsOverviewStartDate: defaultFromDateZeroOffset,
            userAnalyticsOverviewEndDate: defaultToDateZeroOffset,
            healthCheckFromDate: healthCheckFromDateZeroOffset,
            healthCheckToDate: healthCheckToDateZeroOffset,
            profitabilityFinanceOrdersStartDate: defaultFromDateZeroOffset,
            profitabilityFinanceOrdersEndDate: defaultToDateZeroOffset,
            globaldocAccount: accounts[0] || '',
            globaldocMarketplaces: [],
            unifiedAccounts,
        };

        const urlParams = new URLSearchParams(window.location.search);

        let params: FilterProps = { ...this.initialState };

        Object.keys(this.initialState).forEach((key: string) => {
            const defaultValueKey = key as keyof FilterProps;

            if (['accounts', 'accountType'].includes(defaultValueKey) && urlParams.has(defaultValueKey)) {
                let accounts: string[] = JSON.parse(urlParams.get(defaultValueKey) || '[]');

                accounts = accounts && Array.isArray(accounts) ? accounts.filter(account => account) : [];

                if (accounts.length <= 0) {
                    urlParams.delete(defaultValueKey);
                }
            }

            params = {
                ...params,
                [key]: urlParams.has(defaultValueKey) ? JSON.parse(urlParams.get(defaultValueKey) || '') : this.initialState[defaultValueKey]
            };
        });

        this.ready = true;

        this.update(params);
    }

    public update(props: FilterProps | { [key: string]: any }) {
        this.store.update(state => ({ ...state, ...props }));
    }
}
