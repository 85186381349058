import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { buildCacheKey } from '../utils/cache.util';
import { CacheService } from './cache.service';
import { UserService } from './user.service';

export interface ScorecardTimeseriesFilters {
  account: string;
  lookback_window: number;
  report_level: string;
  aggregation_type: string;
}

@Injectable({
    providedIn: 'root'
})
export class PlanningService {
    private base_url = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private cacheService: CacheService,
        private userService: UserService
    ) { }

    public async getPlanningData(scorecardTimeseriesFilters: ScorecardTimeseriesFilters): Promise<Observable<any>> {
        try {
            const url = new URL(this.base_url + 'planning');

            const user = this.userService.getUser()!;

            return this.http.get<[]>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    account: scorecardTimeseriesFilters.account,
                    lookbackPeriod: scorecardTimeseriesFilters.lookback_window,
                    reportLevel: scorecardTimeseriesFilters.report_level,
                    aggregationType: scorecardTimeseriesFilters.aggregation_type
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public cacheResponse(result: Array<any>, cacheKey: string) {
        this.cacheService.setCache('Memory', cacheKey, result, (60 * 60 * 1));

        return result;
    }
}
