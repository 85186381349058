import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable({
    providedIn: 'root'
})
export class MetricsDictionaryService {
    private data: { [key: string]: any } = {};

    private readonly baseUrl = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private userService: UserService) { }

    public async getDataDictionary(): Promise<void> {
        const user = this.userService.getUser()!;

        const endpoint = new URL(this.baseUrl + 'data-dictionary');

        this.http.get<{ url: string }>(endpoint.toString(), {
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).subscribe((response) => {
            (window as any).DATA_DICTIONARY = response;

            localStorage.setItem('DATA_DICTIONARY', JSON.stringify(response));
        });
    }

    public getMetricDefinition(metric: string): any {
        return metric in this.data ? this.data[metric] : 'Definition not found';
    }
}
