import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

interface CreateOrUpdateData {
    id: string;
    account: string;
    asin: string;
    changeType: string;
    frequency: string;
    scope: string;
    notificationMethods: string;
}

@Injectable()
export class NotificationConfigurationService {
    public apiV2 = environment.apiV2Url;

    public constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async get_all(): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            let url = new URL(this.apiV2 + 'manage/notifications-configurations');

            return this.http.get<Array<any>>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async create_or_update(data: CreateOrUpdateData): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const payload = {
                ...data,
                email: user.email,
                phone: user.phone
            };

            let url = new URL(this.apiV2 + 'manage/notifications-configurations');

            return this.http.post(url.toString(), payload, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async delete(id: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            let url = new URL(this.apiV2 + 'manage/notifications-configurations');

            url.searchParams.append('id', id);

            return this.http.delete(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async update_user_phone(phone: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const url = new URL(this.apiV2 + 'configurations/update-user-phone');

            const payload = {
                email: user.email,
                phone
            };

            return this.http.post(url.toString(), payload, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return throwError(() => error);
        }
    }
}
