import { User } from "@auth0/auth0-angular";

export default class Auth0Utils {
    public static getCachedAccessToken(): any {
        const cachedAuth0State = JSON.parse(Auth0Utils.getLocalStorageEntryWithPrefix('@@auth0spajs@@', '::api.local::openid profile email'));

        const accessToken = cachedAuth0State?.body.access_token;

        return accessToken;
    }

    public static getCachedUser(): User {
        const cachedAuth0State = JSON.parse(Auth0Utils.getLocalStorageEntryWithPrefix('@@auth0spajs@@', '@@user@@'));

        const user = cachedAuth0State?.decodedToken?.user;

        return user;
    }

    private static getLocalStorageEntryWithPrefix(prefix: string, scope: string): any {
        const keys = Object.keys(localStorage);
        const matchingKey = keys.find(key => key.startsWith(prefix) && key.includes(scope));

        if (matchingKey) {
            return localStorage.getItem(matchingKey);
        }

        return null;
    }
}
