import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable()
export class UrlHelperService {
    public baseUrl = environment.apiV2Url;

    public constructor(private http: HttpClient, private userService: UserService) { }

    public async getShortUrlId(longUrl: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const url = new URL(this.baseUrl + 'url-shortener');

            return this.http.post<string>(url.toString(), { url: longUrl }, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }

    public async getLongUrlFromId(shortUrlId: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;
            const url = new URL(this.baseUrl + `url-shortener/${shortUrlId}`);

            return this.http.get<string>(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }
}
