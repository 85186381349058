import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CognitoJwtVerifier } from "aws-jwt-verify";
import Auth0Utils from './auth0-utils';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { first, firstValueFrom, lastValueFrom } from 'rxjs';
import { UserService } from './user.service';
import { EventTrackService } from './event-track.service';
import { MetricsDictionaryService } from './metrics-dictionary.service';

@Injectable()
export class AuthService {
    public verifier = CognitoJwtVerifier.create({
        userPoolId: environment.userPoolId,
        tokenUse: "access",
        clientId: environment.userPoolWebClientId,
    });

    public constructor(
        private auth0Service: Auth0Service,
        private jwtHelper: JwtHelperService,
        private userService: UserService,
        private eventTrackService: EventTrackService,
        private metricsDictionaryService: MetricsDictionaryService) { }

    public async isAuthenticated(): Promise<boolean> {
        await this.validateUser();

        return await this.checkTokenValidity();
    }

    public async setupOrgServiceUser() {
        let auth0User = await firstValueFrom(this.auth0Service.user$.pipe(first()));

        if (!auth0User) {
            auth0User = Auth0Utils.getCachedUser();
        }

        const accessToken = await this.getAccessToken();

        return lastValueFrom(await this.userService.setupOrgServiceUser(accessToken));;
    }

    private async validateUser() {
        let auth0User = await firstValueFrom(this.auth0Service.user$.pipe(first()));

        if (!auth0User) {
            auth0User = Auth0Utils.getCachedUser();
        }

        const accessToken = await this.getAccessToken();
        const currentUser = await firstValueFrom(await this.userService.getUserFromEmail(accessToken));

        this.userService.setUser({
            userId: currentUser.user_id,
            orgServiceUserId: currentUser.org_service_user_id,
            name: currentUser.full_name,
            email: currentUser.email,
            phone: currentUser.phone_number,
            group: currentUser.group_type,
            department: currentUser.department,
            token: accessToken,
            role: currentUser.role,
            access: currentUser.access,
            defaultASCAccount: currentUser.default_account_asc,
            defaultAVCAccount: currentUser.default_account_avc,
            defaultModule: currentUser.default_module,
            slackId: currentUser.slack_id,
            accounts: currentUser.account_name?.split(','),
            amzAccountTypes: currentUser.amz_account_type?.split(','),
            allowedAccounts: currentUser.allowed_accounts,
            orgService: currentUser.orgService
        });

        this.eventTrackService.init();

        this.metricsDictionaryService.getDataDictionary();
    }

    private async getAccessToken() {
        let accessToken = await this.getCartAccessToken();

        if (!accessToken) {
            accessToken = Auth0Utils.getCachedAccessToken();
        }

        return accessToken;
    }

    private async getCartAccessToken(): Promise<any> {
        try {
            const accessToken = await firstValueFrom(this.auth0Service.getAccessTokenSilently({
                authorizationParams: {
                    audience: 'api.local'
                }
            }));

            return accessToken;
        } catch (error) {
            return null;
        }
    }

    private async checkTokenValidity(): Promise<boolean> {
        const expired = await this.jwtHelper.isTokenExpired();

        if (expired) {
            throw new Error('Token is expired');
        }

        return !expired;
    }
}
