import { Injectable } from "@angular/core";
import { extractPercentRangeFilterValue, getPercentileRange } from "../utils/zing-chart.util";
import { InvestigateFilters } from "./investigate.service";
import { OptimizeFilters } from "./optimize.service";

@Injectable()
export class PercentileFilterService {
    public createPercentRangeValues(result: Array<any>) {
        return result.map((item) => {
            item.SALES_PERCENTILE_RANGE = getPercentileRange(item.SALES_PERCENTILE);
            item.SESSION_PERCENTILE_RANGE = getPercentileRange(item.SESSION_PERCENTILE);
            item.CONVERSION_RATE_PERCENTILE_RANGE = getPercentileRange(item.CONVERSION_RATE_PERCENTILE);

            item.AD_ROAS_PERCENTILE_RANGE = getPercentileRange(item.AD_ROAS_PERCENTILE);
            item.AD_SPEND_PERCENTILE_RANGE = getPercentileRange(item.AD_SPEND_PERCENTILE);
            item.AD_SALES_PERCENTILE_RANGE = getPercentileRange(item.AD_SALES_PERCENTILE);
            item.AD_CONVERSION_RATE_PERCENTILE_RANGE = getPercentileRange(item.AD_CONVERSION_RATE_PERCENTILE);
            item.AD_CTR_PERCENTILE_RANGE = getPercentileRange(item.AD_CTR_PERCENTILE);
            item.AD_CLICKS_PERCENTILE_RANGE = getPercentileRange(item.AD_CLICKS_PERCENTILE);
            item.AD_IMPRESSIONS_PERCENTILE_RANGE = getPercentileRange(item.AD_IMPRESSIONS_PERCENTILE);

            return item;
        });
    }

    public applyForOptProductsAndInvestigate(result: Array<any>, percentiles: Array<string>) {
        if (percentiles?.length) {
            const sales = percentiles.map(item => extractPercentRangeFilterValue('sales', item)).filter(item => !!item);
            const conversions = percentiles.map(item => extractPercentRangeFilterValue('conversion rate', item)).filter(item => !!item);
            const sessions = percentiles.map(item => extractPercentRangeFilterValue('sessions', item)).filter(item => !!item);

            const filterValue = (item: any, values: Array<any>, property: string) => {
                if (!values.length) {
                    return true;
                }

                return values.includes(item[property]);
            };

            result = result
                .filter((item) => filterValue(item, sales, 'SALES_PERCENTILE_RANGE'))
                .filter((item) => filterValue(item, conversions, 'CONVERSION_RATE_PERCENTILE_RANGE'))
                .filter((item) => filterValue(item, sessions, 'SESSION_PERCENTILE_RANGE'));
        }

        return result;
    }

    public applyForKeywordsAndCampaigns(result: Array<any>, percentiles: Array<string>) {
        if (percentiles?.length) {
            const ad_roas = percentiles.map(item => extractPercentRangeFilterValue('ad roas', item)).filter(item => !!item);
            const ad_spend = percentiles.map(item => extractPercentRangeFilterValue('ad spend', item)).filter(item => !!item);
            const ad_sales = percentiles.map(item => extractPercentRangeFilterValue('ad sales', item)).filter(item => !!item);
            const ad_conversions = percentiles.map(item => extractPercentRangeFilterValue('ad conversion rate', item)).filter(item => !!item);
            const ad_ctr = percentiles.map(item => extractPercentRangeFilterValue('ad CTR', item)).filter(item => !!item);
            const ad_clicks = percentiles.map(item => extractPercentRangeFilterValue('ad clicks', item)).filter(item => !!item);
            const ad_impressions = percentiles.map(item => extractPercentRangeFilterValue('ad impressions', item)).filter(item => !!item);

            const filterValue = (item: any, values: Array<any>, property: string) => {
                if (!values.length) {
                    return true;
                }

                return values.includes(item[property]);
            };

            result = result
                .filter((item) => filterValue(item, ad_roas, 'AD_ROAS_PERCENTILE_RANGE'))
                .filter((item) => filterValue(item, ad_spend, 'AD_SPEND_PERCENTILE_RANGE'))
                .filter((item) => filterValue(item, ad_sales, 'AD_SALES_PERCENTILE_RANGE'))
                .filter((item) => filterValue(item, ad_conversions, 'AD_CONVERSION_RATE_PERCENTILE_RANGE'))
                .filter((item) => filterValue(item, ad_ctr, 'AD_CTR_PERCENTILE_RANGE'))
                .filter((item) => filterValue(item, ad_clicks, 'AD_CLICKS_PERCENTILE_RANGE'))
                .filter((item) => filterValue(item, ad_impressions, 'AD_IMPRESSIONS_PERCENTILE_RANGE'));
        }

        return result;
    }
}
