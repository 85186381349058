import { ITooltipParams } from "ag-grid-community";
import { CustomHeaderMetricTooltipComponent } from "../components/custom-header-metric-tooltip/custom-header-metric-tooltip.component";

type TooltipType = 'header' | 'cell';

export const makeMetricTooltipConfig = (type: TooltipType) => {
    let config: any = {
        tooltipComponent: CustomHeaderMetricTooltipComponent,
        tooltipValueGetter: (params: ITooltipParams) => {
            return params.location === (type === 'cell' ? 'header' : 'cell') ? null : params.value;
        }
    };

    if (type === 'header') {
        config['headerTooltip'] = 'active';
    }

    return config;
};
