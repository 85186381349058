import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { buildCacheKey } from "../../../utils/cache.util";
import { CacheService } from "../../cache.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../../user.service";

export interface VendorCentralScorecardOverviewFilters {
    accounts: string[];
    brands: string[];
    marketplaces: string[];
    currency: string;
    includeTax: string;
};

@Injectable({ providedIn: 'root' })
export class VendorCentralScorecardOverviewService {
    private baseUrl = environment.apiV2Url;

    public constructor(
        private cacheService: CacheService,
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async getMonthlyShippedReceipts(params: VendorCentralScorecardOverviewFilters) {
        try {
            const { accounts, brands, marketplaces, currency, includeTax } = params;

            const key = buildCacheKey(['vendor_central_scorecard_monthly_receipts', accounts.join(','), brands.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/vendor-central/overview/shipped-receipts/monthly');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        'brands[]': brands,
                        'marketplaces[]': marketplaces,
                        currency,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getWeeklyShippedReceipts(params: VendorCentralScorecardOverviewFilters) {
        try {
            const { accounts, brands, marketplaces, currency, includeTax } = params;

            const key = buildCacheKey(['vendor_central_scorecard_weekly_receipts', accounts.join(','), brands.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/vendor-central/overview/shipped-receipts/weekly');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        'brands[]': brands,
                        'marketplaces[]': marketplaces,
                        currency,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getWeekOverWeek(params: VendorCentralScorecardOverviewFilters) {
        try {
            const { accounts, brands, marketplaces, currency, includeTax } = params;

            const key = buildCacheKey(['vendor_central_scorecard_wow', accounts, brands.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/vendor-central/overview/week-over-week');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        'brands[]': brands,
                        'marketplaces[]': marketplaces,
                        currency,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getYearOverYear(params: VendorCentralScorecardOverviewFilters) {
        try {
            const { accounts, brands, marketplaces, currency, includeTax } = params;

            const key = buildCacheKey(['vendor_central_scorecard_yoy', accounts, brands.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/vendor-central/overview/year-over-year');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        'brands[]': brands,
                        'marketplaces[]': marketplaces,
                        currency,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getWeekToDate(params: VendorCentralScorecardOverviewFilters) {
        try {
            const { accounts, brands, marketplaces, currency, includeTax } = params;

            const key = buildCacheKey(['vendor_central_scorecard_week_to_date', accounts, brands.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/vendor-central/overview/week-to-date');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        'brands[]': brands,
                        'marketplaces[]': marketplaces,
                        currency,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getMonthToDate(params: VendorCentralScorecardOverviewFilters) {
        try {
            const { accounts, brands, marketplaces, currency, includeTax } = params;

            const key = buildCacheKey(['vendor_central_scorecard_month_to_date', accounts, brands.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/vendor-central/overview/month-to-date');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        'brands[]': brands,
                        'marketplaces[]': marketplaces,
                        currency,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getQuarterToDate(params: VendorCentralScorecardOverviewFilters) {
        try {
            const { accounts, brands, marketplaces, currency, includeTax } = params;

            const key = buildCacheKey(['vendor_central_scorecard_quarter_to_date', accounts, brands.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/vendor-central/overview/quarter-to-date');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        'brands[]': brands,
                        'marketplaces[]': marketplaces,
                        currency,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getYearToDate(params: VendorCentralScorecardOverviewFilters) {
        try {
            const { accounts, brands, marketplaces, currency, includeTax } = params;

            const key = buildCacheKey(['vendor_central_scorecard_year_to_date', accounts, brands.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;
                const url = new URL(this.baseUrl + 'scorecard/vendor-central/overview/year-to-date');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        'brands[]': brands,
                        'marketplaces[]': marketplaces,
                        currency,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }
}
