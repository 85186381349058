import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentry.enabled) {
    Sentry.init({
        environment: environment.environmentName,
        dsn: environment.sentry.dsn,
        integrations: [
            new BrowserTracing({
                tracingOrigins: [
                    "localhost",
                    "dev.amifyos.com",
                    "qa.amifyos.com",
                    "amifyos.com",
                ],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.sentry.sampleRate,
    });
}

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
};

const checkDiv = () => {
    let timeout;
    const div = document.querySelector('#cartdotcom-container');

    if (div) {
        clearTimeout(timeout);
        bootstrap();
    } else {
        timeout = setTimeout(checkDiv, 100);
    }
};

checkDiv();
