import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable({providedIn: 'root'})
export class BoardService {
    public base_url = environment.apiUrl;

    public constructor(private http: HttpClient, private userService: UserService) { }

    public async get() {
        const user = this.userService.getUser()!;
        const endpoint = `${this.base_url}boards`;

        return this.http.get<Array<any>>(endpoint.toString(), {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        });
    }
}
