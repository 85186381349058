import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-redirect',
    template: '<p>Loading...</p>',
})
export class RedirectComponent {
    public constructor(private readonly userService: UserService, private readonly router: Router) { }

    public async ngOnInit() {
        const user = this.userService.getUser();
        const defaultPage = user?.defaultModule || '/scorecard/overview';

        this.router.navigate([defaultPage], { replaceUrl: true });
    }
}
