import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClarityService {
    private globalWindow = (window as any);

    public constructor(
        private userService: UserService,
    ) {}

    public async boot(): Promise<void> {
        if (!environment.production) {
            return;
        }

        try {
            const { email } = await this.userService.getData();

            this.setUserId(email);
        } catch {
            this.setUserId(null);
        }
    }

    public changePage(pageName: string): void {
        if (!environment.production) {
            return;
        }

        try {
            this.setPageName(pageName);
            this.setPageId(pageName);
        } catch {
            this.setPageName(null);
            this.setPageId(null);
        }
    }

    private setUserId(email: string | null): void {
        this.globalWindow.clarity('set', 'userId', email);
    }

    private setPageName(pageName: string | null): void {
        this.globalWindow.clarity('set', 'pageName', pageName);
    }

    private setPageId(pageId: string | null): void {
        this.globalWindow.clarity('set', 'pageId', pageId);
    }
}
