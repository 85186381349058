<div class="custom-header-metric-tooltip custom-header-metric-tooltip-grouped">
  <div *ngIf="acronym">
    <span style="font-weight: bold; font-size: 1em;">{{ acronym }}</span>
  </div>

  <div *ngIf="definition">
    <span style="font-size: 1em; color: #555;">{{ definition }}</span>
  </div>
  
  <div *ngIf="logic">
    <span style="font-style: italic; font-size: 1em; color: #777; ">{{ logic }}</span>
  </div>
</div>