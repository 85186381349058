<div class="wrapper">
    <div class="sidebar_menu">
        <div class="row mb-3">
            <mat-icon id="amify-logo" [svgIcon]="icon"></mat-icon>
        </div>

        <div class="scroll-container">
            <ng-container *ngFor="let menu of menus$ | async">
                <div [pTooltip]="menu.tooltip || ''" class="row" *ngIf="!menu.submenu; else submenu_container">
                    <a *ngIf="menu.routerLink" class="item" [ngClass]="menu.class || ''" routerLinkActive="active"
                        [routerLink]="menu.routerLink">
                        <span class="icon">
                            <mat-icon id="icon" [svgIcon]="menu.icon"></mat-icon>
                        </span>
                        <span class="list">{{ menu.name }}</span>
                    </a>
                    <a *ngIf="!menu.routerLink" class="item" href='javascript:;' (click)='logout()'>
                        <span class="icon">
                            <mat-icon id="icon" [svgIcon]="menu.icon"></mat-icon>
                        </span>
                        <span class="list">{{ menu.name }}</span>
                    </a>
                </div>

                <ng-template #submenu_container>
                    <div [pTooltip]="menu.tooltip || ''" class="row">
                        <div class="item">
                            <div style="float: left;">
                                <a [ngClass]="menu.class || ''" routerLinkActive="active"
                                    (click)="toggleSubmenuVisibility(menu)" href="javascript:;">
                                    <span class="icon">
                                        <mat-icon id="icon" [svgIcon]="menu.icon"></mat-icon>
                                    </span>
                                    <span class="list">{{ menu.name }}</span>

                                    <ng-container *ngFor="let route of menu.submenuRoute">
                                        <a class="hidden" [routerLink]="[route]"></a>
                                    </ng-container>
                                </a>
                            </div>
                            <div style="float: right;">
                                <span *ngIf="menu.userGuide && !menu_retracted" [showDelay]="500"
                                    [pTooltip]="menu.name + ' user guide'" class="icon"
                                    style="float: right; margin-right: 0px;">
                                    <a (click)="goToUserGuide(menu.userGuide)">
                                        <mat-icon id="icon" class="user-guide-icon"
                                            svgIcon="circle-question"></mat-icon>
                                    </a>
                                </span>
                            </div>
                        </div>

                        <ng-container *ngIf="menu.submenuVisible && !menu_retracted">
                            <ng-template #loading_submenu_container>
                                <div class="submenu">
                                    <span>Loading...</span>
                                </div>
                            </ng-template>

                            <ng-container *ngIf="!menu.loading; else loading_submenu_container">
                                <div class="submenu" *ngFor="let submenuLevel1 of menu.submenu">
                                    <div class="submenu-item" *ngIf="(submenuLevel1.routerLink || submenuLevel1.clickEvent) else submenuLevel2">
                                        <div style="float: left">
                                            <a style="font-size: 16px;" routerLinkActive="active"
                                                [routerLink]="submenuLevel1.routerLink"
                                                *ngIf="submenuLevel1.routerLink else submenuLevel1EventHandler">
                                                <div>
                                                    <div (click)="toggleSubmenuVisibility(submenuLevel1)">
                                                        <span class="submenu-icon">
                                                            <mat-icon svgIcon="chevron-right"></mat-icon>
                                                        </span>
                                                        <span class="name">{{ submenuLevel1.name }}</span>
                                                    </div>

                                                </div>
                                            </a>
                                            <ng-template #submenuLevel1EventHandler>
                                                <a style="font-size: 16px;" href="javascript:;" (click)="submenuLevel1?.clickEvent()">
                                                    <div>
                                                        <div (click)="toggleSubmenuVisibility(submenuLevel1)">
                                                            <span class="submenu-icon">
                                                                <mat-icon svgIcon="chevron-right"></mat-icon>
                                                            </span>
                                                            <span class="name">{{ submenuLevel1.name }}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </ng-template>
                                        </div>

                                        <div style="float: right">
                                            <span *ngIf="submenuLevel1.userGuide && !menu_retracted" [showDelay]="500"
                                                [pTooltip]="submenuLevel1.name + ' user guide'" class="icon"
                                                style="float: right; margin-right: 0px;">
                                                <a (click)="goToUserGuide(submenuLevel1.userGuide)">
                                                    <mat-icon id="icon" class="user-guide-icon"
                                                        svgIcon="circle-question"></mat-icon>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <ng-template #submenuLevel2>
                                        <div class="submenu-item pb-5">
                                            <div style="float: left;">
                                                <a [ngClass]="submenuLevel1.class || ''" routerLinkActive="active" (click)="toggleSubmenuVisibility(submenuLevel1)" href="javascript:;">
                                                    <span class="submenu-icon">
                                                        <mat-icon id="icon" *ngIf="submenuLevel1.submenuVisible" svgIcon="chevron-down"></mat-icon>
                                                        <mat-icon id="icon" *ngIf="!submenuLevel1.submenuVisible" svgIcon="chevron-right"></mat-icon>
                                                    </span>
                                                    <span class="name">{{ submenuLevel1.name }}</span>
                                                </a>
                                            </div>
                                            <div style="float: right;">
                                                <span *ngIf="submenuLevel1.userGuide && !menu_retracted" [showDelay]="500"
                                                    [pTooltip]="submenuLevel1.name + ' user guide'" class="icon"
                                                    style="float: right; margin-right: 0px;">
                                                    <a (click)="goToUserGuide(submenuLevel1.userGuide)">
                                                        <mat-icon id="icon" class="user-guide-icon"
                                                            svgIcon="circle-question"></mat-icon>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="submenuLevel1.submenuVisible">
                                            <div *ngFor="let submenuLevel2 of submenuLevel1.submenu" class="submenu pt-2">
                                                <a routerLinkActive="active" [routerLink]="submenuLevel2.routerLink">
                                                    <div>
                                                        <div (click)="toggleSubmenuVisibility(submenuLevel2)">
                                                            <span class="submenu-icon">
                                                                <mat-icon svgIcon="chevron-right"></mat-icon>
                                                            </span>
                                                            <span class="name">{{ submenuLevel2.name }}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>
        </div>

        <div class="hamburger">
            <div class="inner_hamburger">
                <span class="arrow" (click)="toggleMenuSize()">
                    <mat-icon class="arrow-left" svgIcon="nav-white-left"></mat-icon>
                    <mat-icon class="arrow-right" svgIcon="nav-white-right"></mat-icon>
                </span>
            </div>
        </div>
    </div>
</div>
