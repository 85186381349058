import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { buildCacheKey } from "../utils/cache.util";
import { CacheService } from "./cache.service";
import { PercentileFilterService } from "./percentile-filter.service";
import { transformTaxFilter } from "../utils/transform-tax-filter";
import { UserService } from "./user.service";

export interface OptimizeFilters {
    asin?: string;
    fromDate: Date;
    toDate: Date;
    aggregationType?: string;
    percentiles?: Array<string>;
    marketplaces: string[];
    currency: string;
    includeTax?: string;
}

export interface OptimizeProductsFilter extends OptimizeFilters {
    aggregationType: string;
    aggregationText?: string;
}

export interface OptimizeKeywordsFilters extends OptimizeFilters {
    aggregationType: string;
    keywordText?: string;
    keywordTexts?: string[];
    campaignTypes?: string[];
    strategicPillars?: string[];
}

export interface OptimizeCampaignsFilter extends OptimizeFilters {
    campaignName?: string;
    campaignNames?: string[];
    campaignTypes?: string[];
    strategicPillars?: string[];
}

@Injectable()
export class OptimizeService {
    public apiUrl = environment.apiV2Url;
    public memoryCache: any = {};

    public constructor(
        private http: HttpClient,
        private cacheService: CacheService,
        private percentileFilterService: PercentileFilterService,
        private userService: UserService
    ) { }

    public async getProducts(accounts: string[], filters: OptimizeProductsFilter): Promise<Observable<any>> {
        try {
            filters = transformTaxFilter(filters);

            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];
            const aggregationType = filters.aggregationType;
            const key = buildCacheKey(['optimize_products', accounts, aggregationType, fromDateFormatted, toDateFormatted]);
            const cache = this.cacheService.getCache('LocalStorage', key);

            let obs = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;

                obs = this.http.get<Array<any>>(this.apiUrl + 'investigate/products/optimize', {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        startDate: fromDateFormatted,
                        endDate: toDateFormatted,
                        aggregationType: aggregationType,
                        'marketplaces[]': filters.marketplaces,
                        currency: filters.currency,
                        includeTax: filters.includeTax || 'Yes'
                    }
                }).pipe(map(result => this.cacheResponse(result, key)));
            }

            return this.cacheService.cacheObservable(key, obs)
                .pipe(map(result => this.percentileFilterService.createPercentRangeValues(result)))
                .pipe(map(result => this.percentileFilterService.applyForOptProductsAndInvestigate(result, filters.percentiles!)));
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getProductsAnimation(accounts: string[], filters: OptimizeProductsFilter): Promise<Observable<any>> {
        try {
            filters = transformTaxFilter(filters);

            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];
            const aggregationType = filters.aggregationType;
            const aggregationText = filters.aggregationText!;

            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.apiUrl + 'investigate/products/animation', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    aggregationType: aggregationType,
                    filterText: aggregationText,
                    startDate: fromDateFormatted,
                    endDate: toDateFormatted,
                    'marketplaces[]': filters.marketplaces,
                    currency: filters.currency
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getCampaigns(accounts: string[], filters: OptimizeCampaignsFilter): Promise<Observable<any>> {
        try {
            filters = transformTaxFilter(filters);

            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];
            const aggregationType = filters.aggregationType || 'Campaign Name';
            const key = buildCacheKey(['optimize_campaigns', accounts.join(','), aggregationType, fromDateFormatted, toDateFormatted, filters.marketplaces.join(','), filters.currency]);

            let cache = this.cacheService.getCache('LocalStorage', key);

            const campaignType = filters.campaignTypes || [];
            const strategicPillars = filters.strategicPillars || [];
            const campaignNames = filters.campaignNames || [];

            const needToRequest = campaignType.length > 0 || strategicPillars.length > 0 || campaignNames.length > 0;

            let obs = of(cache);

            if (needToRequest) {
                cache = null;
            }

            if (!cache) {
                const user = this.userService.getUser()!;

                obs = this.http.get<Array<any>>(this.apiUrl + 'investigate/campaigns', {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        startDate: fromDateFormatted,
                        endDate: toDateFormatted,
                        aggregationType: aggregationType,
                        'campaignTypes[]': campaignType,
                        'strategicPillars[]': strategicPillars,
                        'campaignNames[]': campaignNames,
                        'marketplaces[]': filters.marketplaces,
                        currency: filters.currency
                    }
                });

                if (!needToRequest) {
                    obs = obs.pipe(map(result => this.cacheResponse(result, key)));
                }
            }

            return this.cacheService.cacheObservable(key, obs)
                .pipe(map(result => this.percentileFilterService.createPercentRangeValues(result)))
                .pipe(map(result => this.percentileFilterService.applyForKeywordsAndCampaigns(result, filters.percentiles!)))
                .pipe(map(result => this.applyCampaignsFilter(result, filters)));
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getCampaignsAnimation(accounts: string[], campaignName: string, fromDate: Date, toDate: Date, marketplaces: string[], currency: string): Promise<Observable<any>> {
        try {
            const user = this.userService.getUser()!;

            const fromDateFormatted = fromDate.toISOString().split('T')[0];
            const toDateFormatted = toDate.toISOString().split('T')[0];

            return this.http.get<string>(this.apiUrl + 'investigate/campaigns/animation', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    startDate: fromDateFormatted,
                    endDate: toDateFormatted,
                    campaignName: campaignName,
                    'marketplaces[]': marketplaces,
                    currency: currency
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getCampaignsByDate(accounts: string[], filters: OptimizeCampaignsFilter): Promise<Observable<any>> {
        try {
            filters = transformTaxFilter(filters);

            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];
            const user = this.userService.getUser()!;

            const campaignName = filters.campaignName!;

            return this.http.get<Array<any>>(this.apiUrl + 'investigate/campaigns/by-date', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    startDate: fromDateFormatted,
                    endDate: toDateFormatted,
                    campaignName: campaignName,
                    'marketplaces[]': filters.marketplaces,
                    currency: filters.currency
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getCampaignsByKeyword(accounts: string[], filters: OptimizeCampaignsFilter): Promise<Observable<any>> {
        try {
            filters = transformTaxFilter(filters);

            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];
            const user = this.userService.getUser()!;

            const campaign_name = filters.campaignName!;

            return this.http.get<Array<any>>(this.apiUrl + 'investigate/campaigns/by-keyword', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    startDate: fromDateFormatted,
                    endDate: toDateFormatted,
                    campaignName: campaign_name,
                    'marketplaces[]': filters.marketplaces,
                    currency: filters.currency
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getKeywords(accounts: string[], filters: OptimizeKeywordsFilters): Promise<Observable<any>> {
        try {
            filters = transformTaxFilter(filters);

            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];
            const aggregationType = filters.aggregationType;

            const user = this.userService.getUser()!;

            const obs = this.http.get<Array<any>>(this.apiUrl + 'investigate/keywords', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    startDate: fromDateFormatted,
                    endDate: toDateFormatted,
                    aggregationType: aggregationType,
                    'marketplaces[]': filters.marketplaces,
                    currency: filters.currency
                }
            });

            return obs
                .pipe(map(result => this.percentileFilterService.createPercentRangeValues(result)))
                .pipe(map(result => this.percentileFilterService.applyForKeywordsAndCampaigns(result, filters.percentiles!)))
                .pipe(map(result => this.applyKeywordsFilter(result, filters)));
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getKeywordsAnimation(accounts: string[], filters: OptimizeKeywordsFilters): Promise<Observable<any>> {
        try {
            filters = transformTaxFilter(filters);

            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];
            const aggregationType = filters.aggregationType;
            const keywordText = filters.keywordText!;

            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.apiUrl + 'investigate/keywords/animation', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    startDate: fromDateFormatted,
                    endDate: toDateFormatted,
                    aggregationType,
                    keywordText,
                    'marketplaces[]': filters.marketplaces,
                    currency: filters.currency
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public async getKeywordsByDate(accounts: string[], filters: OptimizeKeywordsFilters): Promise<Observable<any>> {
        try {
            filters = transformTaxFilter(filters);

            const fromDateFormatted = filters.fromDate.toISOString().split('T')[0];
            const toDateFormatted = filters.toDate.toISOString().split('T')[0];
            const aggregationType = filters.aggregationType;
            const keywordText = filters.keywordText!;

            const user = this.userService.getUser()!;

            return this.http.get<[]>(this.apiUrl + 'investigate/keywords/by-date', {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    'accounts[]': accounts,
                    startDate: fromDateFormatted,
                    endDate: toDateFormatted,
                    aggregationType,
                    keywordText,
                    'marketplaces[]': filters.marketplaces,
                    currency: filters.currency
                }
            });
        } catch (err) {
            return throwError(() => err);
        }
    }

    public cacheResponse(result: Array<any>, cacheKey: string) {
        this.cacheService.setCache('LocalStorage', cacheKey, result, (60 * 60 * 1));

        return result;
    }

    public applyKeywordsFilter(result: Array<any>, filters: OptimizeKeywordsFilters) {
        let filtered = result;

        if (filters.strategicPillars?.length) {
            filtered = filtered.filter(item => filters.strategicPillars?.includes(item.strategic_pillar));
        }

        if (filters.campaignTypes?.length) {
            filtered = filtered.filter(item => filters.campaignTypes?.includes(item.campaign_type));
        }

        if (filters.keywordTexts?.length) {
            filtered = filtered.filter(item => filters.keywordTexts?.includes(item.keyword_text));
        }

        return filtered;
    }

    public applyCampaignsFilter(result: Array<any>, filters: OptimizeCampaignsFilter) {
        let filtered = result;

        if (filters.strategicPillars?.length) {
            filtered = filtered.filter(item => filters.strategicPillars?.includes(item.strategic_pillar));
        }

        if (filters.campaignTypes?.length) {
            filtered = filtered.filter(item => filters.campaignTypes?.includes(item.campaign_type));
        }

        if (filters.campaignNames?.length) {
            filtered = filtered.filter(item => filters.campaignNames?.includes(item.campaign_name));
        }

        return filtered;
    }
}
