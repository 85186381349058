import { Injectable } from "@angular/core";
import { lastValueFrom, map, Observable, of, throwError } from "rxjs";
import { buildCacheKey } from "../utils/cache.util";
import { CacheService } from "./cache.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { DateHelperService } from "./date-helper.service";
import { format } from "date-fns";
import { AccountTypeEnum } from "./accounts.service";
import { LAST_SELECTED_ACCOUNT_KEY } from "../components/filter/filter.component";
import { GroupTypeEnum } from "./user-attributes.service";

interface UserData {
    userId?: string;
    orgServiceUserId?: string;
    name: string;
    email: string;
    phone: string;
    group: string;
    department: string;
    token: string;
    role?: string;
    access?: string;
    defaultASCAccount?: string;
    defaultAVCAccount?: string;
    defaultModule?: string;
    slackId?: string;
    accounts?: string;
    amzAccountTypes?: string;
    allowedAccounts?: string;
    orgService?: any;
}

interface LocalStorageAccounts { accounts: string[]; accountType: string };

@Injectable({ providedIn: 'root' })
export class UserService {
    public baseUrl = environment.apiV2Url;

    private cachedUser: UserData | null | undefined;

    public constructor(
        private dateHelperService: DateHelperService,
        private cacheService: CacheService,
        private http: HttpClient
    ) { }

    public getUser() {
        return this.cachedUser;
    }

    public setUser(user: UserData | null) {
        this.cachedUser = user;
    }

    public async getUserFromEmail(token: string): Promise<Observable<any>> {
        try {
            const url = new URL(this.baseUrl + 'users/me');

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + token!
                },
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async setupOrgServiceUser(token: string) {
        try {
            const url = new URL(this.baseUrl + 'users/setup-org-user');

            return this.http.post<[]>(url.toString(), {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                },
            });
        } catch (error) {
            return throwError(() => error);
        }
    }

    public async getAllowedAccount() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        if (user?.group == GroupTypeEnum.External || user?.group == GroupTypeEnum.CartExternal) {
            return user.allowedAccounts;
        }

        return '*';
    }

    public async getDefaultAccount(): Promise<{ accountName: string; accountType: string }> {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        const userGroup = user.group;

        const isExternal = (userGroup === GroupTypeEnum.External) || (userGroup === GroupTypeEnum.CartExternal);

        if(isExternal && !user.allowedAccounts) {
            throw new Error('Account list not found for user');
        }

        const allowedAccountsString = isExternal ? user.allowedAccounts! : '*';

        const allowedAccounts: { name: string; type: string }[] = allowedAccountsString.split('|').map((account: string) => {
            const [accountName, accountType] = account.split(':');

            return { name: accountName, type: accountType };
        });

        const [defaultExternalAccount] = allowedAccounts;

        let defaultAccountType = 'ASC';

        if(isExternal) {
            defaultAccountType = (defaultExternalAccount.type === 'cart') ? '' : defaultExternalAccount.type.toUpperCase();
        }

        return isExternal ?
            { accountName: defaultExternalAccount.name, accountType: defaultAccountType } :
            { accountName: await this.getDefaultAccountASC(), accountType: defaultAccountType };
    }

    public async getDefaultFilterValues(): Promise<{ accounts: string[]; accountType: string }> {
        const { accountName, accountType } = await this.getDefaultAccount();

        const hasLastSelectedAccount = localStorage.getItem(LAST_SELECTED_ACCOUNT_KEY);

        if (hasLastSelectedAccount) {
            const { accounts, accountType }: LocalStorageAccounts = JSON.parse(hasLastSelectedAccount);

            if (accounts.filter(account => account).length > 0) {
                return { accounts, accountType };
            }
        }

        return {
            accounts: [accountName],
            accountType: accountType
        };
    }

    public async getAllowedAccountTypes() {
        const allowedAccount = await this.getAllowedAccount();
        const accountTypes = ['asc', 'avc'];

        if (allowedAccount == '*') {
            return accountTypes;
        }

        return accountTypes.filter((accountType: string) => allowedAccount?.includes(`:${accountType}`));
    }

    public async getDefaultAccountASC() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        return user.defaultASCAccount ?? 'Blenders';
    }

    public async getDefaultAccountAVC() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        return user.defaultAVCAccount ?? 'CID-VC';
    }

    public async getDefaultModule() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        if (user.defaultModule) {
            return user.defaultModule;
        } else {
            if (user.group === GroupTypeEnum.ProspectExternal) {
                return '/onboard';
            }

            return '/scorecard/overview';
        }
    }

    public async getGroup() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        return user.group;
    }

    public async getRoles() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        return user.role;
    }

    public async isDemoUser() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        return user.email == 'pet+atc@goamify.com';
    }

    public async getData(): Promise<UserData> {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        return {
            name: user.name,
            email: user.email,
            phone: user.phone ?? '',
            group: user.group,
            department: user.department ?? null,
            token: user.token
        };
    }

    public async getAccessType() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        return user.access;
    }

    public async getAllAttributes() {
        const user = this.getUser();

        if(!user) {
            throw new Error('User not found');
        }

        return user;
    }

    public async getAllUsers(ignoreCache: boolean): Promise<Observable<any>> {
        try {
            const key = buildCacheKey(['all_users']);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (ignoreCache || !cache) {
                const user = this.getUser()!;

                let url = new URL(this.baseUrl + 'users');

                result = this.http.get<Array<any>>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async updateUserStatus(username: string, email: string, status: boolean): Promise<Observable<any>> {
        try {
            const user = this.getUser()!;

            let url = new URL(this.baseUrl + 'users/update-status');

            return this.http.post(url.toString(), { username: username, email: email, status: status }, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return of({ 'status_code': 401, 'detail': error });
        }
    }

    public async createUserAccount(newUser: any): Promise<Observable<any>> {
        try {
            const user = this.getUser()!;

            let url = new URL(this.baseUrl + 'users/create');

            return this.http.post(url.toString(), { user: newUser }, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return of({ 'status_code': 401, 'detail': error });
        }
    }

    public async updateAttributes(userAttributes: any): Promise<Observable<any>> {
        try {
            const user = this.getUser()!;

            let url = new URL(this.baseUrl + 'users/update-attributes');

            return this.http.post(url.toString(), { userAttributes: userAttributes }, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (error) {
            return of({ 'status_code': 401, 'detail': error });
        }
    }

    public async getUserAttributes(userEmail: string): Promise<Observable<any>> {
        try {
            const user = this.getUser()!;

            const url = new URL(this.baseUrl + 'users/get-user');

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
                params: {
                    userEmail: userEmail
                }
            });
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public getDefaultDateRange(params: { offset: number }) {
        const defaultDates = this.dateHelperService.getDefaultDates(params.offset);

        const fromDate = defaultDates.fromDate;
        const toDate = defaultDates.toDate;

        return {
            fromDate: format(fromDate, 'yyyy-MM-dd'),
            toDate: format(toDate, 'yyyy-MM-dd'),
            datePeriod: ''
        };
    }

    public getHealthcheckDateRange() {
        const dates = this.dateHelperService.getHealthcheckDates();

        const fromDate = dates.fromDate;
        const toDate = dates.toDate;

        return {
            fromDate: format(fromDate, 'yyyy-MM-dd'),
            toDate: format(toDate, 'yyyy-MM-dd'),
            datePeriod: ''
        };
    }

    public async getAllBrandManagersAccounts(): Promise<Observable<any>> {
        try {
            const user = this.getUser()!;

            let url = new URL(this.baseUrl + 'users/get-brand-managers-accounts');

            return this.http.get(url.toString(), {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            });
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getAccountsForBrandManagers() {
        const obs = await this.getAllBrandManagersAccounts();

        const result = await lastValueFrom(obs);

        const user = this.getUser()!;

        let email = user.email;

        if (email in result) {
            return {
                [AccountTypeEnum.SellerCentral]: result[email]
                    .filter((acc: any) => acc.type == AccountTypeEnum.SellerCentral)
                    .map((acc: any) => acc.name) as string[],

                [AccountTypeEnum.VendorCentral]: result[email]
                    .filter((acc: any) => acc.type == AccountTypeEnum.VendorCentral)
                    .map((acc: any) => acc.name) as string[],
            };
        }

        return {
            [AccountTypeEnum.SellerCentral]: [user.defaultASCAccount ?? 'Blenders'],
            [AccountTypeEnum.VendorCentral]: [user.defaultAVCAccount ?? 'CID-VC'],
        };
    }
}
