import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { buildCacheKey } from "../../../utils/cache.util";
import { CacheService } from "../../cache.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { transformTaxFilter } from "src/app/utils/transform-tax-filter";
import { UserService } from "../../user.service";

export interface ScorecardOverviewFilters {
    accounts: string[];
    currency: string;
    marketplaces: string[];
    includeTax: string;
};

@Injectable({ providedIn: 'root' })
export class SellerCentralScorecardOverviewService {
    private baseUrl = environment.apiV2Url;

    public constructor(
        private cacheService: CacheService,
        private http: HttpClient,
        private userService: UserService
    ) { }

    public async getSalesOverview(params: ScorecardOverviewFilters) {
        try {
            params = transformTaxFilter(params);

            const { accounts, currency, marketplaces, includeTax } = params;

            const key = buildCacheKey(['scorecard_sales_overview', accounts.join(','), marketplaces.join(','), currency, includeTax]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/overview/sales');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        currency,
                        'marketplaces[]': marketplaces,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getWeeklyOverview(params: ScorecardOverviewFilters) {
        try {
            params = transformTaxFilter(params);

            const { accounts, currency, marketplaces, includeTax } = params;

            const key = buildCacheKey(['scorecard_weekly_overview', accounts.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/overview/weekly');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        currency,
                        'marketplaces[]': marketplaces,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getWeekToDate(params: ScorecardOverviewFilters) {
        try {
            params = transformTaxFilter(params);

            const { accounts, currency, marketplaces, includeTax } = params;

            const key = buildCacheKey(['scorecard_week_to_date_overview', accounts.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/overview/week-to-date');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token

                    },
                    params: {
                        'accounts[]': accounts,
                        currency,
                        'marketplaces[]': marketplaces,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getMonthOverview(params: ScorecardOverviewFilters) {
        try {
            params = transformTaxFilter(params);

            const { accounts, currency, marketplaces, includeTax } = params;

            const key = buildCacheKey(['scorecard_month_overview', accounts.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/overview/month-to-date');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token!
                    },
                    params: {
                        'accounts[]': accounts,
                        currency,
                        'marketplaces[]': marketplaces,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getQuarterOverview(params: ScorecardOverviewFilters) {
        try {
            params = transformTaxFilter(params);

            const { accounts, currency, marketplaces, includeTax } = params;

            const key = buildCacheKey(['scorecard_quarter_overview', accounts.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/overview/quarter-to-date');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        currency,
                        'marketplaces[]': marketplaces,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getYearOverview(params: ScorecardOverviewFilters) {
        try {
            params = transformTaxFilter(params);

            const { accounts, currency, marketplaces, includeTax } = params;

            const key = buildCacheKey(['scorecard_year_overview', accounts.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if (!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/overview/year-to-date');

                result = this.http.get<any>(url.toString(), {
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        currency,
                        'marketplaces[]': marketplaces,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err) {
            return of({ 'status_code': 401, 'detail': err });
        }
    }

    public async getYearOverYearLastCompletedWeek(params: ScorecardOverviewFilters) {
        try {
            params = transformTaxFilter(params);

            const { accounts, currency, marketplaces, includeTax } = params;

            const key = buildCacheKey(['scorecard_yoy_last_completed_week', accounts.join(','), marketplaces.join(','), currency]);
            const cache = this.cacheService.getCache('Memory', key);
            let result = of(cache);

            if(!cache) {
                const user = this.userService.getUser()!;

                const url = new URL(this.baseUrl + 'scorecard/overview/year-over-year-last-completed-week');

                result = this.http.get<any>(url.toString() ,{
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    },
                    params: {
                        'accounts[]': accounts,
                        currency,
                        'marketplaces[]': marketplaces,
                        includeTax
                    }
                }).pipe(map((response) => {
                    this.cacheService.setCache('Memory', key, response, (60 * 60 * 1));

                    return response;
                }));
            }

            return result;
        } catch (err)  {
            return of({'status_code': 401, 'detail': err});
        }
    }
}
